import {OtrHttpRequestService} from '../otr-http-request.service';
import {FileUploader} from 'ng2-file-upload';
import {EventComponent} from './event-component';

export class FileHandler {



  uploader: FileUploader;
  uploadIsProgress = false;
  tooLargeFile = false;

  constructor(private otrHttpRequestService: OtrHttpRequestService,
              private eventComponent: EventComponent) {

    this.uploader = new FileUploader({url: '/event-files/' + this.eventComponent.eventId, removeAfterUpload: true, autoUpload: true});

    this.uploader.onCompleteAll = () => {
      this.uploadIsProgress = false;
      // send message to refresh files
      if (this.eventComponent.httpMode) {
        this.otrHttpRequestService.doGet('/room/' + this.eventComponent.eventId + '/filesChanged').subscribe(data => {
          this.eventComponent.roomStatus.update(data);
        }, err => {
          console.log('err: ' + JSON.stringify(err));
        });
      } else {
        this.eventComponent.roomStatus.filesChanged();
      }

    };
    this.uploader.onProgressAll = (_progress: any) => {
      this.uploadIsProgress = true;
    };
    this.uploader.onErrorItem = ((_item, _response, status, _headers): any => {
      if (status === 413) { // túl nagy fájl
        this.tooLargeFile = true;
        setTimeout(() => {
          this.tooLargeFile = false;
        }, 2000);
      }
    });
  }

  downloadFile(fileId) {
    const fileItem = this.eventComponent.roomStatus.room.files.find(file => file.id === fileId);
    if (fileItem) {
      window.open('/event-files/' + fileItem.id + '/' + this.eventComponent.eventId + '/' + encodeURI(fileItem.name));
    }
  }

  deleteFile(fileId) {
    this.otrHttpRequestService.doDeleteAndCheck('/event-files/' + fileId + '/' + this.eventComponent.eventId, _res => {
      if (this.eventComponent.httpMode) {
        this.otrHttpRequestService.doGet('/room/' + this.eventComponent.eventId + '/filesChanged').subscribe(data => {
          this.eventComponent.roomStatus.update(data);
        }, err => {
          console.log('err: ' + JSON.stringify(err));
        });
      } else {
        this.eventComponent.roomStatus.filesChanged();
      }
    });
  }
}
