import {Injectable} from '@angular/core';
import {OtrHttpRequestService} from './otr-http-request.service';
import {Subject} from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class OtrMainJoinEventService {

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
  }

  private loadIsJoinableEventService = new Subject<string>();
  newJoinableEvent$ = this.loadIsJoinableEventService.asObservable();

  loadEvents() {
    this.otrHttpRequestService.doGetAndCheck('/event-query/load/started', data => {
      let retData;
      if (!data['isError']) {
        retData = data['result'].find(event_ => event_.isJoinble === true);
      } else {
        retData = null;
      }
      this.loadIsJoinableEventService.next(retData);
    });
  }
}
