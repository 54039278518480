import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';

import {OtrUserService} from '../../otr-user.service';
import {OtrEventParticipantService} from '../../otr-event-participant.service';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrMainJoinEventService} from '../../otr-main-join-event.service';

import {Observable, Subscription} from 'rxjs-compat';


import {FileUploader} from 'ng2-file-upload';
import {EventStreamHandler} from '../../event-stream-handler';
import {NgxSpinnerService} from 'ngx-spinner';
import {OtrLangService} from '../../otr-lang.service';
import {Location} from '@angular/common';
import {EventComponent} from '../../event/event-component';
import {OtrLogService} from '../../otr-log.service';
import {ImageModifyService} from '../../image-modify.service';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-otr-conference-monitor-interpreter',
  templateUrl: './otr-conference-monitor-interpreter.component.html',
  styleUrls: ['./otr-conference-monitor-interpreter.component.css'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(300, animateChild()), {optional: true})
      ])
    ])
  ]
})
export class OtrConferenceMonitorInterpreterComponent extends EventComponent implements OnInit, OnDestroy {

  get maxVolume(): number {
    return this._maxVolume;
  }

  set maxVolume(value: number) {
    this._maxVolume = value;
    this.setGains();
  }

  @Input()
  eventId: any;

  constructor(
    private otrUserService: OtrUserService,
    private otrEventParticipantService: OtrEventParticipantService,
    private otrHttpRequestService: OtrHttpRequestService,
    private otrMainJoinEventService: OtrMainJoinEventService,
    private spinner: NgxSpinnerService,
    private otrLangService: OtrLangService,
    private otrLogService: OtrLogService,
    private _location: Location,
    private imageModifyService: ImageModifyService,
    public platform: Platform
  ) {
    super(otrHttpRequestService, otrLogService, 'conference', imageModifyService, platform);
    this.otrLangService.getValuesForGroup('interpreterWindow', res => {
      this.langStr = res;
    });
  }

  uploader: FileUploader;
  uploadIsProgress = false;
  tooLargeFile = false;

  publishNumber = 0;
  conference: any;
  showedRemoteStreams = [];
  waitingToSubscribe = [];

  inSubscribe = false;
  inPublish = false;
  gains: any = {};
  streamIds: any = {};
  localStream: EventStreamHandler;

  _maxVolume: any = 50;
  filterDocuments = false;
  documents: any = [];

  langStr = {
    autonext: '',
    autoshift: '',
    sendmessage: '',
    participants: '',
    missing: '',
    present: '',
    documents: '',
    tooLargeFile: '',
    exit: '',
    chat: '',
    presenter: '',
    presenters: '',
  };

  private audioContext: AudioContext;

  myRoom: any = 'conference';

  cameraOn = false;
  micOn = false;
  muteOn = false;

  timerStart: any;
  minutesDisplay: any = '00';
  hoursDisplay: any = '00';
  secondsDisplay: any = '00';
  ticks = 0;
  sub: Subscription;


  lastUuid: any = '';

  nickname = 'Interpreter';
  currentEventState: any = 'APPLY';
  currentEventClass: any = 'btn-success';

  autoChangeInput = true;
  autoChangeOutput = true;

  isFirefox = false;
  displayedStreamId: any = -1;
  subscriptions: any = {};
  userList = [];
  missingUserList = [];
  speakingUserItem: {
    id: -1,
    type: '',
    name: '',
    lang: '',
    streamId: ''
  };

  rtcConfiguration = {
    iceServers: [],
    iceTransportPolicy: 'relay'
  };

  hasBaseDropZoneOver = false;



  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (event.target['tagName'] === 'INPUT') {
      return;
    }

    if (event.target['tagName'] === 'INPUT' && event.target['id'] === 'chatmsg') {
      return;
    }

    if (event.code === 'KeyM') {
      this.toggleMic();
      event.preventDefault();
    }

    if (event.code === 'KeyN') {
      if (!this.autoChangeInput && (this.roomStatus.room.state === 1 &&
        this.roomStatus.room.speakingUser === -1 && this.roomStatus.room.waitingUsers.length > 0)) {
        // TODO itt mit kell???? this.nextState();
      }
      event.preventDefault();
    }

    if (event.code === 'Space') {
      this.unmute();
      event.preventDefault();
    }
    if (event.code === 'KeyC') {
      if ( !this.autoChangeOutput && this.roomStatus && this.roomStatus.interpreter &&
        this.roomStatus.room && this.roomStatus.room.state === 1 && this.roomStatus.room.languages) {
        let langIndex = this.roomStatus.room.languages.indexOf(this.roomStatus.interpreter.lang);
        if (langIndex !== -1) {
          langIndex ++;
          if (langIndex >= this.roomStatus.room.languages.length) {
            langIndex = 0;
          }
          this.setOutputLang(this.roomStatus.room.languages[langIndex]);
        }
      }
      event.preventDefault();
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {

    if (event.target['tagName'] === 'INPUT') {
      return;
    }

    if (event.code === 'ArrowDown') {
      this.maxVolume -= 10;
      if (this.maxVolume < 0) {
        this.maxVolume = 0;
      }
      this.setGains();
      event.preventDefault();
    }
    if (event.code === 'ArrowUp') {
      this.maxVolume += 10;
      if (this.maxVolume > 100) {
        this.maxVolume = 100;
      }
      this.setGains();
      event.preventDefault();
    }

    if (event.code === 'Space') {
      this.mute();
      event.preventDefault();
    }
  }


  sendText() {
    const inputField = <HTMLInputElement>document.getElementById('chatmsg');
    this.onEnter(inputField.value);
  }

  onEnter(value: string) {
    if (value !== '') {
      if (this.httpMode) {
        this.otrHttpRequestService.doPost('/room/' + this.eventId + '/text', {text: value}).subscribe(data => {
          this.roomStatus.update(data);
        }, err => {
          console.log('err: ' + JSON.stringify(err));
        });
      } else {
        this.roomStatus.sendText({text: value});
      }
      const inputField = <HTMLInputElement>document.getElementById('chatmsg');
      inputField.value = '';
    }
  }

  setOutputLang(lang) {
    if (this.roomStatus.room.state !== 0 && !this.autoChangeOutput) {
      if (this.httpMode) {
        this.otrHttpRequestService
          .doGet('/room/' + this.eventId + '/lang/' + lang)
          .subscribe(
            (data) => {
              this.roomStatus.update(data);
            },
            (err) => {
              console.log('err: ' + JSON.stringify(err));
            }
          );
      } else {
        this.roomStatus.setLang(lang);
      }
    }
  }

  getMaxVolume() {
    return {'left': (1.4 * this.maxVolume) + 'px'};
  }


  setGains() {
    this.roomStatus.room.users.forEach(user => {
      if (user.subscription) {
        const val = (this.eventDisplayState._maxVolume / 100.0);
        user.subscription.gain.gain.setValueAtTime(val, user.subscription.gain.context.currentTime);
        if (user.subscription.gain.context.state === 'suspended') {
          user.subscription.gain.context.resume();
        }
      }
    });
  }


  mergeRecursive(obj1, obj2) {
    for (const p in obj2) {
      if (obj2.hasOwnProperty(p)) {
        try {
          if (obj2[p].constructor === Object) {
            obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
          } else if (Array.isArray(obj2[p])) {
            if (obj2[p].length < 1) {
              obj1[p] = obj2[p];
            } else {
              obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
            }
          } else {
            obj1[p] = obj2[p];
          }
        } catch
          (e) {
          // Property in destination object not set; create it and set its value.
          obj1[p] = obj2[p];
        }
      }
    }
    return obj1;
  }

  ngOnInit() {
    const me = this;
    super.eventId = this.eventId;
    me.isFirefox = false; // window.navigator.userAgent.indexOf("Firefox") !== -1 ;
    //  if(!me.isFirefox){
    this.myRoom = 'conference' + this.eventId;

    this.init();


    //    }
    this.uploader = new FileUploader({url: '/event-files/' + this.eventId, removeAfterUpload: true, autoUpload: true});
    this.uploader.onCompleteAll = () => {
      this.uploadIsProgress = false;
      // send message to refresh files
      if (this.httpMode) {
        this.otrHttpRequestService.doGet('/room/' + this.eventId + '/filesChanged').subscribe(data => {
          this.roomStatus.update(data);
        }, err => {
          console.log('err: ' + JSON.stringify(err));
        });
      } else {
        this.roomStatus.filesChanged();
      }

    };
    this.uploader.onProgressAll = (progress: any) => {
      this.uploadIsProgress = true;
    };
    this.uploader.onErrorItem = ((item, response, status, headers): any => {
      if (status === 413) { // túl nagy fájl
        this.tooLargeFile = true;
        setTimeout(() => {
          this.tooLargeFile = false;
        }, 2000);
      }
    });
  }

  eventStateHandler() {

    this.roomStatus.setAllStream(
      (user) => user.id === this.roomStatus.room.speakingUser,
      (user) => user.id === this.roomStatus.room.speakingUser
    );
    if (this.autoChangeInput) {
      if (
        this.roomStatus.room.speakingUser === -1 &&
        this.roomStatus.room.waitingUsers.length > 0
      ) {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/accept')
            .subscribe((data) => {
              this.roomStatus.update(data);
            });
        } else {
          this.roomStatus.accept();
        }
      }
    }
    if (this.autoChangeOutput) {
      if (
        this.roomStatus.room.speakingUser !== -1 &&
        this.roomStatus.room.speakingUser !== undefined
      ) {
        const user = this.roomStatus.room.users.find(
          (usr) => usr.id === this.roomStatus.room.speakingUser
        );
        if (this.roomStatus.interpreter.lang === user.lang) {
          const idx = this.roomStatus.room.languages.indexOf(user.lang);
          const lang = this.roomStatus.room.languages[(idx + 1) % 2];
          if (this.httpMode) {
            this.otrHttpRequestService
              .doGet('/room/' + this.eventId + '/lang/' + lang)
              .subscribe(
                (data) => {
                  this.roomStatus.update(data);
                },
                (err) => {
                  console.log('err: ' + JSON.stringify(err));
                }
              );
          } else {
            this.roomStatus.setLang(lang);
          }
        }
      }
    }
  }


  createToken(room, userName, role, callback) {
    const req = new XMLHttpRequest();
    const url = '/createTokenByName/';
    const body = {
      room: room,
      username: userName,
      role: role
    };
    const me = this;
    req.onreadystatechange = function () {
      if (req.readyState === 4) {
        callback.call(me, req.responseText);
      }
    };
    req.open('POST', url, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(JSON.stringify(body));
  }

  removeStreamFromShowedStreams(stream) {
    const index = this.showedRemoteStreams.indexOf(stream);
    if (index >= 0) {
      this.showedRemoteStreams.slice(index, 1);
    }

  }

  displayStream(stream, resolution, targetDiv, enableAudio) {
    const streamId = stream.id;

    resolution = resolution || {
      width: 320,
      height: 240
    };

    // Elég csak a videó, az audió máshol van kezelve
    this.subscriptions[stream.id].video(true);

    const parent = document.getElementById(targetDiv);
    if (parent === undefined) {
      alert('undefined ' + targetDiv);
    }
    if (parent) {
      let div = document.getElementById('test' + streamId);
      if (!div) {
        div = document.createElement('div');
        div.setAttribute('id', 'test' + streamId);
        div.setAttribute('title', 'Stream#' + streamId);
        parent.appendChild(div);
        const video = document.createElement('video');
        video.setAttribute('width', '100%');
        video.setAttribute('height', '100%');
        video.setAttribute('autoplay', '');
        video.setAttribute('id', 'video' + streamId);
        div.appendChild(video);
      }
      div.setAttribute('style', 'width: ' + resolution.width + 'px; height: ' + resolution.height + 'px;position:absolute');

      const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('video' + streamId));
      try {
        localVideo.srcObject = stream.mediaStream;
      } catch (error) {
        localVideo.src = URL.createObjectURL(stream.mediaStream);
      }

      if (!enableAudio) {
        localVideo.volume = 0;
      }
    }
  }


  ngOnDestroy() {
    this.destroy();
    this.otrMainJoinEventService.loadEvents();
  }

  mute() {
    if (this.roomStatus.localStream) {
      this.eventDisplayState.muteOn = true;
      this.roomStatus.localStream.audio(false);
    }
  }

  unmute() {
    if (this.roomStatus.localStream) {
      this.eventDisplayState.muteOn = false;
      this.roomStatus.localStream.audio(this.eventDisplayState.micOn);
    }
  }

  toggleMic() {
    if (this.roomStatus.localStream) {
      if (this.eventDisplayState.micOn) {
        this.eventDisplayState.micOn = false;
        if (this.sub) {
          this.sub.unsubscribe();
        }
      } else {
        this.eventDisplayState.micOn = true;
        const timer = Observable.timer(1, 1000);
        this.sub = timer.subscribe((t) => {
          this.ticks = t;

          this.secondsDisplay = ('' + (this.ticks % 60)).padStart(2, '0');
          this.minutesDisplay = ('' + (Math.floor(this.ticks / 60) % 60)).padStart(2, '0');
          this.hoursDisplay = ('' + (Math.floor(this.ticks / 60 / 60))).padStart(2, '0');
        });
      }
      this.roomStatus.localStream.audio(this.eventDisplayState.micOn);
      this.setAudioVideoState();
    }
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  toggleCamera() {
    if (this.roomStatus.localStream && this.roomStatus.room.state === 0) {
      this.setAudioVideoEnabled(this.audioEnabled(), !this.videoEnabled());
    }
  }

  trackByFn(index: number, item: any): any {
    return item.time + item.userId;
  }

  backClicked() {
    this._location.back();
  }

  onRoomStatusUpdate(): void {
    if (this.roomStatus.show !== -1 && this.roomStatus.show !== undefined) {
      this.roomStatus.displayUserStreamById(this.roomStatus.show, 'videoSpeakingUser');
    }
    if (this.roomStatus.localStream) {
      if (this.roomStatus.localStream.audioMuted === 1 && this.eventDisplayState.micOn) {
        this.roomStatus.localStream.audio(this.eventDisplayState.micOn);
      }
      if (this.roomStatus.localStream.audioMuted === 0 && !this.eventDisplayState.micOn) {
        this.roomStatus.localStream.audio(this.eventDisplayState.micOn);
      }
    }
  }

  start() {
    // TODO new mode
    this.otrHttpRequestService.doGet('/room/' + this.eventId + '/lang/' + this.roomStatus.room.languages[0]).subscribe(data => {
      if (this.httpMode) {
        this.roomStatus.update(data);
        this.otrHttpRequestService.doGet('/room/' + this.eventId + '/start').subscribe(dataStart => {
          this.roomStatus.update(dataStart);
        }, err => {
          console.log('err: ' + JSON.stringify(err));
        });
      } else {
        this.roomStatus.start();
      }
      this.otrHttpRequestService.doPostAndCheck('/event-data/finish/' + this.eventId, {}, () => {
      });
    }, err => {
      console.log('err: ' + JSON.stringify(err));
    });

  }

  stop() {
    if (this.httpMode) {
      this.otrHttpRequestService.doGet('/room/' + this.eventId + '/stop').subscribe(data => {
        this.roomStatus.update(data);
      }, err => {
        console.log('err: ' + JSON.stringify(err));
      });
    } else {
      this.roomStatus.stop();
    }
  }
}
