import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrUserService} from '../../otr-user.service';
import {OtrLangService} from '../../otr-lang.service';
import {Subscription} from 'rxjs/Subscription';
import {OtrCodeLoadService} from '../../otr-code-load.service';

@Component({
  selector: 'app-otr-registration',
  templateUrl: './otr-registration.component.html'
})
export class OtrRegistrationComponent implements OnInit, OnDestroy {
  @ViewChild('registrationForm') registrationForm: HTMLFormElement;
  menus = [];
  registrationData = {nyelv: 'ENG'};
  errMessageData = {
    passwordAgain: null,
    termsofuse: null,
    password: null,
    keresztnev: null,
    vezeteknev: null,
    tipus: null,
    email: null
  };
  isNotLoggedIn = false;
  token: any;
  confToken: any;
  isConfirm = false;
  langEventDataStr: any = {};
  subscriptionLang: Subscription;
  langCode: any = {};
  currentLang = '';

  navBarStr: {
    altalanos_szerzodesi_feltetelek: string,
    adatvedelem: string
  };
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private otrHttpRequestService: OtrHttpRequestService, private otrUserService: OtrUserService,
              private otrLangService: OtrLangService, private otrCodeLoadService: OtrCodeLoadService) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      (lang) => {
        this.currentLang = lang;
        this.otrLangService.getValuesForGroup('registrationStr', res => {
          this.langEventDataStr = res;
        });
        this.otrCodeLoadService.loadCodesByCodeType('NYELV', data => {
          this.langCode = data;
        });
        this.otrUserService.getMenus(data => {
          this.menus = data;
        });
        this.otrLangService.getValuesForGroup('navBarStr', res => {
          this.navBarStr = res;
        });
      });
    if (activatedRoute.snapshot.params.token) {
      this.token = activatedRoute.snapshot.params.token;
    }
    if (activatedRoute.snapshot.params.confToken) {
      this.confToken = activatedRoute.snapshot.params.confToken;
      this.isConfirm = true;
    }
  }

  ngOnInit() {
    this.otrLangService.getValuesForGroup('registrationStr', res => {
      this.langEventDataStr = res;
      this.currentLang = this.otrLangService.getCurrentLang();

    });
    this.otrLangService.getValuesForGroup('navBarStr', res => {
      this.navBarStr = res;
    });
    this.otrUserService.getMenus(data => {
      this.menus = data;
    });
    this.otrCodeLoadService.loadCodesByCodeType('NYELV', data => {
      this.langCode = data;
    });
    this.otrUserService.getIsLoggedIn((data, lng) => {
      if (!data) {
        this.isNotLoggedIn = true;
        this.currentLang = lng;
      } else {
        this.router.navigate(['/home']);
      }
    });
    if (this.token) {
      this.loadUserByToken();
    }
  }

  loadUserByToken() {
    this.otrHttpRequestService.doGetAndCheck('/user-profile/load/' + this.token, data => {
      if (!data['isError']) {
        this.registrationData = data['result'];
      } else {
        this.registrationData = {nyelv: 'ENG'};
      }
    });
  }

  saveRegistration() {
    this.errMessageData = {
      passwordAgain: null,
      termsofuse: null,
      password: null,
      keresztnev: null,
      vezeteknev: null,
      tipus: null,
      email: null
    };
    this.otrHttpRequestService.doPostAndCheck('/registration' + (this.token ? '/' + this.token : ''), this.registrationData, res => {
        if (res['isError']) {
          this.errMessageData = res;
          for (const key in res) {
            if (res[key] != null) {
              this.registrationForm.form.controls[key].setErrors({'incorrect': true});
            } else {
              this.registrationForm.form.controls[key].setErrors(null);
            }
          }
        } else {
          this.router.navigate(['auth']);
        }
      }
    );
  }

  confirmRegistration() {
    this.errMessageData = {
      passwordAgain: null,
      termsofuse: null,
      password: null,
      keresztnev: null,
      vezeteknev: null,
      tipus: null,
      email: null
    };
    this.otrHttpRequestService.doPostAndCheck('/registration/confirm/' + this.confToken, {}, res => {
        if (res['isError']) {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key) && this.registrationForm.form.controls[key] != undefined) {
              if (res[key] != null) {
                this.registrationForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.registrationForm.form.controls[key].setErrors(null);
              }
            }
          }
        } else {
          this.router.navigate(['auth']);
        }
      }
    );
  }


  selectLang() {
    this.otrLangService.selectLang('', '');
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }
}
