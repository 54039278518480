<div class="simypanel panel panel-primary" >
  <div *ngIf="eventMode === 'CONFERENCE'">
    <div *ngIf="mode == 'O' && showMonitor">
      <app-otr-conference-monitor-partner eventId="{{eventId}}" (closeHandler)="this.hideMonitor()"></app-otr-conference-monitor-partner>
    </div>
    <div *ngIf="mode == 'T' && showMonitor">
      <app-otr-conference-monitor-interpreter   eventId="{{eventId}}" (closeHandler)="this.hideMonitor()"></app-otr-conference-monitor-interpreter >
    </div>
    <div *ngIf="mode == 'U' && showMonitor">
      <div class="no-remote-users">
        <span>Remote speakers are not supported</span>
      </div>
    </div>
  </div>

  <div *ngIf="eventMode === 'EVENT'">
    <div class="no-remote-users">
      <span>Wrong link</span>
    </div>
  </div>
</div>
