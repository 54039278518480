<div *ngIf="isLoggedIn" class="animate d-flex align-items-center justify-content-center"
style="height: 100%">
  <div class="card" style="width: 300px;">
    <div class="card-title ">{{langEventDataStr.jelszo_modositas}}</div>
    <div class="card-subtitle ">&nbsp;</div>
    <div style="margin-left: 15px;margin-right: 15px;">
      <form (ngSubmit)="modPassword()" #profileForm="ngForm" novalidate>
        <div class="form-content">
          <div class="form-group">
            <label for="passwordold">{{langEventDataStr.regi_jelszo}} *</label>
            <input type="password" class="form-control input-underline input-lg" [(ngModel)]="passwordData.passwordold"
                   id="passwordold" name="passwordold"
                   placement="top" ngbTooltip="{{ errMessageData.passwordold }}">
          </div>
          <div class="form-group">
            <label for="password">{{langEventDataStr.uj_jelszo}} *</label>
            <input type="password" class="form-control input-underline input-lg" [(ngModel)]="passwordData.password"
                   id="password" name="password"
                   placement="top" ngbTooltip="{{ errMessageData.password }}">
          </div>
          <div class="form-group">
            <label for="passwordAgain">{{langEventDataStr.uj_jelszo_megegyszer}} *</label>
            <input type="password" class="form-control input-underline input-lg" [(ngModel)]="passwordData.passwordAgain"
                   id="passwordAgain" name="passwordAgain"
                   placement="top" ngbTooltip="{{ errMessageData.passwordAgain }}">
          </div>
        </div>
        <div style="margin-top: 20px;margin-bottom: 20px;text-align: right">
          <button type="submit" class="button">{{langEventDataStr.mentes}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
