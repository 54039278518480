import { Component, OnInit, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-otr-event-monitor-conference',
  templateUrl: './otr-event-monitor-conference.component.html',
  styleUrls: ['./otr-event-monitor-conference.component.css']
})
export class OtrEventMonitorConferenceComponent implements OnInit {

    @Input()
    eventId: any;
  constructor() { }

  ngOnInit() {
  }

}
