<div *ngIf="isLoggedIn" class="animate" #pageroot>
  <div class="page-navbar nav ">
    <div class="row" style="width: 100%">
      <h3>{{langEventDataStr.felhasznalok}}</h3>
    </div>
  </div>


  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col" style="padding: 15px">
        <div class="card">
          <div class="card-title"></div>
          <div class="card-subtitle"></div>
          <div class="card-body" style="overflow-x:auto;">
            <table id="userstable" class="table table-striped">
              <thead>
              <tr>
                <th scope="col">{{langEventDataStr.nev}}</th>
                <th scope="col">{{langEventDataStr.email}}</th>
                <th scope="col">{{langEventDataStr.tolmacs_adatai}}</th>
                <th scope="col">{{langEventDataStr.tipus}}</th>
                <th scope="col">{{langEventDataStr.zarolas}}</th>
                <th scope="col">{{langEventDataStr.torles}}</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let loadedUser_ of loadedUsers">
                <td *ngIf="loadedUser_.erv_tol">
                  <a class="nav-link linkBold"
                     [routerLink]="['/user-profile/admin', loadedUser_.id]">{{ loadedUser_.vezeteknev }} {{ loadedUser_.keresztnev }}</a>
                </td>
                <td *ngIf="!loadedUser_.erv_tol">
                  <a class="nav-link linkBold"
                     [routerLink]="['/user-profile/admin', loadedUser_.id]">{{ loadedUser_.vezeteknev }} {{ loadedUser_.keresztnev }}</a>&nbsp;({{langEventDataStr.nem_ervenyes}}
                  )
                </td>
                <td>{{ loadedUser_.email }}</td>
                <td *ngIf="loadedUser_.tipus == 'T'">
                  <a class="nav-link linkBold"
                     [routerLink]="['/interpreter-profile/admin', loadedUser_.id]">{{langEventDataStr.tolmacs_adatai}}</a>
                </td>
                <td *ngIf="loadedUser_.tipus != 'T'">-</td>
                <td style="min-width: 80px;">
                  <select class="form-control input-lg" [value]="loadedUser_.tipus" #userTypeSelect
                          (change)="changeUserType(loadedUser_.id, userTypeSelect.value)"
                          name="userType_loadedUser_.id">
                    <option value="U" class="custom_underline_option">{{langEventDataStr.ugyfel}}</option>
                    <option value="T" class="custom_underline_option">{{langEventDataStr.tolmacs}}</option>
                    <option value="A" class="custom_underline_option">{{langEventDataStr.admin}}</option>
                    <option value="X" class="custom_underline_option">{{langEventDataStr.teacher}}</option>
                    <option value="Y" class="custom_underline_option">{{langEventDataStr.student}}</option>
                  </select>
                </td>
                <td *ngIf="loadedUser_.zarolva == 'N'">
                  <a type="button" class="function"
                          (click)="lockUser(loadedUser_.id)">{{langEventDataStr.zarolas}}</a>
                </td>
                <td *ngIf="loadedUser_.zarolva == 'Y'">
                  <a type="button" class="function"
                          (click)="unlockUser(loadedUser_.id)">{{langEventDataStr.feloldas}}</a>
                </td>
                <td>
                  <a type="button" class="function"
                          (click)="deleteUser(loadedUser_.id)">{{langEventDataStr.torles}}</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
