import {EventDisplayState} from './EventDisplayState';
import {RoomStatus} from './RoomStatus';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {Observable} from 'rxjs/Observable';
import {takeWhile} from 'rxjs/operators';
import {FileHandler} from './FileHandler';
import {ServerConnection} from './server-connection';
import {Component, Input} from '@angular/core';
import {OtrLogService} from '../otr-log.service';
import {ImageModifyService} from '../image-modify.service';
import {SubscriptionLike} from 'rxjs';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-event-component',
  template: '',
  styles: []
})
export abstract class EventComponent {


  @Input()
  eventId: any;

  outputWidth = 1280;
  outputHeight = 960;
  audioBitrate = 300;
  videoBitrate = 1500;
  publishOptions = {};
  subscribeOptions = {};
  /*
  videoCodec = 'vp8';
  audioCodec = 'opus';
  useSimulcast = false;
   */
  videoCodec = 'vp8';
  audioCodec = 'opus';
  useSimulcast = true;

  _maxVolume: any = 50;

  timerSubscription: SubscriptionLike;

  hasBaseDropZoneOver = false;

  fileHandler: FileHandler = undefined;
  serverConnection: ServerConnection = undefined;
  eventDisplayState: EventDisplayState;
  roomStatus: RoomStatus;

  analyser: any;
  showMuteMessage = false;
  showAllSpeaker: boolean;
  private alive = true;
  userName: string;
  nickname: string;

  imageDescriptor: any;
  private _offline: boolean;
  private bindedListener: any;
  httpMode = false;

  constructor(
    private httpRequestService: OtrHttpRequestService,
    private logService: OtrLogService,
    private urlPart: String,
    private imageModify: ImageModifyService,
    public platform: Platform
  ) {
    const sets = localStorage.getItem('simy_settings');
    const settings = sets != null ? JSON.parse(sets) : {useSimulcast: true, maxVolume: 50, cameraOn: false};

    this.useSimulcast = settings.useSimulcast;
    this._maxVolume = settings.maxVolume;
    this.eventDisplayState = new EventDisplayState(this);
    if (urlPart === 'room') {
      this.showAllSpeaker = false;
    } else {
      this.showAllSpeaker = true;
    }
    if (this.platform.is('ios') || this.platform.is('android')) {
      this.useSimulcast = true;
      this.outputWidth = 640;
      this.outputHeight = 480;
      this.audioBitrate = 200;
      this.videoBitrate = 800;
    }
    window.addEventListener('offline', () => {
      this.offline = true;
    });
  }

  init(): void {
    const source = document.getElementById('localVideo');
    const target = document.getElementById('canvas-output');
    this.imageDescriptor = this.imageModify.createDescriptor(source, target);
    this.imageDescriptor.blurredEnabled = false;
    this.imageDescriptor.index = 0;
    this.logService.eventId = this.eventId;
    this.fileHandler = new FileHandler(this.httpRequestService, this);

    this.initServices();
  }

  initServices() {
    this.roomStatus = new RoomStatus(this.httpRequestService, this);
    if (this.serverConnection) {
      this.serverConnection.onDestroy();
    }
    this.serverConnection = new ServerConnection(this.httpRequestService, this, this.eventId, this.roomStatus, this.imageModify);
    this.loadFullRoomState(this);
  }

  get offline(): boolean {
    return this._offline;
  }

  onlineEventListener(event) {
    console.log('ONLINE BACK');
    window.removeEventListener('online', this.bindedListener);
    setTimeout(() => {
      this.initServices();
    }, 1000);
  }

  set offline(value: boolean) {
    const prev = this._offline;
    this._offline = value;
    if (this._offline) {
      if (navigator.onLine) {
        console.log('OFFLINE -> ONLINE');
        this.initServices();
      } else {
        this.bindedListener = this.onlineEventListener.bind(this);
        window.addEventListener('online', this.bindedListener);
      }
    }
  }

  saveSettings() {
    localStorage.setItem('simy_settings', JSON.stringify(
      {
        useSimulcast: this.useSimulcast,
        maxVolume: this._maxVolume
      }
    ));
  }

  toggleSimulcast() {
    this.useSimulcast = !this.useSimulcast;
    this.saveSettings();
    window.location.reload();
  }

  loadFullRoomState(me: this) {
    console.log('load full room state');
    this.httpRequestService.doGet('/roomnc/' + this.eventId).subscribe(fullRoomResult => {
      console.log('load full room state result', fullRoomResult);
      if (fullRoomResult.stunServer) {
        this.serverConnection.rtcConfiguration.iceServers = [{
          urls: [fullRoomResult.stunServer]
        }, {
          urls: [fullRoomResult.turnServer],
          credential: fullRoomResult.turnPassword,
          username: fullRoomResult.turnUser
        }];
        this.outputWidth = fullRoomResult.streamProperties.width;
        this.outputHeight = fullRoomResult.streamProperties.height;
        this.videoCodec = fullRoomResult.streamProperties.videoCodec;
        this.publishOptions = fullRoomResult.streamProperties.publishOptions;
        this.subscribeOptions = fullRoomResult.streamProperties.subscribeOptions;
      }

      this.roomStatus.update(fullRoomResult);
      this.logService.userId = '' + this.roomStatus.self.selfId;
      if (!this.timerSubscription) {
        this.timerSubscription = Observable.interval(3000).pipe(
          takeWhile(() => me.alive))
          .startWith(1000).switchMap(
          //  () => this.httpRequestService.doGet('/' + this.urlPart + '/' + this.eventId)
            () => {
              return this.roomStatus.ping();
            }
          )
          .retryWhen((errors) => errors.delay(1000))
          .subscribe(result => {} /*me.roomStatus.update(result) */);
      }

      me.userName = me.roomStatus.self.name.replace(/ /g, '_');
      me.nickname = me.roomStatus.self.nickname;
      me.serverConnection.joinEvent(me.userName, me.imageDescriptor);
    });
  }

  setAudioVideoState(): void {
    if (this.httpMode) {
      this.httpRequestService.doGet(
        '/room/' + this.eventId + '/av/' + (this.audioEnabled() ? 1 : 0) + '/' + (this.videoEnabled() ? 1 : 0)
      ).subscribe(data => {
        this.roomStatus.update(data);
      }, err => {
        console.log('err: ' + JSON.stringify(err));
      });
    } else {
      this.roomStatus.setAV((this.audioEnabled() ? 1 : 0), (this.videoEnabled() ? 1 : 0));
    }
  }

  setAudioVideoEnabled(audio, video): void {
    const serverAudioState = (this.roomStatus.self.audioState === 1);
    const serverVideoState = (this.roomStatus.self.videoState === 1);
    if (audio !== serverAudioState || video !== serverVideoState) {
      if (this.httpMode) {
        this.httpRequestService.doGet(
          '/room/' + this.eventId + '/av/' + (audio ? 1 : 0) + '/' + (video ? 1 : 0)
        ).subscribe(data => {
          this.roomStatus.update(data);
        }, err => {
          console.log('err: ' + JSON.stringify(err));
        });
      } else {
        this.roomStatus.setAV((audio ? 1 : 0), (video ? 1 : 0));
      }
    }
  }

  onRoomStatusUpdate(): void {
  }

  speakerChanged(): void {
  }

  eventStateHandler(): void {
  }

  gatheringStateHandler(): void {
  }

  setShowMuteMessage(show: boolean): void {
    this.showMuteMessage = show;
  }


  getEventState(): EventDisplayState {
    return this.eventDisplayState;
  }

  eventStateModified(): void {
  }

  get maxVolume(): number {
    return this._maxVolume;
  }

  set maxVolume(value: number) {
    this._maxVolume = value;
    this.setGains();
  }

  getMaxVolume() {
    return {'left': (1.4 * this.maxVolume) + 'px'};
  }

  setGains() {
    this.roomStatus.room.users.forEach(user => {
      if (user.subscription && user.subscription.gain) {
        const val = ( this._maxVolume / 100.0 );
        user.subscription.gain.gain.setValueAtTime(val, user.subscription.gain.context.currentTime);
        if (user.subscription.gain.context.state === 'suspended') {
          user.subscription.gain.context.resume();
        }
      }
    });
    this.saveSettings();
  }

  sendText() {
    const inputField = <HTMLInputElement>document.getElementById('chatmsg');
    this.onEnter(inputField.value);
  }

  onEnter(value: string) {
    if (value !== '') {
      if (this.httpMode) {
        this.httpRequestService.doPost('/room/' + this.eventId + '/text/', {text: value}).subscribe(data => {
          this.roomStatus.update(data);
        });
      } else {
        this.roomStatus.sendText({text: value});
      }
      const inputField = <HTMLInputElement>document.getElementById('chatmsg');
      inputField.value = '';
    }
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  destroy(): void {
    this.alive = false;
    if (this.roomStatus.localStream) {
      if (this.roomStatus.localStream.subscription) {
        this.roomStatus.localStream.subscription.stop();
      }
      try {
        this.roomStatus.localStream.stream.mediaStream.getTracks().forEach(function (mediaStreamTrack) {
          mediaStreamTrack.stop();
        });
      } catch (e) {
        console.log(e);
      }
    }
    this.serverConnection.onDestroy();

    try {
      if (this.timerSubscription !== undefined && !this.timerSubscription.closed) {
        this.timerSubscription.unsubscribe();
      }
    } catch (e) {
      console.log(e);
    }
  }

  setNickname() {
    if (this.httpMode) {
      this.httpRequestService.doGet('/room/' + this.eventId + '/nick/' + this.nickname).subscribe(data => {
        this.roomStatus.update(data);
      }, err => {
        console.log('err: ' + JSON.stringify(err));
      });
    } else {
      this.roomStatus.sendNick(this.nickname);
    }
  }

  setLang(lang: string) {
    if (this.httpMode) {
      this.httpRequestService.doGet('/room/' + this.eventId + '/lang/' + lang).subscribe(data => {
        this.roomStatus.update(data);
      }, err => {
        console.log('err: ' + JSON.stringify(err));
      });
    } else {
      this.roomStatus.setLang(lang);
    }
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
  }

  videoEnabled() {
    return this.roomStatus.self && this.roomStatus.self.videoState === 1;
  }

  audioEnabled() {
    return this.roomStatus.self && this.roomStatus.self.audioState === 1;
  }

}
