<div class="ext-home" *ngIf="!isMobile">
<div class="home_header">
  <div class="logo"><img width="100%" src="../../assets/landing/images/logo_sotet_kek_horizont.png"></div>
  <div class="link">
    <a class="button" href="#about">{{texts[currentLanguage].aboutUs}}</a>
  </div>
  <div class="link">
    <a class="button" href="/#/registration">{{texts[currentLanguage].registration}}</a>
  </div>
  <div class="link">
    <a class="button" href="/#/auth">{{texts[currentLanguage].signIn}}</a>
  </div>

  <div class="flag" (click)="changeLang(texts[currentLanguage].nextLang)"><img width="100%" src="../../assets/landing/images/{{texts[currentLanguage].langIcon}}"></div>
  <div class="menu-icons">
    <a href="#about">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
      </svg>
    </a>
    <a href="/#/registration">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
        <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
      </svg>
    </a>
    <a href="/#/auth">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
        <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
      </svg>
    </a>
  </div>
</div>
<div class="banner1">
  <div class="big-text">{{texts[currentLanguage].banner1_big}}</div>
  <div class="small-text">{{texts[currentLanguage].banner1_small}}</div>
  <div class="hover-button" data-toggle="modal" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">{{texts[currentLanguage].banner1_button}}</div>

  <div class="modal fade" id="collapse1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <img height="30px" src="../../assets/landing/images/logo_sotet_kek_horizont.png">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <br/>
          <span class="first-row">{{texts[currentLanguage].questionModal[0]}}</span>
          <br/>
          {{texts[currentLanguage].questionModal[1]}}
          <br/>
          {{texts[currentLanguage].questionModal[2]}}
          <br/>
          {{texts[currentLanguage].questionModal[3]}}
          <br/>
          {{texts[currentLanguage].questionModal[4]}}
          <br/>
          {{texts[currentLanguage].questionModal[5]}}
        </div>
      </div>
    </div>
  </div>
</div>



<div class="banner2" id="about" >
  <div class="big-text">{{texts[currentLanguage].banner2_big}}</div>
  <div class="small-text">{{texts[currentLanguage].banner2_small1}}</div>
  <div class="small-text">{{texts[currentLanguage].banner2_small2}}</div>
  <div class="features">
    <div class="feature"><img src="../../assets/landing/images/simy_landing_4.png">{{texts[currentLanguage].banner2_feature1}}</div>
    <div class="feature"><img src="../../assets/landing/images/simy_landing_3.png">{{texts[currentLanguage].banner2_feature2}}</div>
    <div class="feature"><img src="../../assets/landing/images/simy_landing_2.png">{{texts[currentLanguage].banner2_feature3}}</div>
  </div>
  <div class="button-container">
    <a class="hover-button" href="#process">{{texts[currentLanguage].banner2_button}}</a>
  </div>
</div>
<div class="banner3" id="process">
  <div class="big-text">{{texts[currentLanguage].banner3_big}}</div>
  <div class="features">
    <div class="feature">{{texts[currentLanguage].banner3_feature1}}</div>
    <div class="feature">{{texts[currentLanguage].banner3_feature2}}</div>
    <div class="feature">{{texts[currentLanguage].banner3_feature3}}</div>
    <div class="feature">{{texts[currentLanguage].banner3_feature4}}</div>
  </div>
</div>
<div class="banner4">
  <div class="big-text">{{texts[currentLanguage].banner4_big}}</div>
  <div class="features">
    <div class="feature">
      <img src="../../assets/landing/images/simy_landing_5.png">
      <div class="hover-button"
           data-toggle="modal"
           data-target="#interpeterModal"
           aria-expanded="false"
           aria-controls="collapse1">{{texts[currentLanguage].banner4_feature1}}</div>
    </div>
    <div class="feature">
      <img src="../../assets/landing/images/simy_landing_6.png">
      <div class="hover-button"
           data-toggle="modal"
           data-target="#userModal"
           aria-expanded="false"
           aria-controls="collapse1">{{texts[currentLanguage].banner4_feature2}}</div>
    </div>
  </div>
  <div class="modal fade" id="interpeterModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <img height="30px" src="../../assets/landing/images/logo_sotet_kek_horizont.png">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <br/>
          <span class="first-row">{{texts[currentLanguage].interpreterModal[0]}}</span>
          <br/>
          {{texts[currentLanguage].interpreterModal[1]}}
          <br/>
          <a onclick="location.href='/#/registration';" data-dismiss="modal">
            <div class="web-logo">
              <div class="web-logo-inner"></div>
            </div>
          </a>
          <br/>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="userModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <img height="30px" src="../../assets/landing/images/logo_sotet_kek_horizont.png">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <br/>
          <span class="first-row">{{texts[currentLanguage].userModal[0]}}</span>
          <br/>
          {{texts[currentLanguage].userModal[1]}}
          <br/>
          {{texts[currentLanguage].userModal[2]}}
          <br/>
          <a href="https://apps.apple.com/hu/app/simy/id1403345468?itsct=apps_box_link&itscg=30200">
            <img src="../../assets/landing/images/apple.png">
          </a>
          <a href="https://play.google.com/store/apps/details?id=hu.combit.simy">
            <img src="../../assets/landing/images/google.png">
          </a>
          <a onclick="location.href='/#/registration';" data-dismiss="modal">
            <div class="web-logo">
              <div class="web-logo-inner"></div>
            </div>
          </a>
          <br/>
        </div>
      </div>
    </div>
  </div>
</div>
<p class="parallax_one_copyright_content">
  <a class="linkme-mini" target="_blank" href="assets/document/aszf.pdf">{{texts[currentLanguage].aszf}}</a><br/>
  <a class="linkme-mini" target="_blank" href="assets/document/adatv_nyil.pdf">{{texts[currentLanguage].privacy}}</a><br/>
  <a class="copyright-text"></a>
</p>
</div>
