import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {OtrUserService} from '../otr-user.service';
import {OtrLangService} from '../otr-lang.service';
import {OtrConfirmationDialogService} from '../otr-confirmation-dialog.service';
import {Subscription} from 'rxjs/Subscription';
import {OtrMainJoinEventService} from '../otr-main-join-event.service';


interface SimyEvent {
  tipus: string;
  id: number;
  esemeny_neve: string;
  esemeny_kezdete: string;
  esemeny_statusz: string;
  esemeny_statusz_kod: string;
  isNotAnswered: boolean;
  isAnswerAble: boolean;
  isAccept: boolean;
  isMine: boolean;
  isInviteable: boolean;
  isJoinble: boolean;
  isDeleteable: boolean;
}

interface EventDataStr {
  esemeny_lekerdezes: string;
  uj_esemeny: string;
  uj_konferencia: string;
  muveletek: string;
  statusz: string;
  esemeny_kelte: string;
  esemeny_neve: string;
  csatlakozas: string;
  resztvevok: string;
  torles: string;
  lemondas: string;
  elutasitas: string;
  elfogadas: string;
  varakozo: string;
}

@Component({
  selector: 'app-otr-event-query',
  templateUrl: './otr-event-query.component.html'
})

export class OtrEventQueryComponent implements AfterViewInit, OnInit, OnDestroy {
  eventQueries: Array<SimyEvent> = [];
  eventQueriesFull: Array<SimyEvent> = [];
  isLoggedIn = false;
  userType: any = 'T';
  langEventDataStr: EventDataStr = {
    esemeny_lekerdezes: '',
    uj_esemeny: '',
    uj_konferencia: '',
    muveletek: '',
    statusz: '',
    esemeny_kelte: '',
    esemeny_neve: '',
    csatlakozas: '',
    resztvevok: '',
    torles: '',
    lemondas: '',
    elutasitas: '',
    elfogadas: '',
    varakozo: ''
  };
  langEventStatusStr: any;
  subscriptionLang: Subscription;

  public tableWidget: any = undefined;
  currentLang = '';
  onlyWaiting = true;

  constructor(
    private router: Router,
    private otrConfirmationDialogService: OtrConfirmationDialogService,
    private otrHttpRequestService: OtrHttpRequestService,
    private otrUserService: OtrUserService,
    private otrLangService: OtrLangService,
    private otrMainJoinEventService: OtrMainJoinEventService
  ) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      (lang) => {
        this.currentLang = lang;
        this.loadLangStr();
      }
    );
  }

  ngOnInit(): void {
    this.currentLang = this.otrLangService.currentLangId;
    this.loadLangStr();
  }

  loadLangStr(): void {
    this.otrLangService.getValuesForGroup('eventQueryStr',
      res => {
        this.langEventDataStr = res;
        this.otrLangService.getValuesForGroup('db.ESEMENY_STATUSZ',
          result => {
            this.langEventStatusStr = result;
            this.filterEvents();
          }
        );
      }
    );
  }

  ngAfterViewInit(): void {
    this.reInitDatatable();
    this.loadEventQuerys();
  }

  filterEvents(): void {
    if (this.onlyWaiting) {
      this.eventQueries = this.eventQueriesFull.filter(
        (evt: SimyEvent) => evt.isAnswerAble /*|| evt.isInviteable*/ || evt.isJoinble /*|| evt.isDeleteable*/
      );
    } else {
      this.eventQueries = this.eventQueriesFull;
    }
    this.reInitDatatable();
  }

  loadEventQuerys() {
    this.otrHttpRequestService.doGetAndCheck('/event-query/load', data => {
      if (!data['isError']) {
        this.isLoggedIn = true;
        this.userType = data['userType'];
        if (data['result'].length > 0) {
          this.otrMainJoinEventService.loadEvents();
          this.eventQueriesFull = data['result'];
          this.filterEvents();
        } else {
          this.router.navigate(['/event/event-data']).then();
        }
      } else {
        if (!data['isNotLoggedin']) {
          this.isLoggedIn = true;
        }
        this.eventQueries = [];
        this.eventQueriesFull = [];
      }

      this.reInitDatatable();
    });
  }

  deleteEvent(eventId) {
    this.otrLangService.getValuesForGroup('dialogStr', res => {
      this.otrConfirmationDialogService
        .confirm(res.rendszer_uzenet, res.biztosan_torolni_akarja)
        .then(reqConf => {
          if (reqConf) {
            this.otrHttpRequestService.doDeleteAndCheck(
              '/event-query/' + eventId,
              () => {
                this.loadEventQuerys();
              }
            );
          }
        });
    });
  }

  newEvent() {
    this.otrUserService.getIsLoggedIn(data => {
      if (data) {
        this.router.navigate(['/event/event-data']).then();
      } else {
        this.otrUserService.doLogout();
      }
    });
  }

  newConference() {
    this.otrUserService.getIsLoggedIn(data => {
      if (data) {
        this.router.navigate(['/event/conference-data']).then();
      } else {
        this.otrUserService.doLogout();
      }
    });
  }

  controlInvite(newStatus, eventId) {
    // C lemond vagy D elutasít esetén rákérdezés
    this.otrLangService.getValuesForGroup('dialogStr', res => {
      this.otrConfirmationDialogService
        .confirm(res.rendszer_uzenet, newStatus === 'C' ? res.biztosan_lemondja : res.biztosan_elutasitja)
        .then(reqConf => {
          if (reqConf) {
            this.otrHttpRequestService.doPostAndCheck(
              '/invite-list/status/' + eventId + '/' + newStatus,
              {},
              () => {
                this.loadEventQuerys();
              }
            );
          }
        });
    });
  }

  acceptInvite(newStatus, eventId) {
    // TODO C lemond vagy D elutasít esetén rákérdezés, A esetén a régi
    this.otrHttpRequestService.doPostAndCheck(
      '/invite-list/status/' + eventId + '/' + newStatus,
      {},
      () => {
        this.loadEventQuerys();
      }
    );
  }

  participants(eventId) {
    this.otrUserService.getIsLoggedIn(data => {
      if (data) {
        this.router.navigate(['/event/invite-list/' + eventId]);
      } else {
        this.otrUserService.doLogout();
      }
    });
  }

  goEventMonitor(eventId) {
    const me = this;
    this.otrUserService.getIsLoggedIn(data => {
      const event = me.eventQueries.find(evt => evt.id === eventId);
      if (data) {
        if (event.tipus === 'EVENT') {
          this.router.navigate(['/event-monitor/' + eventId]).then();
        } else {
          this.router.navigate(['/conference-monitor/' + eventId]).then();
        }
      } else {
        this.otrUserService.doLogout();
      }
    });
  }

  private initDatatable(): void {
    const exampleId: any = $('#eventstable');
    this.tableWidget = exampleId.DataTable({
      select: true,
      retrieve: true,
      destroy: false,
      stateSave: true,
      language: {
        url: this.otrLangService.getCurrentDataTableLangUrl()
      },
      order: [[1, 'desc']],
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(
          'DataTables_' + settings.sInstance,
          JSON.stringify(data)
        );
      },
      stateLoadCallback: function (settings) {
        let ret = JSON.parse(
          localStorage.getItem('DataTables_' + settings.sInstance)
        );
        if (ret == null) {
          ret = {order: [[1, 'desc']]};
        }
        return ret;
      }
    });
  }

  private reInitDatatable(): void {
    if (this.tableWidget !== undefined && this.tableWidget.state() !== null) {
      this.tableWidget.destroy();
      this.tableWidget = undefined;
    }
    setTimeout(() => this.initDatatable(), 10);
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }

  onlyWaitingChange(): void {
    this.onlyWaiting = !this.onlyWaiting;
    this.filterEvents();
  }

  getStatusText(esemeny_statusz_kod: string) {
    if (this.langEventStatusStr !== undefined) {
      return this.langEventStatusStr[esemeny_statusz_kod];
    }
    return '';

  }
}
