import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {OtrHttpRequestService} from './otr-http-request.service';

@Injectable()
export class OtrFileService {

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
  }

  private newLoadFilesSource = new Subject<string>();
  newLoadFiles$ = this.newLoadFilesSource.asObservable();

  loadFiles(eventId) {
    this.otrHttpRequestService.doGetAndCheck('/event-files/' + eventId, data => {
      let fileList;
      if (!data['isError']) {
        if (data['result']) {
          fileList = data['result'];
        } else {
          fileList = {};
        }
      } else {
        fileList = {};
      }
      this.newLoadFilesSource.next(fileList);
    });
  }
}
