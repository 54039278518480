import {UserHandler} from './UserHandler';
import {SimyFile} from './SimyFile';
import {SimyText} from './SimyText';
import {RoomStatus} from './RoomStatus';
import {EventComponent} from './event-component';


export class SimyRoom {
  state: number;
  name: string;
  roomId: number;
  currentTime: number;
  languages: string[] = [];
  users: Array<UserHandler> = [];
  userTree: { id: -1, parent: -1, children: [] };
  offlineUsers: Array<number> = [];
  speakingUser: number;
  lastSpeakingUser: number;
  waitingUsers: Array<number> = [];
  otherOnlineUsers: Array<number> = [];
  texts: Array<SimyText> = [];
  files: Array<SimyFile> = [];
  conference: any;
  audioContext: AudioContext;
  component: EventComponent;
  selfId: number;
  updateCount = 0;
  questions = [];

  recording: boolean;

  getUserById(userId: number): UserHandler | undefined {
    return this.users.find(usr => parseInt('' + usr.id, 10) === parseInt('' + userId, 10));
  }

  getUserByStreamId(streamId: string): UserHandler | undefined {
    return this.users.find(usr => usr.streamId === streamId);
  }

  setup(conference: any, audioContext: AudioContext, component: EventComponent) {
    this.conference = conference;
    this.audioContext = audioContext;
    this.component = component;
    for (const user of this.users) {
      user.setup(conference, audioContext, component);
    }
  }

  resetUserStreams(): void {
    for (const user of this.users) {
      user.resetStream();
    }
  }

  update(newStatus: RoomStatus): void {
    const newRoom = newStatus.room;
    const full = newStatus.full;
    const selfId = newStatus.user;
    this.state = newRoom.state;
    for (const user of newRoom.users) {
      const oldUser = this.users.find(usr => usr.id === user.id);
      if (oldUser) {
        oldUser.update(user);
      } else {
        const newUser = new UserHandler();
        newUser.selfId = selfId;
        newUser.id = user.id;
        newUser.update(user);
        newUser.setup(this.conference, this.audioContext, this.component);
        this.users.push(newUser);
      }
    }
    if (full) {
      this.name = newRoom.name;
      // this.users = newRoom.users;
      // this.texts = newRoom.texts;
      this.languages = newRoom.languages;
      this.userTree = newRoom.userTree;

      // TODO csak az első teljesnél kell
      if (this.updateCount === 0) {
        this.texts = [];
      }
      this.users.forEach(user => {
        user.enabled = true;
      });

      this.updateCount++;
    }
    this.currentTime = newRoom.currentTime;
    this.offlineUsers = newRoom.offlineUsers;
    this.speakingUser = newRoom.speakingUser;
    if (this.speakingUser === null || this.speakingUser === undefined) {
      this.speakingUser = -1;
    }
    this.lastSpeakingUser = newRoom.lastSpeakingUser;
    this.waitingUsers = newRoom.waitingUsers;
    this.otherOnlineUsers = newRoom.otherOnlineUsers;
    this.state = newRoom.state;

    this.recording = newRoom.recording;
  }

}
