import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {OtrHttpRequestService} from './otr-http-request.service';

@Injectable({
  providedIn: 'root'
})
export class OtrBreadcrumbService {

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
  }

  private changeBreadCrumbService = new Subject<string>();
  newBreadCrumb$ = this.changeBreadCrumbService.asObservable();

  changeBreadCrumb(eventId) {
    const bread = eventId;
    this.changeBreadCrumbService.next(bread);
  }
}
