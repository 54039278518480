<div *ngIf="isNotLoggedIn" class="login-page animate d-flex align-items-center justify-content-center">
  <div class="container-fluid sidebar-mini">
    <div class="container-row">
      <div class="sidebar-wrapper">
        <div class="logo d-flex align-items-center justify-content-center">
          <a href="/otr-client/src">
            <img src="../../../assets/images/logo_feher_horizont.png">
          </a>

          <div class="menuListDiv flagMenuListDiv">
            <button type="button" class="buttonImgFlag" (click)="selectLang()">
              <img class="imgInButton" src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
            </button>
          </div>
        </div>
        <nav class="nav">
          <div id="navbarSupportedContent">
            <ul *ngIf="menus['elements']" class="navbar-nav">
              <li *ngFor="let menu of menus['elements']" class="nav-item" [routerLinkActive]="['active']">
                <a *ngIf="router.url.substring(0,4) !== menu.link.substring(0,4)" class="nav-link" [routerLink]="[menu.link]"><p>
                  {{ menu.name }}<span class="sr-only">(current)</span>
                </p>
                </a>
              </li>
            </ul>
            <ul class="navbar-nav list-inline">
              <li class="nav-item list-inline-item" [routerLinkActive]="['active']">
                <a class="nav-link"
                   [routerLink]="['/new-password']">{{langEventDataStr.elfelejtett_jelszo}}</a>
              </li>
            </ul>
          </div>
        </nav>

      </div>
      <div class="card">
        <div class="card-title ">{{langEventDataStr.bejelentkezes}}</div>
        <div class="card-subtitle ">&nbsp;</div>
        <div class="card-margin" >
          <form role="form" (ngSubmit)="loginUser()" #loginForm="ngForm" novalidate>
            <div class="form-content">
              <div class="form-group">
                <label for="email">{{langEventDataStr.e_mail}}</label>
                <input type="text" class="form-control input-underline input-lg"
                       [(ngModel)]="loginData.email" id="email" name="email"
                       autocomplete="username"
                       placement="top" ngbTooltip="{{ errMessageData.email }}">
              </div>
              <div class="form-group second-row">
                <label for="password">{{langEventDataStr.jelszo}}</label>
                <input type="password" class="form-control input-underline input-lg" [(ngModel)]="loginData.password"
                       id="password" name="password"
                       autocomplete="current-password"
                       placement="top" ngbTooltip="{{ errMessageData.password }}">
              </div>
            </div>
            <div class="form-button-container">
              <button type="submit" class="button">{{langEventDataStr.bejelentkezes}}</button>
            </div>
          </form>
        </div>

        <p class="parallax_one_copyright_content">
          <a class="linkme-mini" target="_blank" href="assets/document/aszf.pdf">{{navBarStr.altalanos_szerzodesi_feltetelek}}</a><br/>
          <a class="linkme-mini" target="_blank" href="assets/document/adatv_nyil.pdf">{{navBarStr.adatvedelem}}</a><br/>

          <a class="copyright-text"></a>
        </p>
      </div>
    </div>

  </div>
</div>
