<div *ngIf="(isNotLoggedIn) && (isConfirm)" class="registration-page animate d-flex align-items-center justify-content-center" style="height: 100%">
  <div class="container-fluid sidebar-mini">

    <div class="container-row">
      <div class="sidebar-wrapper">
        <div class="logo d-flex align-items-center justify-content-center">
          <a href="/otr-client/src">
            <img  src="../../../assets/images/logo_feher_horizont.png">
          </a>

          <div class="menuListDiv flagMenuListDiv">
            <button type="button" class="buttonImgFlag" (click)="selectLang()">
              <img class="imgInButton" src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
            </button>
          </div>
        </div>
        <nav class="nav">

          <div id="navbarSupportedContent">

            <ul *ngIf="menus['elements']" class="navbar-nav">
              <li *ngFor="let menu of menus['elements']" class="nav-item"

                  [routerLinkActive]="['active']">
                <a *ngIf="router.url !== menu.link" class="nav-link" [routerLink]="[menu.link]"><p>
                  {{ menu.name }}<span class="sr-only">(current)</span>
                </p>
                </a>
              </li>
            </ul>

            <ul class="navbar-nav list-inline">

              <li class="nav-item list-inline-item" [routerLinkActive]="['active']">
                <a class="nav-link"
                   [routerLink]="['/new-password']">{{langEventDataStr.elfelejtett_jelszo}}</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div class="card">
        <div class="card-title ">{{langEventDataStr.regisztracio_megerosites}}</div>
        <div class="large-margin d-flex align-items-center justify-content-center">
          <form (ngSubmit)="confirmRegistration()" #confRegForm="ngForm" novalidate>
            <div class="form-button-container">
              <button type="submit" class="button">{{langEventDataStr.megerosites}}</button>
            </div>
          </form>
        </div>

        <p class="parallax_one_copyright_content">
          <a class="linkme-mini" target="_blank" href="assets/document/aszf.pdf">{{navBarStr.altalanos_szerzodesi_feltetelek}}</a><br/>
          <a class="linkme-mini" target="_blank" href="assets/document/adatv_nyil.pdf">{{navBarStr.adatvedelem}}</a><br/>
          <a class="copyright-text"></a>
        </p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(isNotLoggedIn) && (!isConfirm)" class="registration-page animate d-flex align-items-center justify-content-center">
  <div class="container-fluid sidebar-mini">
    <div class="container-row">
      <div class="sidebar-wrapper">
        <div class="logo d-flex align-items-center justify-content-center">
          <a href="/otr-client/src">
            <img src="../../../assets/images/logo_feher_horizont.png">
          </a>

          <div class="menuListDiv flagMenuListDiv">
            <button type="button" class="buttonImgFlag" (click)="selectLang()">
              <img class="imgInButton" src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
            </button>
          </div>
        </div>
        <nav class="nav">

          <div>

            <ul *ngIf="menus['elements']" class="navbar-nav">
              <li *ngFor="let menu of menus['elements']" class="nav-item"

                  [routerLinkActive]="['active']">
                <a *ngIf="router.url !== menu.link" class="nav-link" [routerLink]="[menu.link]"><p>
                  {{ menu.name }}<span class="sr-only">(current)</span>
                </p>
                </a>
              </li>
            </ul>

          </div>
        </nav>
      </div>

      <div class="card">
        <div class="card-title ">{{langEventDataStr.regisztracio}}</div>
        <div class="card-subtitle ">&nbsp;</div>
        <div class="card-margin">
          <form role="form" (ngSubmit)="saveRegistration()" #registrationForm="ngForm" novalidate>
            <div class="form-content">
              <div class="row">
                <div class="form-group col-md-8">
                  <label>{{langEventDataStr.e_mail_cim}} *</label>
                  <input type="text" class="form-control input-underline input-lg" [(ngModel)]="registrationData.email"
                         name="email" id="email"
                         placement="top" ngbTooltip="{{ errMessageData.email }}"
                  >
                </div>
                <div class="form-group col-md-4">
                  <label>{{langEventDataStr.nyelv}} *</label>
                  <select *ngIf="langCode.length > 0"
                          placement="top" ngbTooltip="{{ errMessageData.tipus }}"
                          class="form-control input-underline input-lg custom_underline_select"
                          [(ngModel)]="registrationData.nyelv" name="nyelv">
                    <option *ngFor="let langCode_ of langCode" class="custom_underline_option" [value]="langCode_.kod">
                      {{ langCode_.nev }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label>{{langEventDataStr.vezeteknev}} *</label>
                  <input type="text" class="form-control input-underline input-lg"
                         [(ngModel)]="registrationData.vezeteknev"
                         name="vezeteknev"
                         placement="top" ngbTooltip="{{ errMessageData.vezeteknev }}"
                  >
                </div>
                <div class="form-group col-md-6">
                  <label>{{langEventDataStr.keresztnev}} *</label>
                  <input type="text" class="form-control input-underline input-lg"
                         [(ngModel)]="registrationData.keresztnev"
                         name="keresztnev"
                         placement="top" ngbTooltip="{{ errMessageData.keresztnev }}"
                  >
                </div>

              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label>{{langEventDataStr.jelszo}} *</label>
                  <input type="password" class="form-control input-underline input-lg"
                         [(ngModel)]="registrationData.password"
                         name="password"
                         placement="top" ngbTooltip="{{ errMessageData.password }}"
                  >
                </div>
                <div class="form-group col-md-6">
                  <label>{{langEventDataStr.jelszo_megegyszer}} *</label>
                  <input type="password" class="form-control"
                         [(ngModel)]="registrationData.passwordAgain" name="passwordAgain"
                         placement="top" ngbTooltip="{{ errMessageData.passwordAgain }}"
                  >
                </div>

              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <div><label>{{langEventDataStr.kijelentem_hogy_az}} <a target="_blank" class="linkBold"
                                                                         [routerLink]="['/event-files/get/aszf.pdf']">{{langEventDataStr.altalanos_szerzodesi_felteteleket}}</a> {{langEventDataStr.es_az}}
                    <a target="_blank" class="linkBold"
                       [routerLink]="['/event-files/get/adatv_nyil.pdf']">{{langEventDataStr.adatvedelmi_nyilatkozatot}}</a> {{langEventDataStr.megismertem_es_elfogadom_s_abban_szereplo_adatkezelesekhez_hozzajarulok}}
                  </label></div>
                  <div>
                    <input type="checkbox" ng-checked="all" [(ngModel)]="registrationData.termsofuse"
                           name="termsofuse" id="termsofuse"
                    >
                    <label for="termsofuse"
                           placement="top" ngbTooltip="{{ errMessageData.termsofuse }}">
                      {{langEventDataStr.a_felhasznalasi_felteteleket_elfogadom}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-button-container">
              <button type="submit" class="button">{{langEventDataStr.regisztracio}}</button>
            </div>
          </form>
        </div>
        <p class="parallax_one_copyright_content">
          <a class="linkme-mini" target="_blank" href="assets/document/aszf.pdf">{{navBarStr.altalanos_szerzodesi_feltetelek}}</a><br/>
          <a class="linkme-mini" target="_blank" href="assets/document/adatv_nyil.pdf">{{navBarStr.adatvedelem}}</a><br/>
          <a class="copyright-text"></a>
        </p>
      </div>
    </div>
  </div>
</div>
