import {ChangeDetectorRef, Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html'
})
export class LanguageDialogComponent {
  languages = [];
  constructor(
    public activeModal: NgbActiveModal,
    public changeRef: ChangeDetectorRef
  ) { }

}
