<div id="content-wrap" [ngClass]="{'bottompadded':showNavbar()}">

  <div *ngIf="showNavbar()" class="axcontainer" onclick="$('.sidebar')[0].classList.toggle('show');this.classList.toggle('active')">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 200 200">
      <g stroke-width="6.5" stroke-linecap="round">
        <path
          d="M72 82.286h28.75"
          fill="#009100"
          fill-rule="evenodd"
          stroke="#000"
        />
        <path
          d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
          fill="none"
          stroke="#000"
        />
        <path
          d="M72 125.143h28.75"
          fill="#009100"
          fill-rule="evenodd"
          stroke="#000"
        />
        <path
          d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
          fill="none"
          stroke="#000"
        />
        <path
          d="M100.75 82.286h28.75"
          fill="#009100"
          fill-rule="evenodd"
          stroke="#000"
        />
        <path
          d="M100.75 125.143h28.75"
          fill="#009100"
          fill-rule="evenodd"
          stroke="#000"
        />
      </g>
    </svg>
  </div>

  <div *ngIf="showNavbar()" class="sidebar" style="">
    <div class="sidebar-background">
    </div>
    <div class="sidebar-wrapper">
      <div class="logo d-flex align-items-center justify-content-center">
        <a href="/">
          <img style="width: 200px;" src="../../assets/images/logo_feher_horizont.png" alt="logo">
        </a>
        <div class="menuListDiv flagMenuListDiv" style="position: absolute;bottom:0px;width:100%;text-align: right;">
          <button type="button" class="buttonImgFlag" (click)="selectLang()">
            <img *ngIf="currentLang && currentLang != ''" class="imgInButton"
                 src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
          </button>
        </div>
      </div>

      <nav style="margin:0;" class="nav">
        <div id="navbarSupportedContent">
          <div class="menuListDiv profileMenuListDiv">
            <ul class="navbar-nav  list-inline">
              <li *ngIf="menus['isLoggedin']" class="nav-item list-inline-item">
                <a
                  class="nav-link"
                  href="#"
                  aria-haspopup="true"
                  aria-expanded="false"
                  [routerLink]="['/user-profile']"
                >
                  <img
                    src="/user-avatar"
                    class="otrUserAvatar"
                    style="width:40px;height:40px;margin-top: -6px;"
                    alt="avatar"
                  />
                  <p>{{ menus.userName }}</p>
                </a>
                <a class="nav-link-mini"
                   [routerLink]="['/modify-password']">{{ langEventDataStr.jelszo_modositas }}</a>
                <a class="nav-link-mini"
                   (click)="logout()">{{ langEventDataStr.kijelentkezes }}</a>
              </li>
            </ul>
          </div>

          <ul *ngIf="menus['elements']" class="navbar-nav">
            <li *ngFor="let menu of menus['elements']" class="nav-item" [routerLinkActive]="['active']">
              <a class="nav-link" [routerLink]="[menu.link]"><p>
                {{ menu.name }}<span class="sr-only">(current)</span>
              </p>
              </a>
            </li>
          </ul>
          <div>
            <div *ngIf="menus['isLoggedin'] && joinableEvent" class="joinableEventDiv">
              <ul class="navbar-nav list-inline">
                <li class="nav-item active list-inline-item" style="padding:23px 10px;"
                    [routerLinkActive]="['active']">
                  <a class="nav-link joinableButton"
                     (click)="goEventMonitor(joinableEvent.id, joinableEvent.tipus)">
                    {{langEventDataStr.ide_kattintva_csatlakozhat_a_most_futo_esemenyhez}}
                  </a>
                </li>
              </ul>
            </div>


          </div>
          <ul class="navbar-nav list-inline" *ngIf="menus['isLoggedin'] == false">
            <li class="nav-item list-inline-item" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="singin()">
                {{ langEventDataStr.bejelentkezes }}
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <p class="parallax_one_copyright_content" style="position: absolute; bottom: 0; width: 100%">

        <a class="linkme" target="_blank"
           href="../assets/document/aszf.pdf">{{langEventDataStr.altalanos_szerzodesi_feltetelek}}</a><br/>
        <a class="linkme" target="_blank"
           href="../assets/document/adatv_nyil.pdf">{{langEventDataStr.adatvedelem}}</a><br/>

        SIMY © 2022 - Combit Zrt.
      </p>
    </div>
  </div>

  <div class="{{showNavbar()?'main-panel':'main-panel-wide'}}" style="float: right; overflow-y: auto; height: 100vh">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>

</div>
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

