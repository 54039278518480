import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';


@Injectable({'providedIn': 'root'})
export class OtrAlertDialogService {
  private toasterService: ToastrService;

  constructor(toasterService: ToastrService) {
    this.toasterService = toasterService;
  }

  public open(intType, intTitle, inMessage) {
    // type 'success'
    if (intType === 'success') {
      this.success(intTitle, inMessage);
    }
    if (intType
      === 'error') {
      this.error(intTitle, inMessage);
    }
  }

  public success(intTitle, inMessage) {
    // type 'success'
    this.toasterService.success(intTitle, inMessage);
  }

  public error(intTitle, inMessage) {
    // type 'success'
    this.toasterService.error(intTitle, inMessage);
  }
}
