import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrUserService} from '../../otr-user.service';
import {OtrLangService} from '../../otr-lang.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-otr-passw',
  templateUrl: './otr-passw.component.html',
  styleUrls: ['./otr-passw.component.css']
})
export class OtrPasswComponent implements OnInit, OnDestroy {
  @ViewChild('profileForm') profileForm: HTMLFormElement;
  menus = [];
  passwordData: any = {};
  errMessageData: any = {};
  isLoggedIn = false;
  langEventDataStr: any = {};
  subscriptionLang: Subscription;

  constructor(private router: Router,
              private otrHttpRequestService: OtrHttpRequestService, private otrUserService: OtrUserService,
              private otrLangService: OtrLangService) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      langEventDataStr => {
        this.loadForgPasswStr();
      });
  }

  loadForgPasswStr(): void {
    this.otrLangService.getValuesForGroup('forgPasswStr', res => {
      this.langEventDataStr = res;
    });
  }

  ngOnInit() {
    this.otrUserService.getIsLoggedIn(data => {
      if (data) {
        this.isLoggedIn = true;

        this.loadForgPasswStr();
      }
    });
  }

  modPassword() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPutAndCheck('/modify-password', this.passwordData, res => {
        if (res['isError']) {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key) && this.profileForm.form.controls[key] !== undefined) {
              if (res[key] != null) {
                this.profileForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.profileForm.form.controls[key].setErrors(null);
              }
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }
}
