import {AfterContentInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtrEventParticipantService} from '../otr-event-participant.service';


import {OtrUserService} from '../otr-user.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-otr-conference-monitor',
  templateUrl: './otr-conference-monitor.component.html',
  styleUrls: ['./otr-conference-monitor.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class OtrConferenceMonitorComponent implements OnInit, AfterContentInit, OnDestroy {

  eventId;

  mode: any;
  eventMode: any;
  showMonitor = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private otrUserService: OtrUserService,
    private otrEventParticipantService: OtrEventParticipantService,
    private _location: Location
  ) {
    if (activatedRoute.snapshot.params.id) {
      this.eventId = activatedRoute.snapshot.params.id;
    } else {
      this.eventId = 0;
    }
  }


  ngOnInit() {
    this.otrUserService.getUserData(
      userData => {
        if (userData) {
          if (this.eventId !== 0) {
            this.otrEventParticipantService.getParticipant(this.eventId,
              data => {
                if (data.resultEvent) {
                  this.eventMode = data.resultEvent.tipus;
                  const usr = data.result[0];
                  if (usr) {
                    this.mode = usr.type;
                  }
                }
              }
            );
          }

        }
      }
    );
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
  }

  hideMonitor() {
    this.showMonitor = false;
    this._location.back();
  }

}
