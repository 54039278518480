import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {OtrLangService} from '../otr-lang.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-otr-admin-user',
  templateUrl: './otr-admin-user.component.html'
})

export class OtrAdminUserComponent implements AfterViewInit, OnInit, OnDestroy {
  errMessageData: any = {};
  isLoggedIn = false;
  langEventDataStr: any = {};
  loadedUsers: any = {};
  subscriptionLang: Subscription;

  public tableWidget: any;

  constructor(
    private otrHttpRequestService: OtrHttpRequestService,
    private otrLangService: OtrLangService
  ) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      () => {
        this.initDatatable();
        this.loadEventDataStr();
      }
    );
  }

  loadEventDataStr(): void {
    this.otrLangService.getValuesForGroup('adminStr', res => {
      this.langEventDataStr = res;
    });
  }

  ngOnInit() {
    this.loadEventDataStr();
  }

  ngAfterViewInit(): void {
    this.reInitDatatable();
    this.loadUsers();
  }

  loadUsers() {
    this.otrHttpRequestService.doGetAndCheck('/admin-user/load', data => {
      if (!data['isError']) {
        this.isLoggedIn = true;
        this.loadedUsers = data['result'];
      } else {
        if (!data['isNotLoggedin']) {
          this.isLoggedIn = true;
        }
        this.loadedUsers = {};
      }

      this.reInitDatatable();
    });
  }

  deleteUser(userId) {
    this.otrHttpRequestService.doDeleteAndCheck(
      '/admin-user/' + userId,
      () => {
        this.loadUsers();
      }
    );
  }

  lockUser(userId) {
    this.otrHttpRequestService.doPutAndCheck(
      '/admin-user/lock/' + userId,
      {},
      () => {
        this.loadUsers();
      }
    );
  }

  unlockUser(userId) {
    this.otrHttpRequestService.doPutAndCheck(
      '/admin-user/unlock/' + userId,
      {},
      () => {
        this.loadUsers();
      }
    );
  }

  reSendRegConfirmEmail(userId) {
    this.otrHttpRequestService.doPutAndCheck(
      '/admin-user/sendConfEmail/' + userId,
      {},
      () => {
        this.loadUsers();
      }
    );
  }

  changeUserType(userId, userType) {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck(
      '/admin-user/change',
      {userId: userId, userType: userType},
      res => {
        if (!res['isError']) {
          this.loadUsers();
        } else {
          this.errMessageData = res;
        }
      }
    );
  }

  private initDatatable(): void {
    const exampleId: any = $('#userstable');
    this.tableWidget = exampleId.DataTable({
      select: true, destroy: true,
      stateSave: true,
      language: {
        url: this.otrLangService.getCurrentDataTableLangUrl()
      },
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(
          'DataTables_' + settings.sInstance,
          JSON.stringify(data)
        );
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(
          localStorage.getItem('DataTables_' + settings.sInstance)
        );
      }
    });
  }

  private reInitDatatable(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    setTimeout(() => this.initDatatable(), 0);
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }
}
