import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html'
})
export class ConfirmationDialogComponent {
    @Input() public title;
    @Input() public message;

    public constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
    }
}
