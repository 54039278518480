import {UserHandler} from './UserHandler';
import {SimyRoom} from './SimyRoom';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {EventComponent} from './event-component';
import {EventStreamHandler} from '../event-stream-handler';

export class RoomStatus {
  isError = false;
  room: SimyRoom = new SimyRoom();
  user: number;
  self: UserHandler;
  interpreter: UserHandler;
  show: number;
  video: number[];


  allUserList: Array<UserHandler> = [];
  userList: Array<UserHandler> = [];
  missingUserList: Array<UserHandler> = [];
  interpreterIsOffline = true;
  speakingUserHandler: UserHandler = null;

  newMessageCount = 0;
  newFileCount = 0;


  full: boolean;
  private conference: any;
  private audioContext: AudioContext;
  private component: EventComponent;
  private speakerChanged: boolean;
  localStream: EventStreamHandler;
  private eventStateModified = false;
  displayedStreamId: string;


  private _socket = null;

  private _reconnectTimes = 0;
  private reconnectionAttempts = 10;
  private spec = { dispatcher: {eventListeners: {}}};
  displayedNickname = '';

  constructor(
    private otrHttpRequestService: OtrHttpRequestService,
    private eventComponent: EventComponent) {
    this.room.roomId = eventComponent.eventId;
  }

  setup(conference: any, audioContext: AudioContext, component: EventComponent) {
    this.component = component;
    this.conference = conference;
    this.audioContext = audioContext;
    this.room.setup(conference, audioContext, component);
    this.connect().then(_r => {});
  }

  connect() {
    return new Promise((resolve, reject) => {
      const opts = {
        'reconnection': true,
        'reconnectionAttempts': this.reconnectionAttempts,
        'force new connection': true,
        'upgrade': true
      };
      this._socket = io.connect(opts);
      this._socket.on('reconnecting', () => {
        this._reconnectTimes++;
        console.log('reconnecting');
      });
      this._socket.on('roomStatus', (data) => {
        this.update(data);
      });
      this._socket.on('reconnect_failed', () => {
        if (this._reconnectTimes >= this.reconnectionAttempts) {
          // TODO disconnected
        }
      });
      this._socket.on('connect_error', (_e) => {
        reject(`connect_error`);
      });
      this._socket.on('drop', () => {
        this._reconnectTimes = this.reconnectionAttempts;
      });
      this._socket.on('connect', () => {
        this._reconnectTimes = 0;
        console.log('connect');
        this._socket.emit('join', this.eventComponent.eventId);
      });
      this._socket.emit('join', this.eventComponent.eventId);
    });
  }

  addEventListener(eventType, listener) {
    if (this.spec.dispatcher.eventListeners[eventType] === undefined) {
      this.spec.dispatcher.eventListeners[eventType] = [];
    }
    this.spec.dispatcher.eventListeners[eventType].push(listener);
  }

  setLocalStream(stream): void {
    if (this.localStream) {
      this.localStream.close();
    }
    this.localStream = stream;
  }

  setState(newRoomStatus: RoomStatus): void {
    if (
      (this.room.state !== newRoomStatus.room.state) ||
      (this.room.state !== 0 && this.room.speakingUser !== newRoomStatus.room.speakingUser)
    ) {
      this.speakerChanged = true;
    }
    this.eventStateModified = this.room.state !== newRoomStatus.room.state;

    this.video = newRoomStatus.video;
    this.show = newRoomStatus.show;
  }

  setBasicUsers(newRoomStatus: RoomStatus): void {
    if (newRoomStatus.user && newRoomStatus.user !== undefined) {
      const selfUser = this.room.getUserById(newRoomStatus.user);
      if (selfUser) {
        this.self = selfUser;
      }
    }
    this.interpreter = this.room.users.find(user => user.type === 'T');

  }

  update(newRoomStatus: RoomStatus): void {
    if (!newRoomStatus.isError) {
      if (newRoomStatus.full) {
        this.newFileCount = 0;
      }
      this.setState(newRoomStatus);
      this.room.update(newRoomStatus);
      this.setBasicUsers(newRoomStatus);
      this.handleUserList();
      this.handleFiles(newRoomStatus);
      this.handleTexts(newRoomStatus);

      if (this.self) {
        if (this.self.lang === undefined || this.self.lang === 'null' || this.self.lang === 'undefined') {
          this.self.lang = this.room.languages[0];
          if (this.component) {
            this.component.setLang(this.self.lang);
          }
        } else {
          const roomUser = newRoomStatus.room.users.find(usr => usr.id === this.self.id);
          if (roomUser && this.self.lang !== roomUser.lang) {
            if (this.component) {
              if (roomUser.lang === 'null' || roomUser.lang === null) {
                this.component.setLang(this.room.languages[0]);
              } else {
                this.component.setLang(roomUser.lang);
              }
            }
          }
        }

      }
    }
    if (this.component) {
      this.component.onRoomStatusUpdate();
      if (this.eventStateModified) {
        this.component.eventStateModified();
      }
      if (this.speakerChanged) {
        this.component.speakerChanged();
        this.speakerChanged = false;
      }
      if (this.room.state !== 0) {
        // event started
        this.component.eventStateHandler();
      } else {
        // gyülekező van
        this.component.gatheringStateHandler();
      }
      this.component.setGains();
    }
    // TODO handle interpreter
    if (this.localStream) {
      if (this.video.includes(this.self.id)) {
        this.localStream.video(this.self.videoState === 1);
        if (this.room.state === 1 && this.eventComponent.eventDisplayState.muteOn) {
          this.localStream.audio(false);
        } else {
          if (this.self.id === this.interpreter.id) {
            this.localStream.audio(this.self.audioState === 1 && !this.eventComponent.eventDisplayState.muteOn);
          } else {
            this.localStream.audio(this.self.audioState === 1);
          }
        }
      } else {
        this.localStream.video(false);
        this.localStream.audio(false);
      }
      // TODO hogyan is this.localStream.audio(this.self.audioState === 1);
    }
  }


  private handleTexts(newRoomStatus: RoomStatus) {
    // handle new chat messages
    const seen = new Set();
    newRoomStatus.room.texts.forEach(text => {
      const k = text.userId + '_' + text.time;
      if (!seen.has(k)) {
        seen.add(k);
        const user = this.room.users.find(roomUser => roomUser.id === text.userId);
        if (user) {
          text.userName = user.nickname;
          // TODO current locale
          text.user = user;
          text.dateTime = new Date(text.time * 1000).toLocaleString('hu-HU');
          const textElement = this.room.texts.find(value => value.userId + '_' + value.time === k);
          if (textElement === null || textElement === undefined) {
            this.room.texts.unshift(text);
            this.newMessageCount++;
          }
        }
      }
    });
    if (newRoomStatus.room.questions) {
      newRoomStatus.room.questions.forEach(text => {
        text.userId = 0;
        text.isQuestion = true;
        text.dateTime = new Date(text.time * 1000).toLocaleString('hu-HU');
        text.userName = 'nick: ' + text.nick;
        const textElement = this.room.texts.find(value => 'nick: ' + text.nick + '_' + text.time === value.userName + '_' + value.time);
        if (textElement === null || textElement === undefined) {
          this.room.texts.unshift(text);
          this.newMessageCount++;
        }
      });

    }
  }

  private handleFiles(newRoomStatus: RoomStatus) {
    if (newRoomStatus.room.files) {
      let fileCount = this.room.files.length;
      this.room.files = newRoomStatus.room.files;
      for (const simyFile of this.room.files) {
        if (simyFile.userName === undefined) {
          const user: UserHandler = this.room.users.find((roomUser: UserHandler) => roomUser.id === parseInt(String(simyFile.user), 10));
          if (user !== undefined) {
            simyFile.userName = user.nickname;
          } else {
            simyFile.userName = 'N/A';
          }
        }
      }
      fileCount = this.room.files.length - fileCount;
      if (fileCount < 0) {
        fileCount = 0;
      }
      if (fileCount > 0) {
        this.newFileCount = fileCount;
      }
    } else {
      // this.room.files = this.room.files;
      // this.newFileCount = 0;
      // update names
      for (const simyFile of this.room.files) {
        const user: UserHandler = this.room.users.find((roomUser: UserHandler) => roomUser.id === parseInt(String(simyFile.user), 10));
        if (user !== undefined) {
          simyFile.userName = user.nickname;
        } else {
          simyFile.userName = 'N/A';
        }
      }
    }
  }

  private handleUserList(): boolean {
    let needReload = false;
    this.userList = new Array<UserHandler>();
    this.missingUserList = new Array<UserHandler>();
    this.allUserList = new Array<UserHandler>();
    let interpreterIsOfflineLocal = false;
    this.speakingUserHandler = null;
    // speakingUser
    this.room.speakingUser = parseInt('' + this.room.speakingUser, 10);
    if (this.room.speakingUser !== -1) {
      if (this.room.speakingUser !== this.self.id) {
        const user = this.room.getUserById(this.room.speakingUser);
        if (user) {
          if (this.room.state !== 0) {
            user.state = 'S';
          } else {
            user.state = 'O';
          }
          this.userList.push(user);
          this.speakingUserHandler = user;
        } else {
          needReload = true;
        }
      } else
      if (this.component && this.component.showAllSpeaker) {
        const user = this.room.getUserById(this.room.speakingUser);
        if (user) {
          user.state = 'S';
          this.userList.push(user);
          this.speakingUserHandler = user;
        } else {
          needReload = true;
        }
      }
    }

    // waitingUsers
    for (const userId of this.room.waitingUsers) {
      if (this.self && userId !== this.self.id) {
        const user = this.room.getUserById(userId);
        if (user) {
          if (this.room.state !== 0) {
            user.state = 'W';
          } else {
            user.state = 'O';
          }
          this.userList.push(user);
          this.allUserList.push(user);
        } else {
          needReload = true;
        }
      } else {  // self - conference
        const user = this.room.getUserById(userId);
        if (user) {
          user.state = 'W';
          this.allUserList.push(user);
        }
      }
    }

    // otherOnlineUsers
    for (const userId of this.room.otherOnlineUsers) {
      if (this.self && userId !== this.self.id) {
        const user = this.room.getUserById(userId);
        if (user) {
          if (user.type !== 'T') {
            user.state = 'O';
            this.userList.push(user);
            this.allUserList.push(user);
          }
        } else {
          needReload = true;
        }
      } else { // self - conference
        const user = this.room.getUserById(userId);
        if (user) {
          if (user.type !== 'T') {
            user.state = 'O';
            this.allUserList.push(user);
          }
        } else {
          needReload = true;
        }
      }
    }
    for (const userId of this.room.offlineUsers) {
      // Az interpretert nem kell bele rakni, külön utas kezelés
      if (this.interpreter === undefined || userId !== this.interpreter.id) {
        const user = this.room.getUserById(userId);
        if (user) {
          user.state = 'M';
          this.missingUserList.push(user);
          this.allUserList.push(user);
        } else {
          needReload = true;
        }
      } else {
        interpreterIsOfflineLocal = true;
      }
    }
    this.interpreterIsOffline = interpreterIsOfflineLocal;
    return needReload;
  }

  toggleUserEnabled(userId) {
    const user = this.room.getUserById(userId);
    if (user) {
      user.enabled = !user.enabled;
    }
  }


  togglePin(userId: any) {
    const user = this.room.getUserById(userId);
    if (user) {
      user.pinned = !user.pinned;
    }
  }

  displayUserStreamById(displayUser: number, videoTagId: string): boolean {
    if (displayUser === undefined) {
      return false;
    }
    const speakingUser: UserHandler = this.room.users.find(
      (user) => parseInt('' + user.id, 10) === parseInt('' + displayUser, 10)
    );
    if (speakingUser) {
      // TODO törölni kell ha újra csatlakozunk
      if (this.displayedStreamId !== speakingUser.streamId) {

        console.debug('RoomStatus', 'displayUserStreamById', displayUser);
        this.displayedStreamId = undefined;
        if (speakingUser.displayStream(videoTagId)) {
          this.displayedNickname = speakingUser.nickname;
          this.displayedStreamId = speakingUser.streamId;
          return true;
        }
      } else {
        this.displayedNickname = speakingUser.nickname;
      }
    } else {
      this.displayedNickname = '';
    }

    return false;
  }

  enableStreamByLang(): void {
    this.setAllStream(
      (user) => (user.id === this.room.speakingUser || user.id === this.interpreter.id) && user.lang === this.self.lang
      , undefined
    );
  }

  enableAllStream() {
    this.setAllStream(() => true, () => true);
  }

  setAllStream(
    audioCallback: (user: UserHandler) => boolean,
    videoCallback: (user: UserHandler) => boolean
  ): void {
    this.room.users.forEach((user) => {
      if (user.subscription) {
        if (audioCallback !== undefined) {
          user.subscription.audio(audioCallback(user));
        }
        if (videoCallback !== undefined) {
          user.subscription.video(videoCallback(user));
        }
      }
    });
  }

  sendText(param) {
    this._socket.emit('text', param);
  }

  sendNick(nickname) {
    this._socket.emit('nick', nickname);
  }

  setLang(lang: string) {
    this._socket.emit('lang', lang);
  }

  setAV(audio: number, video: number) {
    this._socket.emit('av', {audio: audio, video: video});
  }

  setSID(sid: string) {
    this._socket.emit('sid', sid);
  }

  accept() {
    this._socket.emit('accept');
  }

  apply() {
    this._socket.emit('apply');
  }

  unapply() {
    this._socket.emit('unapply');
  }

  unapplyAndGrabTheMic() {
    this._socket.emit('unapply', (status, _data) => {
      if (status === 'ok') {
        this.grabTheMic();
      }});
  }


  stopSpeaking() {
    this._socket.emit('stopSpeaking');
  }

  start() {
    this._socket.emit('start');
  }

  stop() {
    this._socket.emit('stop');
  }

  filesChanged() {
    this._socket.emit('filesChanged');
  }

  startRecord() {
    this._socket.emit('startRecord');
  }

  stopRecord() {
    this._socket.emit('stopRecord');
  }

  grabTheMic() {
    this._socket.emit('grabTheMic');
  }

  ping(): string {
    this._socket.emit('alive');
    return 'ok';
  }
}
