import {Component, TemplateRef} from '@angular/core';

import {OtrAlertDialogService} from '../otr-alert-dialog.service';



@Component({
  selector: 'app-toasts',
  template: ``
})
export class ToastsContainerComponent {
  constructor(public toastService: OtrAlertDialogService) {}
  isTemplate(toast) { return toast.textOrTpl instanceof TemplateRef; }
}
