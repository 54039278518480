import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {QRCodeModule} from 'angularx-qrcode';

import {AppComponent} from './app.component';
import {OtrLoginComponent} from './user-pages/otr-login/otr-login.component';
import {OtrRegistrationComponent} from './user-pages/otr-registration/otr-registration.component';
import {OtrInterProfileComponent} from './interpreter-pages/otr-inter-profile/otr-inter-profile.component';
import {NgbDateCustomParserFormatter} from './ngb-date-iso-parser-formatter';
import {OtrEventQueryComponent} from './otr-event-query/otr-event-query.component';
import {OtrPasswComponent} from './user-pages/otr-passw/otr-passw.component';
import {OtrEventMonitorComponent} from './otr-event-monitor/otr-event-monitor.component';

import {OtrHomeComponent} from './otr-home/otr-home.component';
import {OtrEventMonitorInterpreterComponent} from './interpreter-pages/otr-event-monitor-interpreter/otr-event-monitor-interpreter.component';
import {OtrEventMonitorPartnerComponent} from './otr-event-monitor-partner/otr-event-monitor-partner.component';
import {OtrCodeLoadService} from './otr-code-load.service';
import {OtrUserProfileComponent} from './user-pages/otr-user-profile/otr-user-profile.component';
import {OtrAlertDialogService} from './otr-alert-dialog.service';
import {OtrAlertDialogComponent} from './otr-alert-dialog/otr-alert-dialog.component';
import {OtrConfirmationDialogService} from './otr-confirmation-dialog.service';
import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {OtrExtHomeComponent} from './otr-ext-home/otr-ext-home.component';
import {OtrHttpRequestService} from './otr-http-request.service';
import {OtrUserService} from './otr-user.service';
import {OtrEventParticipantService} from './otr-event-participant.service';
import {OtrForgottenPasswordComponent} from './user-pages/otr-forgotten-password/otr-forgotten-password.component';
import {ImageModifyService} from './image-modify.service';

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

// import {OtrFileUploadComponent} from './otr-file-upload/otr-file-upload.component';
import {FileUploadModule} from 'ng2-file-upload';
// import {OtrFileListComponent} from './otr-file-list/otr-file-list.component';
import {OtrFileService} from './otr-file.service';
import {OtrAdminUserComponent} from './otr-admin-user/otr-admin-user.component';
import {OtrLangService} from './otr-lang.service';
import {OtrInvoiceClientComponent} from './otr-invoice-client/otr-invoice-client.component';
import {OtrInvoiceInterpreterComponent} from './interpreter-pages/otr-invoice-interpreter/otr-invoice-interpreter.component';
import {OtrBreadcrumbService} from './otr-breadcrumb.service';

import {OtrMainJoinEventService} from './otr-main-join-event.service';
import {TransitionGroupComponent} from './transition-group';
import {TransitionGroupItemDirective} from './transition-group-item.directive';

import {NgxSpinnerModule} from 'ngx-spinner';
import {OtrEventMonitorConferenceComponent} from './otr-event-monitor-conference/otr-event-monitor-conference.component';

import {OtrConferenceMonitorInterpreterComponent} from './interpreter-pages/otr-conference-monitor-interpreter/otr-conference-monitor-interpreter.component';
import {OtrConferenceMonitorPartnerComponent} from './otr-conference-monitor-partner/otr-conference-monitor-partner.component';
import {OtrConferenceMonitorComponent} from './otr-conference-monitor/otr-conference-monitor.component';

import {DataTablesModule} from 'angular-datatables';
import {ToastsContainerComponent} from './otr-alert-dialog/toast-container.component';
import { LanguageDialogComponent } from './language-dialog/language-dialog/language-dialog.component';

import { SimyDatePipe } from './simy-date.pipe';
import {OtrLogService} from './otr-log.service';
import { IonicModule } from '@ionic/angular';
// 'hu', 'en', 'de', 'it', 'pl', 'ru'

/*
import { enableProdMode } from '@angular/core';
enableProdMode();
*/

const appRoutes: Routes = [
  {
    path: 'invoice-interpreter',
    component: OtrInvoiceInterpreterComponent,
    data: {title: 'OTR invoice interpreter'}
  },
  {
    path: 'invoice-client',
    component: OtrInvoiceClientComponent,
    data: {title: 'OTR invoice client'}
  },
  {
    path: 'admin-user',
    component: OtrAdminUserComponent,
    data: {title: 'OTR admin user'}
  },
  {
    path: 'new-password/:token',
    component: OtrForgottenPasswordComponent,
    data: {title: 'OTR new password'}
  },
  {
    path: 'new-password',
    component: OtrForgottenPasswordComponent,
    data: {title: 'OTR new password'}
  },
  {
    path: 'homepage',
    component: OtrExtHomeComponent,
    data: {title: 'OTR external home'}
  },
  {
    path: 'user-profile',
    component: OtrUserProfileComponent,
    data: {title: 'OTR user profile'}
  },
  {
    path: 'user-profile/admin/:id',
    component: OtrUserProfileComponent,
    data: {title: 'OTR user profile'}
  },
  {
    path: 'home',
    component: OtrHomeComponent,
    data: {title: 'OTR Home'}
  },
  {
    path: 'event-query',
    component: OtrEventQueryComponent,
    data: {title: 'OTR Evenet Query'}
  },
  {
    path: 'admin-event-query',
    component: OtrEventQueryComponent,
    data: {title: 'OTR Evenet Query'}
  },
  {
    path: 'interpreter-profile',
    component: OtrInterProfileComponent,
    data: {title: 'OTR Interpreter Profile'}
  },
  {
    path: 'interpreter-profile/admin/:id',
    component: OtrInterProfileComponent,
    data: {title: 'OTR Interpreter Profile'}
  },
  {
    path: 'modify-password',
    component: OtrPasswComponent,
    data: {title: 'OTR Modify Password'}
  },
  {
    path: 'auth',
    component: OtrLoginComponent,
    data: {title: 'OTR Login'}
  },
  {
    path: 'auth/c/:continueUrl/:id',
    component: OtrLoginComponent,
    data: {title: 'OTR Login'}
  },
  {
    path: 'auth/c/:continueUrl/:id/:isAccept',
    component: OtrLoginComponent,
    data: {title: 'OTR Login'}
  },
  {
    path: 'registration',
    component: OtrRegistrationComponent,
    data: {title: 'OTR Registration'}
  },
  {
    path: 'registration/:token',
    component: OtrRegistrationComponent,
    data: {title: 'OTR Registration'}
  },
  {
    path: 'registration/confirm/:confToken',
    component: OtrRegistrationComponent,
    data: {title: 'OTR Registration'}
  },
  {
    path: 'event-monitor',
    component: OtrEventMonitorComponent,
    data: {title: 'OTR event monitor'}
  },
  {
    path: 'event-monitor/:id',
    component: OtrEventMonitorComponent,
    data: {title: 'OTR event monitor'}
  },
  {
    path: 'event-monitor/:id/:login',
    component: OtrEventMonitorComponent,
    data: {title: 'OTR event monitor'}
  },
  {
    path: 'conference-monitor',
    component: OtrConferenceMonitorComponent,
    data: {title: 'OTR event monitor'}
  },
  {
    path: 'conference-monitor/:id',
    component: OtrConferenceMonitorComponent,
    data: {title: 'OTR event monitor'}
  },
  {path: '', redirectTo: '/homepage', pathMatch: 'full'},
  { path: 'event', loadChildren: () => import('./create-pages/create-pages.module').then(m => m.CreatePagesModule) },
  { path: 'code', loadChildren: () => import('./otr-code-store/otr-code-store.module').then(m => m.OtrCodeStoreModule) }
];

BrowserModule.withServerTransition({appId: 'OTR'});

@NgModule({
    declarations: [
        AppComponent,
        OtrLoginComponent,
        OtrRegistrationComponent,
        OtrInterProfileComponent,
        OtrEventQueryComponent,
        OtrPasswComponent,
        OtrEventMonitorComponent,
        OtrHomeComponent,
        OtrEventMonitorInterpreterComponent,
        OtrEventMonitorPartnerComponent,
        OtrUserProfileComponent,
        OtrAlertDialogComponent,
        ConfirmationDialogComponent,
        OtrExtHomeComponent,
        OtrForgottenPasswordComponent,
        // OtrFileUploadComponent,
        // OtrFileListComponent,
        OtrAdminUserComponent,
        OtrInvoiceClientComponent,
        OtrInvoiceInterpreterComponent,
        TransitionGroupItemDirective,
        TransitionGroupComponent,
        OtrEventMonitorConferenceComponent,
        OtrConferenceMonitorInterpreterComponent,
        OtrConferenceMonitorPartnerComponent,
        OtrConferenceMonitorComponent,
        ToastsContainerComponent,
        LanguageDialogComponent,
        SimyDatePipe
    ],
    imports: [
        NgxSpinnerModule,
        QRCodeModule,
        FileUploadModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        DataTablesModule,
        NgbModule,
        ToastrModule.forRoot(),
        RouterModule.forRoot(appRoutes, { enableTracing: false } // <-- debugging purposes only
        ),
        IonicModule.forRoot()
    ],
    providers: [
      AndroidPermissions,
        HTTP,
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        OtrCodeLoadService,
        OtrAlertDialogService,
        OtrHttpRequestService,
        OtrUserService,
        OtrEventParticipantService,
        OtrFileService,
        OtrLangService,
        OtrConfirmationDialogService,
        OtrBreadcrumbService,
        OtrMainJoinEventService,
        OtrLogService,
        ImageModifyService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
