import {EventStreamHandler} from '../event-stream-handler';
import {RoomStatus} from './RoomStatus';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {EventComponent} from './event-component';
import {ImageModifyService} from '../image-modify.service';
import * as Owt from '../Owt/Owt';
import {ConferenceClient} from '../Owt/conference/client';


export class ServerConnection {

  inPublish = false;
  conference: ConferenceClient;
  joined = false;
  inJoin = false;

  private audioContext: AudioContext;
  private analyser: AnalyserNode;


  rtcConfiguration = {
    iceServers: [],
    iceTransportPolicy: 'relay'
  };
  private interval: any;
  private onLogout = false;
  private inDestroy = false;

  constructor(
    private otrHttpRequestService: OtrHttpRequestService,
    private eventComponent: EventComponent,
    private eventId: number,
    private roomStatus: RoomStatus,
    private imageModify: ImageModifyService
  ) {
    // TODO cancellable
    this.interval = setInterval(() => {
      this.checkStreamStat();
    }, 1000);
  }


  createToken(userName, role, callback) {
    // const req = new XMLHttpRequest();
    const url = '/createTokenByName/';
    const body = {
      room: 'conference' + this.eventId,
      username: userName,
      role: role
    };
    const me = this;
    this.otrHttpRequestService.doPostTextResponse(url, body).subscribe(
      (res: any) => {
        callback.call(me, res);
      },
      error => {
        setTimeout( () => {me.createToken(userName, role, callback); }, 500);
      });
  }

  joinEvent(userName, desc) {
    if (this.inJoin) {
      return;
    }
    this.inJoin = true;
    const me = this;
    const role = 'presenter'; // 'presenter';//admin
    this.createToken(userName,
      role,
      (response) => {
        const token = response;
        this.conference = new Owt.Conference.ConferenceClient({
          rtcConfiguration: this.rtcConfiguration
        });
        me.conference.published = false;
        this.conference.join(token).then(_resp => {
          this.joined = true;
          this.audioContext = new AudioContext; // or webkitAudioContext
          me.roomStatus.setup(me.conference, this.audioContext, this.eventComponent);
          this.publishStream(me, desc, () => {
            me.conference.published = true;
            me.inJoin = false;
          });
          this.conference.addEventListener('serverdisconnected', () => {
            if (!this.inDestroy) {
              this.eventComponent.offline = true;
            }
          });
        }, function (_err) {
          me.inJoin = false;
          console.log(_err);
          // me.join(room, userName, resolution);
        });
      });

  }

  async checkCamera(me: this) {
    await navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        me.eventComponent.getEventState().hasCamera = false;
        me.eventComponent.getEventState().hasPhyCam = false;
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            me.eventComponent.getEventState().hasCamera = true;
            me.eventComponent.getEventState().hasPhyCam = true;
          }
        });
      })
      .catch(function (err) {
        console.log(err.name + ': ' + err.message);
      });
  }

  publishStream(me: this, desc, callback) {
    let streamConstraints;
    console.log('publish stream');
    me.checkCamera(me).then();
    let haveVideoInput = false;
    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            haveVideoInput = true;
          }
        });
        if (haveVideoInput) {
          me.eventComponent.getEventState().hasCamera = true;
          me.eventComponent.getEventState().hasPhyCam = true;
          const videoConstraints = new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.CAMERA);
          videoConstraints.resolution = {width: me.eventComponent.outputWidth, height: me.eventComponent.outputHeight};
          videoConstraints.frameRate = 30;
          // Publish stream
          streamConstraints = new Owt.Base.StreamConstraints(
            new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.MIC),
            videoConstraints
          );
        } else {
          me.eventComponent.getEventState().hasCamera = false;
          me.eventComponent.getEventState().hasPhyCam = false;

          streamConstraints = new Owt.Base.StreamConstraints(new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.MIC));
        }

        let mediaStream;
        const mediaConstraints = {
          audio: true,
          video: {
            width: {ideal: me.eventComponent.outputWidth},
            height: {ideal: me.eventComponent.outputHeight},
          }
        };
        navigator.mediaDevices.getUserMedia(mediaConstraints).then(stream => {
          // Owt.Base.MediaStreamFactory.createMediaStream(streamConstraints).then(stream => {
          mediaStream = this.streamCreated(mediaStream, stream, me, desc, callback);
          // else: there is no outputCanvas => we are in the destroy cycle
          // });
        }, err => {
          if (me.eventComponent.getEventState().hasPhyCam && err.name === 'NotAllowedError') {
            alert('Please enable camera access');
          }
          me.eventComponent.getEventState().hasCamera = false;
          streamConstraints = new Owt.Base.StreamConstraints(new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.MIC));
          navigator.mediaDevices.getUserMedia(mediaConstraints).then(stream => {
            // Owt.Base.MediaStreamFactory.createMediaStream(streamConstraints).then(stream => {
            mediaStream = this.streamCreated(mediaStream, stream, me, desc, callback);
          }, err_media => {
            console.error('Failed to create MediaStream, ' + err_media);
            alert('Failed to create MediaStream, ' + err_media.message);

          });
        });

      })
      .catch(function (err) {
        console.log(err.name + ': ' + err.message);
      });
  }

  private streamCreated<T>(mediaStream, stream: MediaStream, me: this, desc, callback) {
    mediaStream = stream;
    const localMediaStream = me.eventComponent.getEventState().hasCamera ?
      new Owt.Base.LocalStream(mediaStream, new Owt.Base.StreamSourceInfo('mic', 'camera'))
      : new Owt.Base.LocalStream(mediaStream, new Owt.Base.StreamSourceInfo('mic', undefined));
    const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('localVideo'));

    const videoTracks = stream.getVideoTracks();
    const videoOnly = new MediaStream([videoTracks[0]]);
    const videoWidth = videoOnly.getVideoTracks()[0].getSettings().width;
    const videoHeight = videoOnly.getVideoTracks()[0].getSettings().height;
    const outputCanvas = document.getElementById('canvas-output');
    if (outputCanvas) {
      outputCanvas.setAttribute('width', '' + videoWidth);
      outputCanvas.setAttribute('height', '' + videoHeight);
      try {
        localVideo.srcObject = videoOnly;
      } catch (error) {
        //  localVideo.src = URL.createObjectURL(videoOnly);
      }
      localVideo.volume = 0.0;

      localVideo.play().then();


      this.imageModify.perform(desc).then();
      localMediaStream.mediaStream = this.imageModify.getResultStream(desc);
      localMediaStream.mediaStream.addTrack(mediaStream.getAudioTracks()[0]);
      mediaStream.removeTrack(mediaStream.getAudioTracks()[0]);


      const localStream = new EventStreamHandler(localMediaStream, undefined, undefined, localMediaStream.id, undefined);

      me.roomStatus.setLocalStream(localStream);
      me.publish(localStream, callback);

      try {
        this.analyser = this.audioContext.createAnalyser();
        const microphone = this.audioContext.createMediaStreamSource(stream);

        this.analyser.smoothingTimeConstant = 0.3;
        this.analyser.fftSize = 1024;
        const volume = this.audioContext.createGain();
        microphone.connect(this.analyser);
        this.analyser.connect(volume);


        volume.connect(this.audioContext.destination);

        volume.gain.setValueAtTime(0.0, volume.context.currentTime);

        this.draw.bind(this)();
      } catch (e) {
      }
    }
    return mediaStream;
  }

  publishScreen(me: this, callback) {

    me.checkCamera(me).then();
    let streamConstraints;
    streamConstraints = new Owt.Base.StreamConstraints(
      undefined,
      new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.SCREENCAST)
    );
    let mediaStream;

    // const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    Owt.Base.MediaStreamFactory.createMediaStream(streamConstraints).then(async stream => {
      mediaStream = stream;
      const audioStream = await navigator.mediaDevices.getUserMedia({audio: true});
      audioStream.getAudioTracks().forEach(track => {
        stream.addTrack(track);
      });
      const localMediaStream = new Owt.Base.LocalStream(mediaStream, new Owt.Base.StreamSourceInfo('screen-cast', 'screen-cast'));
      const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('localVideo'));
      try {
        localVideo.srcObject = stream;
      } catch (error) {
        localVideo.src = URL.createObjectURL(stream);
      }
      localVideo.volume = 0.0;

      const localStream = new EventStreamHandler(localMediaStream, undefined, undefined, localMediaStream.id, undefined);

      me.roomStatus.setLocalStream(localStream);
      me.publish(localStream, callback);
    }, err => {
      console.log(err);
      // TODO sikertelen megosztás
    });
  }


  publish(localStream: EventStreamHandler, callback) {
    if (!this.inPublish) {
      this.inPublish = true;
      const me = this;
      const codecs = [me.eventComponent.videoCodec];

      const publishOption = me.eventComponent.publishOptions;

      this.conference.publish(localStream.stream,
        publishOption,
        codecs
      ).then(publication => {
        this.eventComponent.offline = false;
        localStream.setSubscription(publication);

        const audioTrack = localStream.stream.mediaStream.getAudioTracks()[0];
        if (audioTrack) {
          this.eventComponent.eventDisplayState.hardwareMuted = audioTrack.muted;
          audioTrack.mute = (event) => {
            this.eventComponent.eventDisplayState.hardwareMuted = true;
          };
          audioTrack.unmute = (event) => {
            this.eventComponent.eventDisplayState.hardwareMuted = false;
          };
        }
        if (me.roomStatus.room.state === 0) {
          this.eventComponent.eventDisplayState.micOn = true;
          localStream.video(false);
          localStream.audio(true);
          this.eventComponent.setAudioVideoState();
        } else {
          this.eventComponent.eventDisplayState.micOn = false;
          localStream.video(false);
          localStream.audio(false);
          this.eventComponent.setAudioVideoState();
        }
        this.setStreamId(me, publication.id);
        if (callback !== undefined) {
          callback();
        }
        publication.addEventListener('ended', (event) => {
          console.log('Publication ended', event);
          if (!this.onLogout && !this.inDestroy) {
            // need reconnect all
            this.eventComponent.offline = true;
          }
        });
        publication.addEventListener('error', (event) => {
          console.log('Publication error', event);
        });
        me.inPublish = false;
      }, function (_err) {
        if (_err === 'Too many inputs') {
          // inkább elölről
          if (!me.inDestroy) {
            me.eventComponent.offline = true;
          }
        } else {
          console.log(_err);
          me.inPublish = false;
          setTimeout(() => {
            me.publish(localStream, callback);
          }, 100);
        }
      }).catch((e) => {
        console.log(e);
      });
    }
  }

  setStreamId(me: this, id: any) {
    if (me.eventComponent.httpMode) {
      me.otrHttpRequestService.doGet('/room/' + this.eventId + '/sid/' + id)
        .subscribe(
          data => {
            this.roomStatus.update(data);
          },
          err => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    } else {
      this.roomStatus.setSID(id);
    }
  }

  draw() {
    requestAnimationFrame(this.draw.bind(this));

    const array = new Uint8Array(this.analyser.frequencyBinCount);
    this.analyser.getByteFrequencyData(array);
    let values = 0;

    const length = array.length;
    for (let i = 0; i < length; i++) {
      values += array[i];
    }

    const average = values / length;
    this.eventComponent.setShowMuteMessage(this.eventComponent.getEventState().muteOn && average > 20);
  }

  onDestroy(): void {
    this.inDestroy = true;
    console.log('Stop image modify');
    this.imageModify.stopMe = true;
    this.onLogout = true;
    if (this.conference) {
      this.conference.leave();
    }
    if (this.audioContext && this.audioContext.state !== 'closed') {
      this.audioContext.close();
    }
    clearTimeout(this.interval);
  }

  private checkStreamStat() {
    /* TODO hátha ez okozza a fagyást
    if (this.roomStatus && this.roomStatus.localStream && this.roomStatus.localStream.subscription) {
      this.roomStatus.localStream.subscription.getStats().then((stats) => {

        let statsOutput = '';

        stats.forEach(report => {
          if (report.type === 'outbound-rtp') {
            Object.keys(report).forEach(statName => {
              if (statName === 'totalPacketSendDelay') {
                statsOutput += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
              }
            });
          }
          if (report.type === 'outbound-rtp') {
            Object.keys(report).forEach(statName => {
              if (statName === 'totalPacketSendDelay') {
                statsOutput += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
              }
              if (statName === 'qualityLimitationReason') {
                statsOutput += `<strong>${statName}:</strong> ${report[statName]}<br>\n`;
              }
            });
          }
        });

        // TODO  console.log(statsOutput);
      }).catch((err) => {
        console.log(err);
      });
    }
     */
  }
}
