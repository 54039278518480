import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OtrUserService} from './otr-user.service';
import {Location} from '@angular/common';
import {OtrHttpRequestService} from './otr-http-request.service';
import {OtrLangService} from './otr-lang.service';
import {Subscription} from 'rxjs/Subscription';
import {OtrMainJoinEventService} from './otr-main-join-event.service';
import {OtrLogService} from './otr-log.service';
import {HTTP} from "@ionic-native/http/ngx";
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
  menus: any = [];
  langEventDataStr: any = {};
  isNotLoggedIn = false;
  joinableEvent: any;
  subscriptionLang: Subscription;
  subscriptionJoinableEvent: Subscription;
  currentLang = '';

  constructor(
    private router: Router,
    private otrUserService: OtrUserService,
    private location_: Location,
    private otrHttpRequestService: OtrHttpRequestService,
    private otrLangService: OtrLangService,
    private otrMainJoinEventService: OtrMainJoinEventService,
    private otrLogService: OtrLogService,
    private platform: Platform
  ) {
    platform.ready().then( () => {
      this.subscriptionLang = this.otrLangService.newLangs$.subscribe((lang) => {
          this.currentLang = lang;
          this.otrUserService.getMenus(data => {
            this.menus = data;
          });
          this.otrLangService.getValuesForGroup('navBarStr', res => {
            this.langEventDataStr = res;
          });
        }
      );
      this.subscriptionJoinableEvent = this.otrMainJoinEventService.newJoinableEvent$.subscribe(
        isJoinable => {
          this.joinableEvent = isJoinable;
        }
      );
      this.otrLogService.log('SIMY APP START');
      // TODO check this out
      // window.screen.orientation.lock('portrait');
    });
  }

  ngOnInit() {
    this.platform.ready().then( () => {
      this.otrLangService.getValuesForGroup('navBarStr', res => {
        this.langEventDataStr = res;
      });
      this.reloadMenus();
    });
  }

  reloadMenus() {
    this.otrUserService.getMenus(data => {
      this.menus = data;
      this.otrLangService.setCurrentInterfaceLang(this.menus.lng);
      if (this.menus['isLoggedin']) {
        this.isNotLoggedIn = false;
        this.otrMainJoinEventService.loadEvents();
        window.addEventListener('beforeunload', function (e) {
          e.preventDefault();
        });
      } else {
        this.isNotLoggedIn = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionJoinableEvent.unsubscribe();
    this.subscriptionLang.unsubscribe();
    this.logout();
  }

  singin() {
    this.router.navigate(['/auth']).then();
  }

  goEventMonitor(eventId, tipus) {
    this.otrUserService.getUserData(data => {
      if (data.isLoggedin) {
        if (data.userType === 'X' || data.userType === 'Y') {
          this.router.navigate(['/school/' + eventId]).then();
        } else {
          if (tipus === 'CONFERENCE') {
            this.router.navigate(['/conference-monitor/' + eventId]).then();
          } else {
            this.router.navigate(['/event-monitor/' + eventId]).then();
          }
        }
      } else {
        this.otrUserService.doLogout();
      }
    });
  }

  url() {
    return this.router.url;
  }

  showNavbar() {
    return (
      !this.router.url.startsWith('/auth') &&
      !this.router.url.startsWith('/registration') &&
      !this.router.url.startsWith('/new-password') &&
      !this.router.url.startsWith('/school/') &&
      !this.router.url.startsWith('/school-teacher/') &&
      !this.router.url.startsWith('/school-student/') &&
      !this.router.url.startsWith('/event-monitor/') &&
      !this.router.url.startsWith('/homepage') &&
      !this.router.url.startsWith('/conference-monitor/')
    );
  }

  logout() {
    this.otrUserService.doLogout();
  }

  changeLang(newLang) {
    this.otrLangService.changeLang(newLang);
  }

  onActivate() {
    window.scroll(0, 0);
    this.platform.ready().then( () => {
      this.reloadMenus();
    });
  }

  selectLang() {
    this.otrLangService.selectLang('', '');
  }
}
