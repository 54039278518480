<div class="container-fluid event-monitor darkest-bkg" style="margin-top: 0;">
  <div class="player">
    <div class="partheader">
      <button class="btn btn-danger"
              style="
                    float: left;
                    z-index: 100;
                    position: relative;
                    height: 40px;
                    padding-right: 16px;
                    justify-content: center;
                    display: flex;
                    line-height: 22px;"
              (click)="backClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="currentColor" class="bi bi-door-open"
             viewBox="0 0 16 16">
          <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
          <path
            d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
        </svg>
        {{langStr.exit}}
      </button>
      <div [hidden]="!roomStatus.room.recording" style="display: inline-block;float: left;margin-left: 7px;position: absolute;">
        <i class="fa fa-circle" style="color: red" aria-hidden="true"></i>
      </div>
      <div class="room-name">{{roomStatus.room.name}}</div>
      <div class="toolar-buttons" style="float:right;margin-right:30px;">

        <div class="video-input-toggle" (click)="toggleVideoInput()" *ngIf="videoCameraToggleEnabled"
             style="cursor:pointer;float:left;margin-right:10px;position: relative;">
          <svg *ngIf="!videoCameraInput" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-webcam" viewBox="0 0 16 16"> <path d="M0 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H9.269c.144.162.33.324.531.475a6.785 6.785 0 0 0 .907.57l.014.006.003.002A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.224-.947l.003-.002.014-.007a4.473 4.473 0 0 0 .268-.148 6.75 6.75 0 0 0 .639-.421c.2-.15.387-.313.531-.475H2a2 2 0 0 1-2-2V6Zm2-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1H2Z"/> <path d="M8 6.5a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm7 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"/> </svg>

          <svg *ngIf="videoCameraInput" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-display" viewBox="0 0 16 16"> <path d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z"/> </svg>

        </div>

        <div class="participant-toggle" (click)="eventDisplayState.showParticipants()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative" title="{{langStr.participants}}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill"
               viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
            <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
          </svg>

          <span class="position-absolute top-10 start-100 translate-middle badge rounded-pill"
                [ngClass]="{'bg-danger':roomStatus.interpreterIsOffline,'bg-success':!roomStatus.interpreterIsOffline}">
            {{roomStatus.missingUserList.length + roomStatus.userList.length + 1}}/{{roomStatus.userList.length + 1}}
          </span>
        </div>
        <div class="chat-toggle" (click)="eventDisplayState.showChat()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative" title="{{langStr.chat}}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat"
               viewBox="0 0 16 16">
            <path
              d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
          </svg>
          <span
            [style.display]="roomStatus.newMessageCount == 0?'none':''"
            class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">{{roomStatus.newMessageCount}}</span>
        </div>
        <div class="files-toggle" (click)="eventDisplayState.showDocuments()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative" title="{{langStr.documents}}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files"
               viewBox="0 0 16 16">
            <path
              d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
          </svg>
          <span
            [style.display]="roomStatus.newFileCount == 0?'none':''"
            class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">{{roomStatus.newFileCount}}</span>
        </div>

        <div class="settings-toggle"  (click)="eventDisplayState.showSettings()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative" title="{{langStr.settings}}">

          <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor"  viewBox="0 0 512 512">
            <path d="M200 0H312l17.2 78.4c15.8 6.5 30.6 15.1 44 25.4l76.5-24.4 56 97-59.4 54.1c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l59.4 54.1-56 97-76.5-24.4c-13.4 10.3-28.2 18.9-44 25.4L312 512H200l-17.2-78.4c-15.8-6.5-30.6-15.1-44-25.4L62.3 432.5l-56-97 59.4-54.1C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L6.3 176.5l56-97 76.5 24.4c13.4-10.3 28.2-18.9 44-25.4L200 0zm56 336c44.2 0 80-35.8 80-80s-35.8-80-80-80s-80 35.8-80 80s35.8 80 80 80z"/>
          </svg>
        </div>

        <div *ngIf="roomStatus.room.state == 0" style="cursor:pointer;float:left;margin-right:10px;"
             ngbPopover="{{langStr.gatheringInfo}}">
          <span class="fa fa-info-circle"></span>
        </div>
        <div class="rightPanel-close" *ngIf="eventDisplayState.rightPanelVisible"
             (click)="eventDisplayState.closeRightPanel()"
             style="cursor:pointer;float:left;margin-right:10px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
               viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
      </div>
    </div>
    <div
      [ngClass]="{'video-area':eventDisplayState.rightPanelVisible,'video-area-wide':!eventDisplayState.rightPanelVisible}">
      <button (click)="eventDisplayState.toggleFit()"
              class="btn"
              style="color: white;min-width: 40px;top: 10px;position: absolute;left: 10px;padding-top:11px;padding-left:8px;padding-right:8px;z-index: 200;background-color: #3b3a39; ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-aspect-ratio"
             style="margin-top: -3px;" viewBox="0 0 16 16">
          <path
            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
          <path
            d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z"/>
        </svg>
        <span *ngIf="roomStatus.displayedNickname != ''" style="margin-left: 10px;" class="stroke">{{roomStatus.displayedNickname}}</span>
      </button>
      <div id="otherUserVideo" class="speaker-video">
        <video id="videoSpeakingUser" autoplay width="100%" height="100%"></video>
      </div>
      <div [ngClass]="{'blackbkg':!eventDisplayState.hasCamera}"
           style="width:180px; position: absolute; top: 5px; right: 5px; z-index: 100" title="local medias">
        <div id="myVideo" style="width:182px; height: auto; border: 1px #323131 solid; display: flex; align-items: center;" title="my camera">
          <ngx-spinner bdColor="#333333" size="large" color="#ffffff" type="ball-8bits"
                       [fullScreen]="false" [hidden]="!serverConnection.inPublish">
            <p style="color: white"> Loading... </p>
          </ngx-spinner>
          <video id="localVideo" playsinline style="position:absolute;display: none;" autoplay width="640" height="480"></video>
          <div [hidden]="videoEnabled()" id="localImage"
               style="position:absolute;background-size: cover;width: 182px;height: 137px;background-position: center; border-radius: 7px; left: 0;  top: 0;"
               [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + userId + ')'}">
          </div>
          <div id="SelfName"
               style="position: absolute;left: 1px;bottom:1px;width: 178px;text-align:center;overflow: hidden">
            <span class="stroke">{{nickname}}</span>
          </div>
          <canvas [hidden]="!videoEnabled()" id="canvas-output" width="{{outputWidth}}" height="{{outputHeight}}" style="width: 100%;height: auto"></canvas>

          <div [hidden]="gestureCounter == 4" style="-webkit-text-stroke: 1px white;position: absolute;font-size: 35px;top: 10px;left: 10px;color: green;font-weight: 900;">{{gestureCounter}}</div>
        </div>
      </div>
      <div *ngIf="roomStatus.interpreter" id="interpreterVideoContainer"
           [hidden]="roomStatus.interpreter.subscription == null"
           [style.display]="roomStatus.room.state == 0?'':'none'"
           style=" position: absolute; bottom: 5px; left: 5px; z-index: 100; overflow:hidden; width:180px; height: 137px;">
        <div [hidden]="roomStatus.interpreter.subscription == null || roomStatus.interpreter.videoState == 0"
             id="interpreterVideo"
             style="position: absolute;width:100%; height: 100%; border: none;"></div>
        <div [hidden]="roomStatus.interpreter.subscription == null || roomStatus.interpreter.videoState == 1"
             id="interpreterImage"
             style="position:absolute;background-size: cover;width: 100%; height: 100%;background-position: center; border-radius: 7px; left: 0; top: 0;"
             [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + roomStatus.interpreter.id + ')'}">
        </div>
        <div style="position: absolute;left: 10px;top:5px;">
          <span class="stroke">{{langStr.interpreter}}</span>
        </div>
        <div style="position: absolute;right: 10px;bottom:5px;width: 100px;text-align:right;">
          <span class="stroke">{{roomStatus.interpreter.nickname}}</span>
        </div>
      </div>
      <div *ngIf="showMuteMessage" [@enterAnimation] id="unmuteaudio"
           style="position: absolute;width: 100%; height: 100%; top: 0; left: 0; display: flex; align-items: center; justify-content: center;">
        <span class="stroke" style="color: #ff7070; font-size: 24px; text-align: center;">{{langStr.turnonmic}}</span>
      </div>
    </div>
    <div class="other-videos">
      <ng-template ngFor let-item [ngForOf]="roomStatus.userList">
        <div *ngIf="item.pinned" #userPinned id="Partner{{item.id}}Container" data-userId="{{item.id}}"
             class="videoContainer">
          <video id="Partner{{item.id}}VideoContainer" [hidden]="item.videoState === 0"
                 style="width:100%;height:100%;overflow:hidden;position: absolute;left: 0;top: 0;" autoplay>
          </video>
          <div id="Partner{{item.id}}" [hidden]="item.videoState === 1"
               style="background-size: cover;width: 80px;height: 80px;background-position: center;  border-radius: 15px;  position: absolute;  left: 50px;  top: 10px;"
               [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}"
               [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
          </div>
          <div id="Partner{{item.id}}CanvasContainer"
               style="width:16px;height:16px;overflow:hidden;position: absolute;right: 10px;top:10px;">
            <canvas width="16" height="16" style="width:16px;height:16px;" id="Partner{{item.id}}Canvas"></canvas>
          </div>
          <div id="Partner{{item.id}}Name"
               style="position: absolute;left: 1px;bottom:1px;width: 178px;text-align:center;overflow: hidden">
            <span class="stroke">{{item.nickname}}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="rightPanel" [hidden]="!eventDisplayState.rightPanelVisible">
    <div class="participants darkest-bkg" [hidden]="!eventDisplayState.participantsVisible">
      <div class="partheader">
        <div>{{langStr.participants}}</div>
      </div>
      <div class="partsubheader collapsed" data-toggle="collapse" data-target="#missingUsers" aria-expanded="false" aria-controls="missingUsers">
        <div>{{langStr.missing}} ({{roomStatus.missingUserList.length}})</div>
      </div>
      <div @list id="missingUsers" class="collapse" aria-labelledby="missingUsers"
           style="min-height:100px;max-height:100px;overflow-y:scroll;border-bottom:1px solid #323131">
        <ng-template ngFor let-item [ngForOf]="roomStatus.missingUserList">
          <div @items class="chatrow" style="min-height: 50px;color: white">
            <div id="UserM{{item.id}}"
                 style="float:left;margin-right: 10px; background-size: cover;width: 40px;height: 40px;background-position: center;  border-radius: 7px;  position: relative;  left: 0;  top: 0;"
                 [ngStyle]="{'background-image': 'url(/user-avatar/id/' + item.id + ')'}"
                 [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
            </div>
            <span class="sender">{{item.nickname}}</span><br/>
            <span *ngIf="item.type == 'T'" class="message">{{langStr.interpreter}}</span>
            <span *ngIf="item.type == 'U' && item.id == roomStatus.room.userTree.id"
                  class="message">{{langStr.organizer}}</span>
          </div>
        </ng-template>
      </div>
      <div>
        <div class="partsubheader" data-toggle="collapse" data-target="#currentContainer" aria-expanded="false" aria-controls="currentContainer">
          <div>{{langStr.present}} ({{roomStatus.userList.length}})</div>
        </div>
        <div id="currentContainer" style="width:100%;height:calc(100vh - 342px);overflow-y: scroll;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.userList">
            <div @items class="chatrow" style="position:relative;min-height: 50px;color: white">
              <div id="UserP{{item.id}}"
                   style="float:left;margin-right: 10px; background-size: cover;width: 40px;height: 40px;background-position: center;  border-radius: 7px;  position: relative;  left: 0;  top: 0;"
                   [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}"
                   [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
              </div>
              <span class="sender">{{item.nickname}}</span><br/>
              <span *ngIf="item.type == 'T'" class="message">{{langStr.interpreter}}</span>
              <span *ngIf="item.type == 'U' && item.id == roomStatus.room.userTree.id"
                    class="message">{{langStr.organizer}}</span>
              <div id="Partner{{item.id}}Lang" style="position: absolute;right: 5px;bottom:10px;">
                <span class="stroke">{{item.lang}}</span>
              </div>
              <div id="Partner{{item.id}}StreamStatus"
                   [ngClass]="{'streamDisconnected':item.streamStatus=='10','streamNotConnected':item.streamStatus=='0','streamPublished':item.streamStatus=='1','streamConnected':item.streamStatus=='2','streamConnectError':item.streamStatus=='3'}">
              </div>
              <div id="Partner{{item.id}}Pin"
                   style="position: absolute;right: 40px;top:4px;width: 20px;text-align:center;background-color:#3b3a39;color:white;border:1px solid #323131;"
                   (click)="togglePin(item.id)">
                <svg *ngIf="!item.pinned" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-display" viewBox="0 0 16 16">
                  <path
                    d="M0 4s0-2 2-2h12s2 0 2 2v6s0 2-2 2h-4c0 .667.083 1.167.25 1.5H11a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1h.75c.167-.333.25-.833.25-1.5H2s-2 0-2-2V4zm1.398-.855a.758.758 0 0 0-.254.302A1.46 1.46 0 0 0 1 4.01V10c0 .325.078.502.145.602.07.105.17.188.302.254a1.464 1.464 0 0 0 .538.143L2.01 11H14c.325 0 .502-.078.602-.145a.758.758 0 0 0 .254-.302 1.464 1.464 0 0 0 .143-.538L15 9.99V4c0-.325-.078-.502-.145-.602a.757.757 0 0 0-.302-.254A1.46 1.46 0 0 0 13.99 3H2c-.325 0-.502.078-.602.145z"/>
                </svg>
                <svg *ngIf="item.pinned" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-display" viewBox="0 0 16 16">
                  <path
                    d="M6 12c0 .667-.083 1.167-.25 1.5H5a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-.75c-.167-.333-.25-.833-.25-1.5h4c2 0 2-2 2-2V4c0-2-2-2-2-2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h4z"/>
                </svg>
              </div>
              <div *ngIf="item.videoState == 1" id="Partner{{item.id}}VideoEnabled"
                   style="position: absolute;right: 50px;top:28px;height:10px;width: 10px;text-align:center;background-color:#3b3a39;color:white;border:1px solid #323131;">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" class="bi bi-camera-video" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"/>
                </svg>

              </div>
              <div *ngIf="item.audioState == 1" id="Partner{{item.id}}VideoEnabled"
                         style="position: absolute;right: 40px;top:28px;height:10px;width: 10px;text-align:center;background-color:#3b3a39;color:white;border:1px solid #323131;">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor" class="bi bi-mic" viewBox="0 0 16 16">
                  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
                  <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z"/>
                </svg>

            </div>
              <div id="Partner{{item.id}}Mute"
                   style="position: absolute;right: 7px;top:0;width: 20px;text-align:center;background-color:#3b3a39;color:white;border:1px solid #323131;"
                   (click)="toggleUserEnabled(item.id)">
                <em class="fa" [ngClass]="{'fa-volume-up':item.enabled,'fa-volume-off':!item.enabled}"></em>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="documents darkest-bkg" [hidden]="!eventDisplayState.documentsVisible">
      <div>
        <div class="formInnerDiv">
          <div style="border: 1px solid #323131;position: relative;">
            <div class="partheader">
              <div style="float:left">{{langStr.documents}}</div>
              <label class="btn-file-upload" style="float:right;">
                <em class="fa fa-file fa-fw buttonInDropZone"></em>
                <input type="file" name="file" ng2FileSelect [uploader]="fileHandler.uploader" multiple hidden/>
              </label>
            </div>
            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                 [uploader]="fileHandler.uploader" class="well my-drop-zone"
                 style="overflow-y: scroll;  height: calc(100vh - 198px);position: absolute;">
              <ng-template ngFor let-item [ngForOf]="roomStatus.room.files">
                <div class="filerow" style="position:relative;"
                     (click)="fileHandler.downloadFile(item.id);$event.stopPropagation()">
                  <i style="position:absolute;right:5px;top:5px;" title="Törlés" class="fa fa-remove fa-fw buttonIcon"
                     aria-hidden="true" (click)="fileHandler.deleteFile(item.id);$event.stopPropagation()"></i>
                  <span class="filename">{{item.name}}</span><br/><span class="fileuploader">{{item.userName}}</span>
                </div>
              </ng-template>
            </div>
            <div class="fileAlertBox" *ngIf="fileHandler.tooLargeFile">
              {{langStr.tooLargeFile}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat darkest-bkg" [hidden]="!eventDisplayState.chatVisible">
      <div class="partheader">
        <div>{{langStr.chat}}</div>
      </div>
      <div style="height: 100%">
        <div class="input-group mb-3 darkest-bkg" style="margin-bottom: 0 !important;">
          <input type="text" class="form-control darkest-bkg light-bordered" id="chatmsg" #chatmsg
                 (keyup.enter)="onEnter(chatmsg.value)">
          <div class="input-group-append">
            <button class="btn btn-secondary darkest-bkg" (click)="sendText()" type="button" placement="auto"
                    ngbTooltip="{{langStr.sendmessage}}"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
          </div>
        </div>
        <div id="chatmsgs" class="darkest-bkg"
             style="overflow:auto;height: calc(100vh - 232px);width:auto;border:1px #323131 solid;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.room.texts">
            <div @items class="chatrow">
              <span class="sender">{{item.user.nickname}}</span>&nbsp;<span
              class="dateTime">{{item.dateTime}}</span><br/><span class="message">{{item.text}}</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="settings darkest-bkg" [hidden]="!eventDisplayState.settingsVisible">
      <div>
        <div class="formInnerDiv">
          <div style="border: 1px solid #323131;position: relative;">
            <div class="partheader">
              <div style="float:left">{{langStr.settings}}</div>
            </div>

            <div *ngIf="!isSafari() && !isMobile()" style="display: flex;flex-direction: row;justify-content: center;">
              <div
                [ngClass]="{'selected-blur':imageDescriptor.index==0,'passive-blur':imageDescriptor.index != 0}"
                   (click)="imageDescriptor.blurredEnabled=false;imageDescriptor.virtualBackgroundEnabled=false;imageDescriptor.index=0;">
              </div>
              <div
                [ngClass]="{'selected-blur':imageDescriptor.index==1,'passive-blur':imageDescriptor.index != 1}"
                (click)="imageDescriptor.blurredEnabled=true;imageDescriptor.virtualBackgroundEnabled=false;imageDescriptor.index=1;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="40" fill="currentColor"
                     class="bi bi-display" viewBox="0 0 24 24">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2C5.10457 2 6 2.89543 6 4C6 5.10457 5.10457 6 4 6C2.89543 6 2 5.10457 2 4C2 2.89543 2.89543 2 4 2ZM12 2C13.1046 2 14 2.89543 14 4C14 5.10457 13.1046 6 12 6C10.8954 6 10 5.10457 10 4C10 2.89543 10.8954 2 12 2ZM22 4C22 2.89543 21.1046 2 20 2C18.8954 2 18 2.89543 18 4C18 5.10457 18.8954 6 20 6C21.1046 6 22 5.10457 22 4ZM4 10C5.10457 10 6 10.8954 6 12C6 13.1046 5.10457 14 4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10ZM14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12ZM20 10C21.1046 10 22 10.8954 22 12C22 13.1046 21.1046 14 20 14C18.8954 14 18 13.1046 18 12C18 10.8954 18.8954 10 20 10ZM6 20C6 18.8954 5.10457 18 4 18C2.89543 18 2 18.8954 2 20C2 21.1046 2.89543 22 4 22C5.10457 22 6 21.1046 6 20ZM12 18C13.1046 18 14 18.8954 14 20C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20C10 18.8954 10.8954 18 12 18ZM22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22C21.1046 22 22 21.1046 22 20Z" fill="black"/>
                </svg>
              </div>
              <img src="/assets/images/background/IMG_3150.jpg" width="40" height="40"
                   [ngClass]="{'selected-blur':imageDescriptor.index==2,'passive-blur':imageDescriptor.index != 2}"
                   (click)="imageDescriptor.blurredEnabled=false;imageDescriptor.virtualBackgroundEnabled=true;imageDescriptor.selectedBackground=$event.target;imageDescriptor.index=2;">
              <img src="/assets/images/background/IMG_3151.jpg" width="40" height="40"
                   [ngClass]="{'selected-blur':imageDescriptor.index==3,'passive-blur':imageDescriptor.index != 3}"
                   (click)="imageDescriptor.blurredEnabled=false;imageDescriptor.virtualBackgroundEnabled=true;imageDescriptor.selectedBackground=$event.target;imageDescriptor.index=3;">
              <img src="/assets/images/background/IMG_3153.jpg" width="40" height="40"
                   [ngClass]="{'selected-blur':imageDescriptor.index==4,'passive-blur':imageDescriptor.index != 4}"
                   (click)="imageDescriptor.blurredEnabled=false;imageDescriptor.virtualBackgroundEnabled=true;imageDescriptor.selectedBackground=$event.target;imageDescriptor.index=4;">
            </div>
            <!--div style="display: flex;flex-direction: row;justify-content: center;">
              <button (click)="startRecording()">start</button>
              <button (click)="stopRecording()">stop</button>
            </div-->
            <div style="display: flex;flex-direction: row;justify-content: center;padding: 0 35px 35px 35px;">
              <div class="input-group mb-3 darkest-bkg" style="margin-bottom: 0 !important;">
                <input type="text" class="form-control light-bordered"
                       (keyup.enter)="setNickname()" [(ngModel)]="nickname" >
                <div class="input-group-append">
                  <button class="btn btn-secondary" (click)="setNickname()" type="button" placement="auto">
                    <i class="fa fa-user-circle" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="roomStatus != undefined && roomStatus.self != undefined && roomStatus.self.type == 'U' && roomStatus.self.id == roomStatus.room.userTree.id" style="display: flex;flex-direction: row;justify-content: center;padding-left: 35px;padding-bottom: 35px;">
              <div class="input-group mb-3 darkest-bkg" style="margin-bottom: 0 !important;">
                <button style="width: 230px; height: 35px;" class="btn-success" (click)="activateOrganizer()" >
                  <em class="fa fa-microphone"></em>
                </button>
              </div>
            </div>

            <!-- TODO simulcast teszt
            <div *ngIf="!isMobile()" style="display: flex;flex-direction: row;justify-content: center;padding-left: 35px;padding-bottom: 35px;">
              <div class="input-group mb-3 darkest-bkg" style="margin-bottom: 0 !important;">
                <button style="width: 230px; height: 35px;" class="btn-success" (click)="toggleSimulcast()" >
                  Experimental video:
                  <span *ngIf="useSimulcast">ON</span>
                  <span *ngIf="!useSimulcast">OFF</span>
                </button>
              </div>
            </div>
            -->

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="control-area">
    <div>
      <div>
        <button (click)="toggleCamera()" [disabled]="!eventDisplayState.hasCamera"
                [ngClass]="{'btn-success': videoEnabled(),'btn-secondary': !videoEnabled()}"
                class="btn"
                style="width: 60px;margin-right:5px;top: 5px;position: absolute;left: 5px;padding-left:8px;padding-right:8px;z-index: 200;">
          <i class="fa"
             [ngClass]="{'fa-video-camera': videoEnabled(),'fa-video-slash': !videoEnabled()}"
             aria-hidden="true"></i>
        </button>
        <button (click)="toggleMic()" *ngIf="roomStatus.room.state == 0"
                [ngClass]="{'btn-secondary':!audioEnabled(),'btn-success':audioEnabled()}"
                class="btn"
                style="width: 60px;margin-left:5px;position: absolute;left: 65px;top: 5px;padding-left:8px;padding-right:8px;z-index: 200;">
          <i class="fa"
             [ngClass]="{'fa-microphone':audioEnabled(),'fa-microphone-slash':!audioEnabled()}"
             aria-hidden="true"></i>
        </button>
        <div class="slidecontainer"
             style="width: 165px;left: 66px;top:45px;position: absolute;">
          <input type="range" min="0" max="100" [(ngModel)]="maxVolume" class="slider" id="volumeSlider"
                 (change)="setGains()">
          <div class="volumedot" [ngStyle]="getMaxVolume()">
            {{maxVolume}}
          </div>
        </div>
        <button *ngIf="roomStatus.room.state == 1" class="btn"
                style="width: 289px;position: absolute;top: 75px;left: 5px;height:33px"
                [ngClass]="{'greenglowbutton':eventDisplayState.speakingStatus==='2','btn-secondary':eventDisplayState.speakingStatus === '0','btn-success':eventDisplayState.speakingStatus === '1','btn-warning':eventDisplayState.speakingStatus==='3'}"
                (click)="nextState()"><em class="fa fa-microphone"></em></button>
        <button *ngIf="roomStatus.room.state == 1" class="btn"
                style="width: 60px;position: absolute;left: 70px;top: 5px;padding-left: 8px;padding-right: 8px;"
                [ngClass]="{'btn-success':eventDisplayState.muteOn,'btn-secondary':!eventDisplayState.muteOn}"
                (mousedown)="mute()" (mouseup)="unmute()">MUTE</button>
      </div>
      <div style="position:absolute;right: 5px;top: 5px;">
        <ng-template ngFor let-item [ngForOf]="roomStatus.room.languages">
          <button style="width:62px;" class="btn" id="outputLang{{item}}"
                  [ngClass]="{'btn-light':(roomStatus.self.lang!=item),'btn-success':roomStatus.self.lang==item }"
                  type="button" (click)="setLang(item)">{{item}}</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div [hidden]="!offline"  style="width: 100vw;
  height: 100vh;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #595959d1;
  z-index: 400;">
  <span style="color: white; font-size: 40px;">Server connection lost</span>
</div>
