import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtrEventParticipantService} from '../otr-event-participant.service';
import {OtrUserService} from '../otr-user.service';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {OtrLangService} from '../otr-lang.service';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {Base64} from '../Owt/base/base64';
import * as Owt from '../Owt/Owt';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

@Component({
  selector: 'app-otr-event-monitor',
  templateUrl: './otr-event-monitor.component.html',
  styleUrls: ['./otr-event-monitor.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class OtrEventMonitorComponent implements OnInit, OnDestroy {

  @ViewChild('rateForm') rateForm: HTMLFormElement;
  eventId;
  loginInfo;
  isDirect = false;
  mode: any = 'X';
  currentLang = 'hu';
  subscriptionLang: any;
  audioInputs = [];
  audioOutputs = [];
  videoInputs = [];
  stream: any;
  selectedAudioInput: any;
  selectedAudioOutput: any;
  selectedVideoInput: any;
  videoPreview = true;
  preEventStr: any = {};
  eventDataStr: any = {};
  eventQueryStr: any = {};
  currentStatusStr = '';
  showMonitor = true;
  rateData: any = {};
  errMessageData: any = {};
  isRateIsDone = false;
  eventData: any = {};
  beforeConnect = true;
  eventMode: string;
  statuses = {
    availableRid: '',
    network: {
      websocket: false,
      server: false,
    },
    camera: false,
    stream: {
      room: false,
      output: {
        audio: false,
        video: false
      },
      publish: false,
      subscribe: false,
      input: {
        audio: false,
        video: false
      },
    }
  };
  private mediaStream: MediaStream;
  private conference: Owt.Conference.ConferenceClient;
  previewMessage = '';
  helpMessage = '';
  allowToConnect = false;
  outputWidth = 1280;
  outputHeight = 720;
  videoCodec = 'vp8';
  publishOptions = {};
  subscribeOptions = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private otrUserService: OtrUserService,
    private otrEventParticipantService: OtrEventParticipantService,
    private otrHttpRequestService: OtrHttpRequestService,
    private otrLangService: OtrLangService,
    private _location: Location,
    private androidPermissions: AndroidPermissions
  ) {
    this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);

    this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO);

    function error() {
      console.warn('Camera permission is not turned on');
    }

    function success( status ) {
      if ( !status.hasPermission ) {
        error();
      }
    }
    if (activatedRoute.snapshot.params.id) {
      this.eventId = activatedRoute.snapshot.params.id;

      this.testNetwork();

      if (activatedRoute.snapshot.params.login) {
        this.loginInfo = activatedRoute.snapshot.params.login;
        this.otrHttpRequestService.doPostAndCheck('/auth/direct', {event: this.eventId, key: this.loginInfo}, res => {
            if (!res['isError']) {
              this.isDirect = true;
              this.currentLang = otrLangService.getCurrentLang();
              if (this.videoPreview) {
                navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this)).catch(this.handleError.bind(this));
              }
              this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
                (lang) => {
                  this.currentLang = lang;
                  this.otrLangService.getValuesForGroup('preEventStr,eventQueryStr,eventDataStr', result => {
                    this.preEventStr = result.preEventStr;
                    this.eventDataStr = result.eventDataStr;
                    this.eventQueryStr = result.eventQueryStr;
                  });
                });
            } else {
              this.otrUserService.doLogout();
            }
          }
        );
      } else {
        // this.getUserData();
        this.currentLang = otrLangService.getCurrentLang();
        if (this.videoPreview) {
          navigator.mediaDevices.enumerateDevices().then(this.gotDevices.bind(this)).catch(this.handleError.bind(this));
        }
        this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
          (lang) => {
            this.currentLang = lang;
            this.otrLangService.getValuesForGroup('preEventStr,eventQueryStr,eventDataStr', result => {
              this.preEventStr = result.preEventStr;
              this.eventDataStr = result.eventDataStr;
              this.eventQueryStr = result.eventQueryStr;
            });
          });
      }
    } else {
      this.eventId = 0;
      this.getUserData();
    }
  }

  private testNetwork() {

    const me = this;
    console.log('get full room');
    this.otrHttpRequestService.doGet('/roomnc/' + this.eventId + '?refresh=0').subscribe(fullRoomResult => {

      console.log('get full room result:', fullRoomResult);
      const rtcConfiguration = {
        iceServers: [],
        iceTransportPolicy: 'relay'
      };
      if (fullRoomResult.stunServer) {
        rtcConfiguration.iceServers = [{
          urls: [fullRoomResult.stunServer]
        }, {
          urls: [fullRoomResult.turnServer.split(',')],
          credential: fullRoomResult.turnPassword,
          username: fullRoomResult.turnUser
        }];
        me.outputWidth = fullRoomResult.streamProperties.width;
        me.outputHeight = fullRoomResult.streamProperties.height;
        me.videoCodec = fullRoomResult.streamProperties.videoCodec;
        me.publishOptions = fullRoomResult.streamProperties.publishOptions;
        me.subscribeOptions = fullRoomResult.streamProperties.subscribeOptions;
      }
      const url = '/createTokenByName/';
      const body = {
        room: 'conference_test' + this.eventId,
        username: 'alma',
        role: 'presenter'
      };
      me.previewMessage = me.preEventStr.connectToServer || 'Connecting to server';
      me.helpMessage = 'Can\'t connect room server';
      this.otrHttpRequestService.doPostTextResponse(url, body).subscribe((res: any) => {
        me.statuses.network.server = true;
        const token = JSON.parse(Base64.decodeBase64(res));
        const isSecured = (token.secure === true);
        let host = token.host;
        if (host.indexOf('http') === -1) {
          host = isSecured ? ('https://' + host) : ('http://' + host);
        }
        const opts = {
          'reconnection': false,
          'reconnectionAttempts': 1,
          'force new connection': true,
        };
        const _socket = io(host, opts);
        _socket.on('progress', (data) => {
          console.log(data);
        });
        me.previewMessage = me.preEventStr.connectToStreamServer || 'Connecting to stream server';
        me.helpMessage = '';
        _socket.on('connect', () => {
          me.statuses.network.websocket = true;
          me.previewMessage = me.preEventStr.getCamera || 'Get camera information';

          // Test camera
          navigator.mediaDevices.enumerateDevices()
            .then((devices) => {
              let hasCamera = false;
              devices.forEach((device) => {
                if (device.kind === 'videoinput') {
                  hasCamera = true;
                }
              });
              if (hasCamera) {
                me.statuses.camera = true;

                me.previewMessage = me.preEventStr.connectToConference || 'Connecting to conference server';
                // Test room
                this.conference = new Owt.Conference.ConferenceClient({
                  rtcConfiguration: rtcConfiguration
                });
                this.conference.join(res).then(_resp => {

                  me.statuses.stream.room = true;
                  me.previewMessage = me.preEventStr.createOuputStream || 'Creating output video/audio';

                  const videoConstraints = new Owt.Base.VideoTrackConstraints(Owt.Base.VideoSourceInfo.CAMERA);
                  videoConstraints.resolution = { width: me.outputWidth, height: me.outputHeight};
                  // Publish stream
                  const streamConstraints = new Owt.Base.StreamConstraints(
                    new Owt.Base.AudioTrackConstraints(Owt.Base.AudioSourceInfo.MIC),
                    videoConstraints
                  );
                  const mediaConstraints = {
                    audio: true,
                    video: {
                      width: {ideal: me.outputWidth},
                      height: {ideal: me.outputHeight},
                    }
                  };
                  navigator.mediaDevices.getUserMedia(mediaConstraints).then(stream => {
                  // Owt.Base.MediaStreamFactory.createMediaStream(streamConstraints).then(stream => {
                    if (stream.getAudioTracks().length > 0) {
                      me.statuses.stream.input.audio = true;
                    }
                    if (stream.getVideoTracks().length > 0) {
                      me.statuses.stream.input.video = true;
                    }
                    this.mediaStream = stream;
                    const localMediaStream = new Owt.Base.LocalStream(stream, new Owt.Base.StreamSourceInfo('mic', 'camera'));

                    const publishOption = me.publishOptions;
                    /*{
                      audio: true,
                      video: [
                        {rid: 'q', active: true, scaleResolutionDownBy: 2.0, maxBitrate: 500000},
                        {rid: 'h', active: true, scaleResolutionDownBy: 1.5, maxBitrate: 1000000},
                        {rid: 'f', active: true, scaleResolutionDownBy: 1.0, maxBitrate: 1500000}
                      ]
                    };
                     */
                        /*
                      {
                        audio: true,
                        video: [
                          {rid: 'q', scalabilityMode: 'L1T3', active: true},
                          {rid: 'h', scalabilityMode: 'L1T3', active: true},
                          {rid: 'f', scaleResolutionDownBy: 1.0, scalabilityMode: 'L1T3', active: true}
                        ]};
                         */
                      /*{
                        audio: [{
                          maxBitrate: 300000,
                          codec: {name: 'opus', clockRate: 48000, channelCount: 1}
                        }],
                        video: [{
                          maxBitrate: 1500000,
                          resolution: {width: 1280, height: 960},
                          codec: {name: 'vp8'}
                        }]
                      };*/
                    me.previewMessage = me.preEventStr.publish || 'Publishing video/audio stream';

                    this.conference.publish(localMediaStream,
                      publishOption,
                      [me.videoCodec]
                    ).then(async () => {
                      me.statuses.stream.publish = true;
                      // Wait for own stream
                      let remote = this.conference.info.remoteStreams.find(
                        (remoteStream) => remoteStream.origin === this.conference.info.self.id
                      );
                      while (remote === undefined) {
                        await new Promise(resolve => setTimeout(resolve, 100));
                        remote = this.conference.info.remoteStreams.find(
                          (remoteStream) => remoteStream.origin === this.conference.info.self.id
                        );
                      }
                      me.previewMessage = me.preEventStr.subscribe || 'Subscribing to video/audio stream';
                      const videoCapabilities = me.subscribeOptions// {};
                      this.conference.subscribe(remote, {audio: {}, video: videoCapabilities}).then((subscription) => {
                        me.statuses.stream.subscribe = true;
                        const audioContext = new AudioContext();
                        const gainNode = audioContext.createGain();
                        gainNode.gain.setValueAtTime(0.0, gainNode.context.currentTime);
                        // Show video
                        const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('testLocalVideo'));

                        if (remote.mediaStream.getAudioTracks().length > 0) {
                          me.statuses.stream.output.audio = true;
                        }
                        if (remote.mediaStream.getVideoTracks().length > 0) {
                          me.statuses.stream.output.video = true;
                        }

                        if (localVideo !== undefined && localVideo !== null) {

                          try {
                            localVideo.srcObject = remote.mediaStream;
                          } catch (error) {
                            localVideo.src = URL.createObjectURL(remote.mediaStream);
                          }
                          localVideo.volume = 0;
                          console.log(localVideo);
                          // Show volume level
                          me.previewMessage = '';
                          me.allowToConnect = true;
                        }

                      }).catch((err) => {
                        console.log(err);
                      });
                    }, function (_err) {
                      console.log(_err);
                      me.statuses.stream.publish = false;
                    }).catch((e) => {
                      console.log(e);
                    });
                    // Subscribe own stream and show
                  });

                }, function (_err) {
                  console.log(_err);
                  me.statuses.stream.room = false;
                });
              }
            })
            .catch(function (err) {
              me.statuses.camera = false;
            });
        });
        _socket.on('connect_error', (e) => {
          me.statuses.network.websocket = false;
        });
      });
    });
  }

  ngOnInit() {
    this.otrLangService.getValuesForGroup('preEventStr,eventQueryStr,eventDataStr', result => {
      this.preEventStr = result.preEventStr;
      this.eventDataStr = result.eventDataStr;
      this.eventQueryStr = result.eventQueryStr;
      this.checkStatus();
    });
  }

  checkStatus() {
    this.otrHttpRequestService.doGetAndCheck('/event-query/load/' + this.eventId, data => {
      if (!data['isError']) {
        if (data['result'].length > 0) {
          this.eventData = data['result'][0];
          if (moment(this.eventData.esemeny_kezdete).subtract('15', 'minutes').isBefore(moment.now())) {
            if (moment(this.eventData.esemeny_vege).isAfter(moment.now())) {
              this.currentStatusStr = '';
            } else {
              this.currentStatusStr = this.preEventStr.event_is_over;
            }
          } else {
            this.currentStatusStr = this.preEventStr.not_yet;
          }
        }
      }
      setTimeout(this.checkStatus.bind(this), 30 * 1000);
    });
  }

  gotDevices(deviceInfos) {

    this.audioInputs = [];
    this.audioOutputs = [];
    this.videoInputs = [];
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === 'audioinput') {
        this.audioInputs.push({id: deviceInfo.deviceId, name: deviceInfo.label});
      } else if (deviceInfo.kind === 'audiooutput') {
        this.audioOutputs.push({id: deviceInfo.deviceId, name: deviceInfo.label});
      } else if (deviceInfo.kind === 'videoinput') {
        this.videoInputs.push({id: deviceInfo.deviceId, name: deviceInfo.label});
      } else {
        console.log('Some other kind of source/device: ', deviceInfo);
      }
    }
    if (!this.selectedVideoInput) {
      this.selectedVideoInput = this.videoInputs[0];
    }
    if (!this.selectedAudioOutput) {
      this.selectedAudioOutput = this.audioOutputs[0];
    }
    if (!this.selectedAudioInput) {
      this.selectedAudioInput = this.audioInputs[0];
    }
  }

  handleError(error) {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  }

  getUserData(): void {
    this.otrUserService.getUserData(
      userData => {
        if (userData) {
          if (this.eventId !== 0) {
            this.otrEventParticipantService.getParticipant(this.eventId,
              data => {
                if (data.resultEvent) {
                  this.eventMode = data.resultEvent.tipus;
                  const usr = data.result[0];
                  if (usr) {
                    this.mode = userData.userType;
                  }
                }
              }
            );
          }

        }
      }
    );
  }

  selectLang() {
    this.otrLangService.selectLang('', '');
  }

  gotStream(stream) {
    this.stream = stream; // make stream available to console
    const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('localVideo'));
    console.debug('otr-event-monitor-component', 'gotStream', 'localVideo');
    try {
      localVideo.srcObject = stream;
    } catch (error) {
      localVideo.src = URL.createObjectURL(stream);
    }
    return navigator.mediaDevices.enumerateDevices();
  }

  start() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    const audioSource = this.selectedAudioInput.id;
    const videoSource = this.selectedVideoInput.id;
    const constraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      video: {deviceId: videoSource ? {exact: videoSource} : undefined}
    };
    navigator.mediaDevices.getUserMedia(constraints)
      .then(this.gotStream.bind(this))
      .then(this.gotDevices.bind(this))
      .catch(this.handleError.bind(this));

  }

  changeAudioInput(event: any) {
    console.log(event);
  }

  hideMonitor() {
    this.showMonitor = false;
    if (this.isDirect) {
      this.otrUserService.doLogout();
    }
  }

  exit() {
    this._location.back();
  }

  saveRate() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck('/event-data/rate/' + this.eventId, this.rateData, res => {
        if (!res['isError']) {
          this._location.back();
        } else {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key) && this.rateForm.form.controls[key] !== undefined) {
              if (res[key] != null) {
                this.rateForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.rateForm.form.controls[key].setErrors(null);
              }
            }
          }
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.subscriptionLang) {
      this.subscriptionLang.unsubscribe();
    }

    try {
      this.mediaStream.getTracks().forEach(function (mediaStreamTrack) {
        mediaStreamTrack.stop();
      });
    } catch (e) {
      console.log(e);
    }
    try {
      if (this.conference) {
        this.conference.leave();
      }
    } catch (e) {
      console.log(e);
    }
  }

  isNaN(fill: any) {
    return Number.isNaN(fill);
  }

  connect() {
    console.log('R', 'DISCONNECT TEST STREAM');
    try {
      this.mediaStream.getTracks().forEach(function (mediaStreamTrack) {
        mediaStreamTrack.stop();
      });
    } catch (e) {
      console.log(e);
    }
    try {
      if (this.conference) {
        this.conference.leave();
      }
    } catch (e) {
      console.log(e);
    }
    this.beforeConnect = false;
    this.getUserData();
  }
}
