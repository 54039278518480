import {EventComponent} from './event-component';

export class EventDisplayState {

  participantsVisible = false;
  documentsVisible = false;
  settingsVisible = true;
  chatVisible = true;
  rightPanelVisible = false;
  private _cameraOn = false;
  private _muteOn = false;
  speakingStatus: string;
  micOn = false;
  _maxVolume: any = 50;

  hasCamera = false;
  hasPhyCam = false;

  fit = false;
  // TODO hardware muted
  hardwareMuted: boolean;

  constructor(private component: EventComponent) {
    this.loadState();

  }

  showParticipants() {
    this.participantsVisible = true;
    this.documentsVisible = false;
    this.chatVisible = false;
    this.settingsVisible = false;
    this.rightPanelVisible = true;
    this.saveState();
  }


  get muteOn(): boolean {
    return this._muteOn;
  }

  set muteOn(value: boolean) {
    this._muteOn = value;
  }


  showDocuments() {
    this.participantsVisible = false;
    this.documentsVisible = true;
    this.chatVisible = false;
    this.settingsVisible = false;
    this.rightPanelVisible = true;
    this.saveState();
    this.component.roomStatus.newFileCount = 0;
  }

  showChat() {
    this.participantsVisible = false;
    this.documentsVisible = false;
    this.chatVisible = true;
    this.settingsVisible = false;
    this.rightPanelVisible = true;
    this.saveState();
    this.component.roomStatus.newMessageCount = 0;
  }

  showSettings() {
    this.participantsVisible = false;
    this.documentsVisible = false;
    this.chatVisible = false;
    this.settingsVisible = true;
    this.rightPanelVisible = true;
    this.saveState();
    this.component.roomStatus.newMessageCount = 0;
  }

  closeRightPanel() {
    this.participantsVisible = false;
    this.documentsVisible = false;
    this.chatVisible = false;
    this.settingsVisible = false;
    this.rightPanelVisible = false;
    this.saveState();
  }

  saveState() {
    localStorage.setItem('o-e-m-p.participantsVisible', String(this.participantsVisible));
    localStorage.setItem('o-e-m-p.documentsVisible', String(this.documentsVisible));
    localStorage.setItem('o-e-m-p.chatVisible', String(this.chatVisible));
    localStorage.setItem('o-e-m-p.settingsVisible', String(this.settingsVisible));
    localStorage.setItem('o-e-m-p.rightPanelVisible', String(this.rightPanelVisible));
  }

  loadState() {
    this.participantsVisible = localStorage.getItem('o-e-m-p.participantsVisible') === 'true';
    this.documentsVisible = localStorage.getItem('o-e-m-p.documentsVisible') === 'true';
    this.chatVisible = localStorage.getItem('o-e-m-p.chatVisible') === 'true';
    this.settingsVisible = localStorage.getItem('o-e-m-p.settingsVisible') === 'true';
    this.rightPanelVisible = localStorage.getItem('o-e-m-p.rightPanelVisible') === 'true';
  }

  get maxVolume(): number {
    return this._maxVolume;
  }

  set maxVolume(value: number) {
    this._maxVolume = value;
    if (this.component) {
      this.component.setGains();
    }
  }

  getMaxVolume() {
    return {left: 1.4 * this.maxVolume + 'px'};
  }


  toggleFit() {
    this.fit = !this.fit;
    localStorage.setItem('o-e-m-p.fit', String(this.fit));
    if (document.getElementById('videoSpeakingUser')) {
      if (this.fit) {
        document.getElementById('videoSpeakingUser').setAttribute('style', 'object-fit:cover');
      } else {
        document.getElementById('videoSpeakingUser').setAttribute('style', 'object-fit:contain');
      }
    }
  }

  toggleFitById(videoId: string) {
    this.fit = !this.fit;
    localStorage.setItem('o-e-m-p.fit', String(this.fit));
    if (document.getElementById(videoId)) {
      if (this.fit) {
        document.getElementById(videoId).setAttribute('style', 'object-fit:cover;width:100%;height:100%;');
      } else {
        document.getElementById(videoId).setAttribute('style', 'object-fit:contain;width:100%;height:100%;');
      }
    }
  }
}
