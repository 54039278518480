import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FileUploader} from 'ng2-file-upload';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable} from 'rxjs/Observable';
import {takeWhile} from 'rxjs/operators';
import {EventStreamHandler} from '../event-stream-handler';
import {OtrEventParticipantService} from '../otr-event-participant.service';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {OtrMainJoinEventService} from '../otr-main-join-event.service';
import {OtrUserService} from '../otr-user.service';
import {OtrLangService} from '../otr-lang.service';
import {OtrLogService} from '../otr-log.service';
import {EventComponent} from '../event/event-component';
import {ImageModifyService} from '../image-modify.service';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-otr-conference-monitor-partner',
  templateUrl: './otr-conference-monitor-partner.component.html',
  styleUrls: ['./otr-conference-monitor-partner.component.css'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(300, animateChild()), {optional: true})
      ])
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('10ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('5000ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})


export class OtrConferenceMonitorPartnerComponent extends EventComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeHandler = new EventEmitter();
  @Input()
  eventId: any;

  get maxVolume(): number {
    return this._maxVolume;
  }

  set maxVolume(value: number) {
    this._maxVolume = value;
    this.setGains();
  }

  constructor(
    private otrUserService: OtrUserService,
    private otrEventParticipantService: OtrEventParticipantService,
    private otrMainJoinEventService: OtrMainJoinEventService,
    private otrHttpRequestService: OtrHttpRequestService,
    private spinner: NgxSpinnerService,
    private otrLangService: OtrLangService,
    private otrLogService: OtrLogService,
    private imageModifyService: ImageModifyService,
    public platform: Platform
  ) {
    super(otrHttpRequestService, otrLogService, 'conference', imageModifyService, platform);
    this.otrLangService.getValuesForGroup('partnerWindow', res => {
      this.langStr = res;
    });
  }

  uploader: FileUploader;
  uploadIsProgress = false;
  tooLargeFile = false;

  userId: number;

  publishNumber = 0;
  conference: any;


  showedRemoteStreams = [];
  waitingToSubscribe = [];
  inSubscribe = false;
  inPublish = false;
  myRoom: any = 'conference';

  filterDocuments = false;
  documents: any = [];

  muteOn = false;

  _maxVolume: any = 50;

  speakingStatus: any = '0';

  subscriptions: any = {};
  displayedStreamId: any;
  previousDisplayedStreamId: any;
  displayedInterpreterStreamId: any;

  langStr = {
    gatheringInfo: '',
    interpreter: '',
    sendmessage: '',
    participants: '',
    missing: '',
    present: '',
    documents: '',
    tooLargeFile: '',
    exit: '',
    chat: '',
    organizer: '',
    turnonmic: '',
    currentSpeaker: '',
    presenter: '',
    presenters: ''
  };


  timerSubscription: any;
  rtcConfiguration = {
    iceServers: [],
    iceTransportPolicy: 'relay'
  };

  hasBaseDropZoneOver = false;

  mergeRecursive(obj1, obj2) {
    for (const p in obj2) {
      if (obj2.hasOwnProperty(p)) {
        try {
          if (obj2[p].constructor === Object) {
            obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
          } else if (Array.isArray(obj2[p])) {
            if (obj2[p].length < 1) {
              obj1[p] = obj2[p];
            } else {
              obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
            }
          } else {
            obj1[p] = obj2[p];
          }
        } catch (e) {
          obj1[p] = obj2[p];
        }
      }
    }
    return obj1;
  }

  ngAfterViewInit() {
  }

  eventState() {
    if (this.roomStatus.room.speakingUser === -1) {
      this.speakingStatus = '0';
    } else {
      this.speakingStatus = '1';
    }
    this.roomStatus.room.users.forEach(user => {
      if (user.id !== this.roomStatus.self.id) {
        const subs = this.subscriptions[user.streamId];
        if (subs) {
          if (
            user.enabled &&
            user.lang === this.roomStatus.self.lang &&
            this.roomStatus.interpreter.id === user.id
          ) {
            subs.audio(false);
          } else {
            subs.audio(false);
          }
        }
      }
    });
  }

  ngOnInit() {

    super.eventId = this.eventId;
    this.myRoom = 'conference' + this.eventId;

    this.init();
    this.uploader = new FileUploader({
      url: '/event-files/' + this.eventId,
      removeAfterUpload: true,
      autoUpload: true
    });
    this.uploader.onCompleteAll = () => {
      this.uploadIsProgress = false;
      // send message to refresh files
      if (this.httpMode) {
        this.otrHttpRequestService
          .doGet('/room/' + this.eventId + '/filesChanged')
          .subscribe(
            data => {
              this.roomStatus.update(data);
            },
            err => {
              console.log('err: ' + JSON.stringify(err));
            }
          );
      } else {
        this.roomStatus.filesChanged();
      }
    };
    this.uploader.onProgressAll = (_progress: any) => {
      this.uploadIsProgress = true;
    };
    this.uploader.onErrorItem = (item, response, status, _headers): any => {
      if (status === 413) {
        // túl nagy fájl
        this.tooLargeFile = true;
        setTimeout(() => {
          this.tooLargeFile = false;
        }, 2000);
      }
    };
  }

  // Beszéd gomb kezelő, az aktuális állapotnak megfelelő akciót hajtja végre.
  nextState() {
    if (this.roomStatus.room.state === 1) {
      if (this.roomStatus.room.speakingUser === this.roomStatus.self.id) {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/stopspeaking')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.stopSpeaking();
        }
      } else if (
        this.roomStatus.room.waitingUsers.includes(this.roomStatus.self.id)
      ) {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/unapply')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.unapply();
        }
      } else {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/apply')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.apply();
        }
      }
    }
  }

  // SEND MESSAGE TO OTHERS
  sendText() {
    const inputField = <HTMLInputElement>document.getElementById('chatmsg');
    this.onEnter(inputField.value);
  }

  onEnter(value: string) {
    if (value !== '') {
      if (this.httpMode) {
        this.otrHttpRequestService
          .doPost('/room/' + this.eventId + '/text/', {text: value})
          .subscribe(data => {
            this.roomStatus.update(data);
          });
      } else {
        this.roomStatus.sendText({text: value});
      }
      const inputField = <HTMLInputElement>document.getElementById('chatmsg');
      inputField.value = '';
    }
  }

  setGains() {
   // if (this.roomStatus.room.state === 0) {
      // Gyülekező
      this.roomStatus.room.users.forEach(user => {
        if (user.subscription) {
          const val = ( this.eventDisplayState._maxVolume / 100.0 );
          user.subscription.gain.gain.setValueAtTime(val, user.subscription.gain.context.currentTime);
          if (user.subscription.gain.context.state === 'suspended') {
            user.subscription.gain.context.resume();
          }
        }
      });

    // }
  }

  setLang(lang) {
    if (parseInt('' + this.roomStatus.room.speakingUser, 10) !== -1) {
      console.log('speaking user', this.roomStatus.room.speakingUser);
      this.otrHttpRequestService
        .doGet(
          '/conference/' +
          this.eventId +
          '/lang/' +
          lang +
          '/' +
          this.roomStatus.room.speakingUser
        )
        .subscribe(
          data => {
            this.roomStatus.update(data);
          },
          err => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    } else {
      console.log('first user', this.roomStatus);
      this.otrHttpRequestService
        .doGet(
          '/conference/' +
          this.eventId +
          '/lang/' +
          lang +
          '/' +
          this.roomStatus.room.userTree.id
        )
        .subscribe(
          data => {
            this.roomStatus.update(data);
          },
          err => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    }
  }

  displayStream(stream, resolution, targetDiv, enableAudio) {
    const streamId = stream.id;

    resolution = resolution || {
      width: 320,
      height: 240
    };

    // Elég csak a videó, az audió máshol van kezelve
    this.subscriptions[stream.id].video(true);

    const parent = document.getElementById(targetDiv);
    if (parent === undefined) {
      // alert("undefined " + targetDiv);
    }
    if (parent) {
      let div = document.getElementById('test' + streamId);
      if (!div) {
        div = document.createElement('div');
        div.setAttribute('id', 'test' + streamId);
        div.setAttribute('title', 'Stream#' + streamId);
        parent.appendChild(div);
        const video = document.createElement('video');
        video.setAttribute('width', '100%');
        video.setAttribute('height', '100%');
        video.setAttribute('autoplay', '');
        video.setAttribute('id', 'video' + streamId);
        div.appendChild(video);
      }
      div.setAttribute('style', 'width: 100%; height: 100%;position:absolute');

      const localVideo: HTMLVideoElement = <HTMLVideoElement>(
        document.getElementById('video' + streamId)
      );
      try {
        localVideo.srcObject = stream.mediaStream;
      } catch (error) {
        localVideo.src = URL.createObjectURL(stream.mediaStream);
      }

      if (!enableAudio) {
        // stream.disableAudio();
        // TODO DOOOO
        localVideo.volume = 0;
      }
      // this.showedRemoteStreams.push(stream);
    }
  }

  ngOnDestroy() {
    this.destroy();
    this.otrMainJoinEventService.loadEvents();
  }

  mute() {
    if (this.roomStatus.localStream) {
      this.eventDisplayState.muteOn = true;
      this.roomStatus.localStream.audio(false);
    }
  }

  unmute() {
    if (this.roomStatus.localStream) {
      this.eventDisplayState.muteOn = false;
      this.roomStatus.localStream.audio(true);
    }
  }


  toggleCamera() {
    if (this.roomStatus.video.includes(this.roomStatus.self.id)) {
      this.setAudioVideoEnabled(this.audioEnabled(), !this.videoEnabled());
    } else {
      this.setAudioVideoEnabled(this.audioEnabled(), false);
    }
  }


  toggleMic() {
    this.eventDisplayState.muteOn = !this.eventDisplayState.muteOn;
    this.setAudioVideoState();
    if (this.roomStatus.video.includes(this.roomStatus.self.id)) {
      this.roomStatus.localStream.audio(!this.eventDisplayState.muteOn);
    } else {
      this.eventDisplayState.micOn = false;
      this.roomStatus.localStream.audio(false);
    }

  }

  getMaxVolume() {
    const styles = {left: 1.4 * this.maxVolume + 'px'};
    return styles;
  }

  downloadFile(fileId) {
    const fileItem = this.roomStatus.room.files.find(file => parseInt('' + file.id, 10) === parseInt(fileId, 10));
    if (fileItem) {
      window.open(
        '/event-files/' +
        fileItem.id +
        '/' +
        this.eventId +
        '/' +
        encodeURI(fileItem.name)
      );
    }
  }

  deleteFile(fileId) {
    this.otrHttpRequestService.doDeleteAndCheck(
      '/event-files/' + fileId + '/' + this.eventId,
      res => {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/filesChanged')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.filesChanged();
        }
      }
    );
  }


  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  toggleFilterDocuments() {
    this.filterDocuments = !this.filterDocuments;
    this.fillDocuments();
  }

  fillDocuments() {
    if (this.filterDocuments && this.roomStatus.room.state !== 0) {
      this.documents = [];
      this.roomStatus.room.files.forEach(file => {
        if (file.user === this.roomStatus.room.speakingUser) {
          this.documents.push(file);
        }
      });
    } else {
      this.documents = this.roomStatus.room.files;
    }
  }

  selectSpeaker(userId) {
    if (userId === this.roomStatus.room.speakingUser) {
      this.otrHttpRequestService
        .doGet('/conference/' + this.eventId + '/unpromote')
        .subscribe(
          data => {
            this.roomStatus.update(data);
          },
          err => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    } else {
      this.otrHttpRequestService
        .doGet('/conference/' + this.eventId + '/promote/' + userId)
        .subscribe(
          data => {
            this.roomStatus.update(data);
          },
          err => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    }
  }

  trackByFn(index: number, item: any): any {
    return item.time + item.userId;
  }


  backClicked() {
    this.closeHandler.emit();
  }
}
