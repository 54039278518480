<div class="container-fluid event-monitor darkest-bkg" style="margin-top: 0;">
  <div class="player">
    <div class="partheader">
      <button class="btn btn-danger"
              style="
                    float: left;
                    z-index: 100;
                    position: relative;
                    height: 40px;
                    padding-right: 16px;
                    justify-content: center;
                    display: flex;
                    line-height: 22px;"
              (click)="backClicked()">

        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="currentColor" class="bi bi-door-open"
             viewBox="0 0 16 16">
          <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
          <path
            d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
        </svg>
        {{langStr.exit}}
      </button>
      <div class="room-name">{{roomStatus.room.name}}</div>

      <div ngSwitch="{{roomStatus.room.state}}">
        <button *ngSwitchCase="0" style="float:right" style="float:right;padding-right:7px;font-size:22px;border:0;background-color:transparent;color:green;" (click)="start()"><i class="fa fa-play-circle" aria-hidden="true" ></i></button>
        <button *ngSwitchCase="1" style="float:right" style="float:right;padding-right:7px;font-size:22px;border:0;background-color:transparent;color:red;" (click)="stop()"><i class="fa fa-stop-circle" aria-hidden="true" ></i></button>
      </div>

      <div class="toolar-buttons" style="float:right;margin-right:10px;">

        <div class="participant-toggle" (click)="eventDisplayState.showParticipants()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill"
               viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
            <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
          </svg>
          <span class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-success">
            {{roomStatus.missingUserList.length+roomStatus.userList.length}}/{{roomStatus.userList.length}}
          </span>
        </div>

        <div class="chat-toggle" (click)="eventDisplayState.showChat()" style="cursor:pointer;float:left;margin-right:10px;position: relative">

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat"
               viewBox="0 0 16 16">
            <path
              d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
          </svg>

          <span
            [style.display]="roomStatus.newMessageCount == 0?'none':''"
            class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">{{roomStatus.newMessageCount}}</span>
        </div>

        <div class="files-toggle" (click)="eventDisplayState.showDocuments()" style="cursor:pointer;float:left;margin-right:10px;position: relative">

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files"
               viewBox="0 0 16 16">
            <path
              d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
          </svg>

          <span
            [style.display]="roomStatus.newFileCount == 0?'none':''"
            class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">{{roomStatus.newFileCount}}</span>
        </div>
        <div class="rightPanel-close" *ngIf="eventDisplayState.rightPanelVisible" (click)="eventDisplayState.closeRightPanel()"
             style="cursor:pointer;float:left;margin-right:10px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
               viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>

      </div>

    </div>

    <div [ngClass]="{'video-area':eventDisplayState.rightPanelVisible,'video-area-wide':!eventDisplayState.rightPanelVisible}">

      <button (click)="eventDisplayState.toggleFit()"
              class="btn"
              style="color: white;width: 40px;top: 10px;position: absolute;left: 10px;padding-top:11px;padding-left:8px;padding-right:8px;z-index: 200;background-color: #3b3a39; ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-aspect-ratio"
             viewBox="0 0 16 16">
          <path
            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
          <path
            d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z"/>
        </svg>
      </button>


      <div id="otherUserVideo" class="speaker-video">
        <video id="videoSpeakingUser" autoplay width="100%" height="100%" [volume]="0"></video>
      </div>

      <div class="blackbkg"
           style="width:182px; position: absolute; top: 5px; right: 5px; z-index: 100" title="local medias">
        <div id="myVideo" style="width:182px; height: auto; " title="my camera"
             [style.display]="roomStatus.room.state == 0?'':'none'">
          <video id="localVideo" autoplay playsinline width="100%" height="100%" [volume]="0" style="display: none"></video>
          <canvas [hidden]="!videoEnabled()"  id="canvas-output" width="{{outputWidth}}" height="{{outputHeight}}" style="width: 100%;height: 100%;border: 1px #323131 solid;"></canvas>
        </div>
      </div>


    </div>
    <div class="darkest-bkg" style="display: flex;justify-content: center">
      <div style="position: relative;width: 430px;">

        <div style="padding-top: 24px;width: 165px;position: relative;height: 100px;">
          <div class="slidecontainer" style="position: absolute;width:165px;left:5px;">
            <input type="range" min="0" max="100" [(ngModel)]="eventDisplayState.maxVolume" class="slider" id="volumeSlider" (change)="setGains()">
            <div class="volumedot" [ngStyle]="eventDisplayState.getMaxVolume()">
              {{eventDisplayState.maxVolume}}
            </div>
          </div>
          <button class="btn" style="width:40px;margin-right:5px;top: 65px;position: absolute;left: 5px;padding: 8px;" (click)="toggleCamera()" [ngClass]="{'btn-success':videoEnabled()}"><i class="fa" [ngClass]="{'fa-video-camera':videoEnabled(),'fa-video-slash':!videoEnabled()}" aria-hidden="true" ></i></button>
          <button class="btn" style="width:40px;margin:5px;position: absolute;top: 60px;left: 50px;padding: 8px;" [ngClass]="{'btn-success':eventDisplayState.micOn}" (click)="toggleMic()"><i class="fa" [ngClass]="{'fa-microphone':eventDisplayState.micOn,'fa-microphone-slash':!eventDisplayState.micOn}" ></i></button>
          <button class="btn" style="width:65px;margin-left:5px;position: absolute;left:  100px;top: 65px;padding: 8px;" [ngClass]="{'btn-success':eventDisplayState.muteOn}" (mousedown)="mute()" (mouseup)="unmute()">MUTE</button>
        </div>

        <div style="padding-top: 33px;width:  90px;position:  absolute;top: 4px;left: 180px;">
          <span class="input-group-text" id="speak-timer">{{hoursDisplay}}:{{minutesDisplay}}:{{secondsDisplay}}</span>
        </div>

        <div style="width: 165px;position: absolute; top: 2px; left: 280px;">
          <div class="input-group" placement="top" ngbTooltip="{{langStr.autoshift}}">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input type="checkbox" id="autoChangeOutput" [(ngModel)]="autoChangeOutput">
              </div>
            </div>
            <input type="text" class="form-control" readonly style="text-align:center;" value="AUTO SHIFT">
          </div>
          <div style="display:flex"  >
            <ng-template ngFor let-item [ngForOf]="roomStatus.room.languages" let-i="index">
              <div style="padding-left: 0px;padding-right: 0px;">
                <div class="input-group">
                  <div class="input-group-prepend" style="width:22px;">
                    <span class="input-group-text" style="padding-left:5px;padding-right:5px;">{{i+1}}</span>
                  </div>
                  <div class="input-group-append">
                    <button style="width:62px;" class="btn" id="outputLang{{i}}"
                            [ngClass]="{'btn-light':roomStatus.interpreter.lang!=item,'btn-success':roomStatus.interpreter.lang==item||roomStatus.room.state==0 }" type="button" (click)="setOutputLang(item)">{{item}}</button>
                  </div>
                </div>
              </div>
              <div style="padding-left: 0px;padding-right: 0px;height:38px;" >

              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="rightPanel" [hidden]="!eventDisplayState.rightPanelVisible">
    <div class="participants darkest-bkg" [hidden]="!eventDisplayState.participantsVisible">
      <div class="partsubheader">
        <div>{{langStr.presenter}}</div>
      </div>
      <div @list id="missingUsers"
           style="min-height:100px;max-height:100px;overflow-y:scroll;border-bottom:1px solid #323131">
        <div *ngIf="roomStatus.speakingUserHandler != null">
          <div @items class="chatrow" style="min-height: 50px;color: white;position: relative;">
            <div id="UserMS{{roomStatus.speakingUserHandler.id}}"
                 style="float:left;margin-right: 10px; background-size: cover;width: 40px;height: 40px;background-position: center;  border-radius: 7px;  position: relative;  left: 0;  top: 0;"
                 [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + roomStatus.speakingUserHandler.id + ')'}"
                 class="speaking">
            </div>
            <span class="sender">{{roomStatus.speakingUserHandler.name}}</span>

            <div id="PartnerS{{roomStatus.speakingUserHandler.id}}Lang" style="position: absolute;right: 5px;bottom:10px;">
              <span class="stroke">{{roomStatus.speakingUserHandler.lang}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="partsubheader">
        <div>{{langStr.presenters}}</div>
      </div>
      <div>


        <div id="currentContainer" style="width:100%;height:calc(100vh - 342px);overflow-y: scroll;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.allUserList">
            <div @items class="chatrow" style="position:relative;min-height: 50px;color: white;cursor: pointer;">
              <div id="UserP{{item.id}}"
                   style="float:left;margin-right: 10px; background-size: cover;width: 40px;height: 40px;background-position: center;  border-radius: 7px;  position: relative;  left: 0;  top: 0;"
                   [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}"
                   [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
              </div>
              <span class="sender">{{item.name}}</span>
              <div id="Partner{{item.id}}Lang" style="position: absolute;right: 5px;bottom:10px;">
                <span class="stroke">{{item.lang}}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="documents darkest-bkg" [hidden]="!eventDisplayState.documentsVisible">
      <div>
        <div class="formInnerDiv">
          <div style="border: 1px solid #323131;position: relative;">
            <div class="partheader">
              <div style="float:left">{{langStr.documents}}</div>
              <label class="btn-file-upload" style="float:right;">
                <i class="fa fa-file fa-fw buttonInDropZone"></i>
                <input type="file" name="file" ng2FileSelect [uploader]="fileHandler.uploader" multiple hidden/>
              </label>
            </div>
            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                 [uploader]="fileHandler.uploader" class="well my-drop-zone"
                 style="overflow-y: scroll;  height: calc(100vh - 198px);position: absolute;">
              <ng-template ngFor let-item [ngForOf]="roomStatus.room.files" let-i="index">

                <div class="filerow" style="position:relative;" (click)="fileHandler.downloadFile(item.id)">

                  <i style="position:absolute;right:5px;top:5px;" title="Törlés" class="fa fa-remove fa-fw buttonIcon"
                     aria-hidden="true" (click)="fileHandler.deleteFile(item.id);$event.stopPropagation()"></i>
                  <span class="filename">{{item.name}}</span><br/><span class="fileuploader">{{item.userName}}</span>

                </div>
              </ng-template>
            </div>
            <div class="fileAlertBox" *ngIf="tooLargeFile">
              {{langStr.tooLargeFile}}
            </div>
          </div>
        </div>

      </div>

    </div>
    <div class="chat darkest-bkg" [hidden]="!eventDisplayState.chatVisible">
      <div class="partheader">
        <div>{{langStr.chat}}</div>
      </div>
      <div style="height: 100%">
        <div class="input-group mb-3 darkest-bkg" style="margin-bottom: 0px !important;">
          <input type="text" class="form-control darkest-bkg" id="chatmsg" #chatmsg
                 (keyup.enter)="onEnter(chatmsg.value)">
          <div class="input-group-append">
            <button class="btn btn-secondary darkest-bkg" (click)="sendText()" type="button" placement="auto"
                    ngbTooltip="{{langStr.sendmessage}}"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
          </div>
        </div>
        <div id="chatmsgs" class="darkest-bkg"
             style="overflow:auto;height: calc(100vh - 232px);width:auto;border:1px #323131 solid;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.room.texts" let-i="index">
            <div @items class="chatrow" [ngClass]="{'question':item.isQuestion == true}" >
              <span *ngIf="item.isQuestion" class="sender">{{item.userName}}</span>&nbsp;
              <span *ngIf="!item.isQuestion" class="sender">{{item.user.nickname}}</span>&nbsp;
              <span class="dateTime">{{item.dateTime}}</span><br/><span class="message">{{item.text}}</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
<div class="container-fluid" style="height: inherit; margin-top: 5px;" *ngIf="isFirefox == true ">
  <br/><br/><br/><br/>
  <h1 style="width: 100%; text-align:center;">Firefox not supported yet.</h1>
  <h1 style="width: 100%; text-align:center;">Please use Google Chrome.</h1>
</div>
<div class="container-fluid" style="height: inherit; margin-top: 5px;" *ngIf="isFirefox == false ">


  <div style="width: 450px;float:left;border:1px solid #aaaaaa;height:calc(100vh - 10px)">
    <div class="partheader">
      <div style="float:left;max-width: 390px;max-height: 40px;overflow: hidden;">{{prevStatus.room.name}}</div>
      <div ngSwitch="{{prevStatus.room.state}}">
        <button *ngSwitchCase="0" style="float:right" style="float:right;padding-right:7px;font-size:22px;border:0;background-color:transparent;color:green;" (click)="start()"><i class="fa fa-play-circle" aria-hidden="true" ></i></button>
        <button *ngSwitchCase="1" style="float:right" style="float:right;padding-right:7px;font-size:22px;border:0;background-color:transparent;color:red;" (click)="stop()"><i class="fa fa-stop-circle" aria-hidden="true" ></i></button>
      </div>
    </div>

    <div id="streaminfo" style="pointer-events:none;position:absolute;width:100%;height:100%">
    </div>

    <div style="height:calc(100vh - 180px);position: relative;width: 450px;display: flex;align-items: center;justify-content: center;">
      <div id="otherUserVideo" style="position: absolute;width: 322px; height: 242px;margin:auto;border: 1px #aaaaaa solid; ">
      </div>
      <div id="myVideo" style="position: absolute;right:5px;top:5px;width:122px; height: 92px;  border: 1px #aaaaaa solid;" title="">
        <ngx-spinner
bdOpacity = 0.9
bdColor = "#333333"
size = "large"
color = "#ffffff"
type = "ball-8bits"
[fullScreen] = "false"
>
<p style="color: white" > Loading... </p>
</ngx-spinner>
        <video id="localVideo" autoplay width="100%" height="100%"></video>
      </div>
    </div>
    <div class="" style="border-top:1px solid #aaaaaa;padding-top:10px;position:relative;padding-bottom:16px;background-color:#dddddd">
      <div style="padding-top: 30px;width: 165px;position: relative;height: 100px;">
        <div class="slidecontainer" style="position: absolute;width:165px;left:5px;">
          <input type="range" min="0" max="100" [(ngModel)]="maxVolume" class="slider" id="volumeSlider" (change)="setGains()">
          <div class="volumedot" [ngStyle]="getMaxVolume()">
            {{maxVolume}}
          </div>
        </div>
        <button class="btn" style="width:40px;margin-right:5px;top: 65px;position: absolute;left: 5px;padding: 8px;" (click)="toggleCamera()" [ngClass]="{'btn-danger':cameraOn}"><i class="fa" [ngClass]="{'fa-video-camera':cameraOn,'fa-video-slash':!cameraOn}" aria-hidden="true" ></i></button>
        <button class="btn" style="width:40px;margin:5px;position: absolute;top: 60px;left: 50px;padding: 8px;" [ngClass]="{'btn-danger':micOn}" (click)="toggleMic()"><i class="fa" [ngClass]="{'fa-microphone':micOn,'fa-microphone-slash':!micOn}" ></i></button>
        <button class="btn" style="width:65px;margin-left:5px;position: absolute;left:  100px;top: 65px;padding: 8px;" [ngClass]="{'btn-danger':muteOn}" (mousedown)="mute()" (mouseup)="unmute()">MUTE</button>
      </div>

      <div style="padding-top: 33px;width:  90px;position:  absolute;top: 10px;left: 180px;">
        <span class="input-group-text" id="speak-timer">{{hoursDisplay}}:{{minutesDisplay}}:{{secondsDisplay}}</span>
      </div>

      <div style="width: 165px;position: absolute; top: 8px; left: 280px;">
        <div class="input-group" placement="top" ngbTooltip="{{langStr.autoshift}}">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <input type="checkbox" id="autoChangeOutput" [(ngModel)]="autoChangeOutput">
            </div>
          </div>
          <input type="text" class="form-control" readonly style="text-align:center;" value="AUTO SHIFT">
        </div>
        <div style="display:flex">
          <ng-template ngFor let-item [ngForOf]="prevStatus.room.languages" let-i="index">
            <div style="padding-left: 0px;padding-right: 0px;">
              <div class="input-group">
                <div class="input-group-prepend" style="width:22px;">
                  <span class="input-group-text" style="padding-left:5px;padding-right:5px;">{{i+1}}</span>
                </div>
                <div class="input-group-append">
                  <button style="width:62px;height:37px;" class="btn" id="outputLang{{i}}"
                  [ngClass]="{'btn-light':prevStatus.interpreter.lang!=item,'btn-success':prevStatus.interpreter.lang==item||prevStatus.room.state==0 }"
                  type="button"
                  (click)="setOutputLang(item)">
                    {{item}}
                    <br/>
                    <span style="font-size: 0.7em; position: relative; bottom: 6px;">{{prevStatus.room.languageCounts[i]}}</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div style="margin-left:10px;margin-right:10px;padding:0px; float:left; width: 180px;border:1px solid #aaaaaa;height: calc(100vh - 10px);">
    <div class="partheader">
      <div style="float:left">{{langStr.presenter}}</div>
    </div>
    <div @list id="missingUsers" style="min-height:162px;max-height:162px;overflow-y:scroll;border-bottom:1px solid #aaaaaa">
      <div *ngIf="speakingUserItem != null"  style="position:relative;" @items transition-group-item id="Partner{{speakingUserItem.id}}Container" class="videoContainer">
        <div id="Partner{{speakingUserItem.id}}" style="min-height:140px;background-size: cover;" [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + speakingUserItem.id + ')'}" [ngClass]="'speaking'">
        </div>
        <div id="Partner{{speakingUserItem.id}}Lang" style="position: absolute;left: 10px;top:5px;">
          <span class="stroke">{{speakingUserItem.lang}}</span>
        </div>
        <div id="Partner{{speakingUserItem.id}}Name" style="position: absolute;right: 10px;top:5px;width: 100px;text-align:right;">
          <span class="stroke">{{speakingUserItem.name}}</span>
        </div>
      </div>
    </div>
    <div>
      <div class="partsubheader">
        <div style="float:left">{{langStr.presenters}}</div>
      </div>
      <div @list [transition-group]="'flip-list'" id="currentContainer" style="width:100%;height:calc(100vh - 234px);overflow-y: scroll;">
        <ng-template ngFor let-item [ngForOf]="userList" let-i="index">
          <div style="position:relative;" @items transition-group-item id="Partner{{item.id}}Container" class="videoContainer">
            <div id="Partner{{item.id}}" style="min-height:140px;background-size: cover;" [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}" [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','other':item.state=='O'}">
            </div>
            <div id="Partner{{item.id}}Lang" style="position: absolute;left: 10px;top:5px;">
              <span class="stroke">{{item.lang}}</span>
            </div>
            <div id="Partner{{item.id}}Name" style="position: absolute;right: 10px;top:5px;width: 100px;text-align:right;">
              <span class="stroke">{{item.name}}</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div style="min-width:210px;padding:0px;float:left;width:calc(100vw - 680px);height: calc(100vh - 10px);">
    <div style="min-height:140px;">
      <div class="formInnerDiv">
        <div style="border: 1px solid #aaaaaa;position: relative;height: 180px;">
          <div class="partheader">
            <div style="float:left">{{langStr.documents}}</div>

            <label class="btn-file-upload" style="float:right;">
            <i class="fa fa-file fa-fw buttonInDropZone"></i>
            <input type="file" name="file" ng2FileSelect [uploader]="uploader" multiple hidden/>
            </label>
            <label style="float:right;position:relative;" (click)="toggleFilterDocuments()">
              <i class="fa fa-fw" [ngClass]="{'fa-filter':!filterDocuments,'fa-filter-slash':filterDocuments}"></i>
            </label>
          </div>
          <div ng2FileDrop
               [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
               (fileOver)="fileOverBase($event)"
               [uploader]="uploader"
               class="well my-drop-zone"
               style="overflow-y: scroll;height: 140px;position: absolute;">
            <ng-template ngFor let-item [ngForOf]="documents" let-i="index">

              <div  class="filerow" style="position:relative;" (click)="downloadFile(item.id)">

                  <i style="position:absolute;right:5px;top:5px;" title="Törlés" class="fa fa-remove fa-fw buttonIcon" aria-hidden="true" (click)="deleteFile(item.id);$event.stopPropagation()"></i>
                  <span class="filename">{{item.name}}</span><br/><span class="fileuploader">{{item.userName}}</span>

              </div>
            </ng-template>
          </div>
          <div class="fileAlertBox" *ngIf="tooLargeFile">
            {{langStr.tooLargeFile}}
          </div>
        </div>
      </div>

    </div>

    <div style="min-height:100px;height: calc(100vh - 197px);margin-top: 10px;">
      <div style="height: 100%">
        <div class="input-group mb-3" style="margin-bottom: 0px !important">
          <input type="text" class="form-control" id="chatmsg" #chatmsg (keyup.enter)="onEnter(chatmsg.value)">
          <div class="input-group-append">
            <button class="btn btn-secondary" (click)="sendText()" type="button" placement="auto" ngbTooltip="{{langStr.sendmessage}}"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
          </div>
        </div>
        <div id="chatmsgs" style="overflow:auto;height: calc(100% - 38px);width:auto;border:1px lightgray solid;background: white;">
          <ng-template ngFor let-item [ngForOf]="prevStatus.room.texts" [ngForTrackBy]="trackByFn" let-i="index">
            <div @items class="chatrow"  [ngClass]="{'simpleText':item.isQuestion==false,'questionText':item.isQuestion==true}" >
                <span class="sender">{{item.user.name}}</span>&nbsp;<span class="message">{{item.text}}</span>
            </div>
          </ng-template>
        </div>


      </div>

    </div>

  </div>
</div>
-->
