import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { HTTP } from '@ionic-native/http/ngx';

import {environment} from './../environments/environment';
import {OtrAlertDialogService} from './otr-alert-dialog.service';
import {Platform} from '@ionic/angular';

@Injectable()
export class OtrHttpRequestService {

  constructor(private http: HTTP,
              private httpClient: HttpClient,
              private platform: Platform,
              private otrAlertDialogService: OtrAlertDialogService) {
  }

  doGetAndCheck(url: string, callback: any) {
    this.doGet(url).subscribe(data => {
      this.check(data);
      callback(data);
    }, err => {
      console.log('err: ' + JSON.stringify(err));
    });
  }

  doPostAndCheck(url: string, data: any, callback: any) {
    this.doPost(url, data).subscribe(res => {
      this.check(res);
      callback(res);
    }, err => {
      console.log('err: ' + JSON.stringify(err));
    });
  }


  doPutAndCheck(url: string, data: any, callback: any) {
    this.doPut(url, data).subscribe(res => {
      this.check(res);
      callback(res);
    }, err => {
      console.log('err: ' + JSON.stringify(err));
    });
  }

  doDeleteAndCheck(url: string, callback: any) {
    this.doDelete(url).subscribe(res => {
      this.check(res);
      callback(res);
    }, err => {
      console.log('err: ' + JSON.stringify(err));
    });
  }

  check(data: any) {
    if ((data['isError']) && (data['isNotLoggedin'])) {
      this.doLogout();
    }
    if (data['popMessage']) {
      this.doGetAndCheck('/getLang/dialogStr', (res: any) => {
        this.otrAlertDialogService.open(data['isError'] ? 'error' : 'success', res.rendszer_uzenet, data['popMessage']);
      });
    }
  }

  doGet(url: string): Observable<any> {
    console.log('base url', environment.baseUrl);
    // TODO ionicban is ellenőrizni: this.platform.is('ios') || this.platform.is('android')
    if (this.platform.is('cordova')) {
      return new Observable<any>((observer) => {
        this.http.get(encodeURI(environment.baseUrl + url), '', {}).then(
          res => {
            observer.next(JSON.parse(res.data));
          }
        ).catch(reason => {
          alert('reason' + reason);
          observer.next();
        });
      });
    } else {
      return this.httpClient.get(encodeURI(environment.baseUrl + url), { withCredentials: true });
    }
  }


  doPost(url: string, data: any): Observable<any> {
    if (this.platform.is('cordova')) {
      return new Observable<any>((observer) => {
        this.http.post(environment.baseUrl + url, data, {}).then(
          res => observer.next(JSON.parse(res.data))
        );
      });
    } else {
      return this.httpClient.post(environment.baseUrl + url, data, { withCredentials: true });
    }
  }


  doPostTextResponse(url: string, data: any, timeout = 5000): Observable<any> {
    if (this.platform.is('cordova')) {
      return new Observable<any>((observer) => {
        this.http.post(environment.baseUrl + url, data, {}).then(
          res => {
            console.log('text', res);
            observer.next(res.data);
          }
        );
      });
    } else {
      return new Observable<any>((observer) => {
        const req = new XMLHttpRequest();
        req.timeout = timeout;
        req.onreadystatechange = function () {
          if (req.readyState === 4) {
            if (req.status === 200) {
              observer.next(req.responseText);
            } else {
              observer.error('timeout');
            }
          }
        };
        req.open('POST', url, true);
        req.setRequestHeader('Content-Type', 'application/json');
        req.send(JSON.stringify(data));
      });
      // return this.httpClient.post(environment.baseUrl + url, data, { withCredentials: true });
    }
  }

  doPut(url: string, data: any): Observable<any> {
    if (this.platform.is('cordova')) {

      return new Observable<any>((observer) => {
        this.http.put(environment.baseUrl + url, data, {}).then(
          res => observer.next(JSON.parse(res.data))
        );
      });
    } else {
      return this.httpClient.put(environment.baseUrl + url, data, { withCredentials: true });
    }
  }

  doDelete(url: string): Observable<any> {
    if (this.platform.is('cordova')) {

      return new Observable<any>((observer) => {
        this.http.delete(environment.baseUrl + url, '', {}).then(
          res => observer.next(JSON.parse(res.data))
        );
      });
    } else {
      return this.httpClient.delete(environment.baseUrl + url, { withCredentials: true });
    }
  }

  doLogout() {
    this.doGetAndCheck('/auth/logout', () => {
      window.location.href = 'auth';
    });
  }

}
