import {TransitionGroupItemDirective} from './transition-group-item.directive';
import {AfterViewInit, Component, ContentChildren, Input, QueryList} from '@angular/core';
import {animate, style} from '@angular/animations';


@Component({
  selector: '[transition-group]',
  template: '<ng-content></ng-content>'
})
export class TransitionGroupComponent implements AfterViewInit {
  @Input('transition-group') group;

  @ContentChildren(TransitionGroupItemDirective) items: QueryList<TransitionGroupItemDirective>;

  ngAfterViewInit() {
    setTimeout(() => this.refreshPosition('prevPos'), 0); // save init positions on next 'tick'

    this.items.changes.subscribe(items => {
      items.forEach(item => item.prevPos = item.newPos || item.prevPos);
      items.forEach(this.runCallback);
      this.refreshPosition('newPos');
      items.forEach(item => item.prevPos = item.prevPos || item.newPos); // for new items

      const animatex = () => {
        items.forEach(this.applyTranslation);
      };

      const willMoveSome = items.some((item) => {
        const dx = item.prevPos.left - item.newPos.left;
        const dy = item.prevPos.top - item.newPos.top;
        return dx || dy;
      });

      if (willMoveSome) {
        animatex();
      } else {
        setTimeout(() => { // for removed items
          this.refreshPosition('newPos');
          animatex();
        }, 0);
      }
    });
  }

  runCallback(item: TransitionGroupItemDirective) {
    if (item.moveCallback) {
      item.moveCallback();
    }
  }

  runTransition(item: TransitionGroupItemDirective) {
    if (!item.moved) {
      return;
    }
    const cssClass = this.group + '-move';
    const el = item.el;
    el.classList.add(cssClass);
    el.style.transform = el.style.webkitTransform = el.style.transitionDuration = '';
    el.addEventListener('transitionend', item.moveCallback = (e: any) => {
      if (!e || /transform$/.test(e.propertyName)) {
        el.removeEventListener('transitionend', item.moveCallback);
        item.moveCallback = null;
        el.classList.remove(cssClass);
      }
    });
  }

  refreshPosition(prop: string) {
    this.items.forEach(item => {
      item[prop] = item.el.getBoundingClientRect();
    });
  }

  applyTranslation(item: TransitionGroupItemDirective) {
    item.moved = false;
    const dx = item.prevPos.left - item.newPos.left;
    const dy = item.prevPos.top - item.newPos.top;
    if (dx || dy) {
      item.moved = true;
      animate('2s 1s', style({
        transform: 'translate(' + dx + 'px,' + dy + 'px)'
      }));
    }
  }
}
