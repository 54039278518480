import {Injectable} from '@angular/core';
import {OtrHttpRequestService} from './otr-http-request.service';

@Injectable({
  providedIn: 'root',
})
export class OtrLogService {

  oldLog: any;
  oldError: any;
  oldDebug: any;
  oldInfo: any;
  oldWarn: any;
  eventId = '-no-event-';
  userId = '-no-user-';
  currentLogs: any;

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
    this.currentLogs = new Array();
    this.oldLog = console.log.bind(console);
    this.oldError = console.error;
    this.oldDebug = console.debug;
    this.oldInfo = console.info;
    this.oldWarn = console.warn;

    /*
    console.log = this.log.bind(this);
    console.error = this.error.bind(this);
    console.debug = this.debug.bind(this);
    console.info = this.info.bind(this);
    console.warn = this.warn.bind(this);
*/
  }

  createLogRow(type: string, args) {
    const logRow = {
      type: type,
      time: new Date(),
      userId: this.userId,
      eventId: this.eventId,
      arguments: args
    };
    this.currentLogs.push(logRow);
  }

  sendCurrent(): void {
    const toSend = this.currentLogs;
    this.currentLogs = new Array();
    /*
    this.otrHttpRequestService.doPost('/logs', {data: JSON.stringify(toSend)}).subscribe(res => {
      this.oldLog.apply(console, res);
    }, err => {
      this.oldLog.apply(console, err);
    });

     */
  }

  info(message?: any, ...optionalParams: any[]): void {
    // this.createLogRow('info', JSON.stringify(arguments));
    this.oldInfo.apply(console, arguments);
  }

  log(message?: any): void {
    // this.createLogRow('log', JSON.stringify(arguments));
    this.oldLog.apply(console, arguments);
  }

  error(message?: any, ...optionalParams: any[]): void {

    this.oldError.apply(console, arguments);
    // this.createLogRow('error', JSON.stringify(arguments));
    // this.sendCurrent();
  }

  debug(message?: any, ...optionalParams: any[]): void {
    // this.createLogRow('debug', JSON.stringify(arguments));
    this.oldDebug.apply(console, arguments);
  }

  warn(message?: any, ...optionalParams: any[]): void {
    // this.createLogRow('warn', JSON.stringify(arguments));
    this.oldWarn.apply(console, arguments);
  }
}
