import {ChangeDetectorRef, Component, Input} from '@angular/core';

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './otr-alert-dialog.component.html',
})
export class OtrAlertDialogComponent {
  @Input() public title;
  @Input() public message;

  public constructor(public activeModal: NgbActiveModal, public changeRef: ChangeDetectorRef) {
  }

}
