
<div style="padding: 20px;text-align: center">

    <button *ngFor="let lang of languages" type="button" class="buttonImgFlagLarge" (click)="activeModal.close(lang)">
      <img style="width: 100%; border: 1px #bcbcbc solid;" src="../../assets/images/flags/{{lang}}.svg" alt=""/>
    </button>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
