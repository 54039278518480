import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {OtrUserService} from '../otr-user.service';
import {OtrLangService} from '../otr-lang.service';

import {environment} from '../../environments/environment';
import {Platform} from "@ionic/angular";
import {HTTP} from "@ionic-native/http/ngx";

@Component({
  selector: 'app-otr-ext-home',
  templateUrl: './otr-ext-home.component.html',
  styleUrls: ['./otr-ext-home.component.scss']
})
export class OtrExtHomeComponent implements OnInit {

  isNotLoggedIn = false;
  currentLanguage = 'en';
  isMobile = true;

  texts = {
    'en': {
      aboutUs: 'ABOUT US',
      registration: 'REGISTRATION',
      signIn: 'SIGN IN',
      langIcon: 'hu2.png',
      banner1_big: 'ALL-IN-ONE INTERPRETING APP',
      banner1_small: 'SIMY is a service center app that can handle every process related to interpeting in one place',
      banner1_button: 'CONTACT US!',
      banner2_big: 'About Us',
      banner2_small1: 'SIMY is a platform that enables on-demand or scheduled interpretation services, while significantly simplifying and accelerating the process of requesting quotes and orders.',
      banner2_small2: 'The mobile application and web interface serve both as marketplace and hardware tool during the ordering and delivery process, all in the online space.',
      banner2_feature1: 'Interpreter Marketplace',
      banner2_feature2: 'The whole process is all in one place',
      banner2_feature3: 'You only the app for a live interpreting',
      banner2_button: 'CHECK OUT THE PROCESS!',
      banner3_big: 'The advantages of SIMY',
      banner3_feature1: 'There is no need to bring interpreters or negotiators to the site, as with the internet you can connect from any country, reducing costs significantly.',
      banner3_feature2: 'Your phone with the app is enough to create a broadcast, no special tools are needed.',
      banner3_feature3: 'Multiple parties can join the broadcast.',
      banner3_feature4: 'The ordering process is quick and easy and everything is done in one place.',
      banner4_big: 'CHOOSE:',
      banner4_feature1: 'I AM AN INTERPRETER',
      banner4_feature2: 'I AM A CUSTOMER',
      aszf: 'EULA',
      privacy: 'Privacy Policy',
      nextLang: 'hu',
      questionModal: [
        'Have any questions?',
        'Feel free to contacts us!',
        '',
        'Email: combit@combit.hu',
        'Phone: +36 1 789 3692',
        '',
      ],
      interpreterModal: [
        'Try out our online platform!',
        '',
        'You can manage your orders or start the streaming from here:',
      ],
      userModal: [
        'Try out our app!',
        'Available on Android and IOS:',
      ]
    },
    'hu': {
      aboutUs: 'RÓLUNK',
      registration: 'REGISZTRÁCIÓ',
      signIn: 'BELÉPÉS',
      langIcon: 'eng2.png',
      banner1_big: 'TOLMÁCSOLÁSHOZ MINDEN EGY HELYEN',
      banner1_small: 'A SIMY egy szolgáltató központ, amellyel egy helyen lehet intézni minden a tolmácsolással kapcsolatos folyamatot, illetve a résztvevőknek sem szükséges egy helyen tartózkodni',
      banner1_button: 'LÉPJ VELÜNK KAPCSOLATBA!',
      banner2_big: 'Rólunk',
      banner2_small1: 'A Simy egy olyan platform, amely azonnali vagy időponthoz kötött tolmácsolásközvetítést tesz lehetővé, miközben jelentősen egyszerűsíti és gyorsítja az ajánlatkérés-megrendelés-kifizetés menetét.',
      banner2_small2: 'A mobil alkalmazás és a webes felület a piactér, illetve a hardver funkcióját is betölti a megrendelési és teljesítési folyamat közben, mindezt az online térben.',
      banner2_feature1: 'Tolmács Piactér',
      banner2_feature2: 'Az egész ügymenet egy helyen',
      banner2_feature3: 'Csak az appra van szükség',
      banner2_button: 'NÉZD MEG A FOLYAMATOT!',
      banner3_big: 'A SIMY előnyei',
      banner3_feature1: 'Nem szükséges a tolmácsot vagy a tárgyaló feleket a helyszínre utaztatni, hiszen internet segítségével bármely országokból létrehozható a kapcsolat, ezáltal sokat csökkentve a költségeken.',
      banner3_feature2: 'Elegendő egy telefon az alkalmazással a közvetítés létrehozására, nincs szükség speciális eszközökre.',
      banner3_feature3: 'Több fél is csatlakozhat a közvetítéshez.',
      banner3_feature4: 'A megrendelési folyamat gyors és egyszerű, illetve minden egy helyen történik.',
      banner4_big: 'Válassz:',
      banner4_feature1: 'TOLMÁCS VAGYOK',
      banner4_feature2: 'FELHASZNÁLÓ VAGYOK',
      aszf: 'EULA',
      privacy: 'Privacy Policy',
      nextLang: 'en',
      questionModal: [
        'Kérdésed van?',
        'Lépj kapcsolatba velünk alábbi elérhetőségeinken:',
        '',
        'Email: combit@combit.hu',
        'Phone: +36 1 789 3692',
        '',
      ],
      interpreterModal: [
        'Próbáld ki a webes felületet!',
        'Itt tudod menedzselni megrendeléseid, illetve elindítani a közvetítést.',
      ],
      userModal: [
        'Próbáld ki alkalmazásunkat!',
        'Elérhető Androidon és IOS-en is:',
      ]
    }
  };

  constructor(private router: Router,
              private otrHttpRequestService: OtrHttpRequestService,
              private otrUserService: OtrUserService,
              public otrLangService: OtrLangService,
              private platform: Platform,
              private http: HTTP
  ) {
    this.isMobile = platform.is('mobile');
  }

  ngOnInit() {
    this.platform.ready().then( () => {
      this.otrUserService.getIsLoggedIn(data => {
        if (!data) {
          this.isNotLoggedIn = true;
          // TODO better job document.location.href = environment.baseUrl + environment.extUrl + 'simyapp.html';
          if (this.platform.is('mobile')) {
            this.router.navigate(['/auth']);
          }
        } else {
          this.router.navigate(['/home']);
        }
      });
    });
  }

  changeLang(nextLang: any) {
    this.currentLanguage = nextLang;
  }
}
