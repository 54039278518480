import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OtrCodeLoadService} from '../../otr-code-load.service';
import {Subscription} from 'rxjs/Subscription';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrUserService} from '../../otr-user.service';
import {OtrLangService} from '../../otr-lang.service';
import {OtrConfirmationDialogService} from '../../otr-confirmation-dialog.service';

@Component({
  selector: 'app-otr-inter-profile',
  templateUrl: './otr-inter-profile.component.html'
})
export class OtrInterProfileComponent implements OnInit, OnDestroy {

  @ViewChild('profileForm') profileForm: HTMLFormElement;
  @ViewChild('languagePairsForm') languagePairsForm: HTMLFormElement;
  interpreterProfileData: any = {};
  errMessageData: any = {};
  languagePairs: any = {};
  excludedPeriods: any = {};
  excludedPeriodData: any = {};
  languagePairData: any = {};
  langCode: any = {};
  commissionCode: any = {};
  specializationCode: any = {};
  interpreterId: any = 0;
  isLoggedIn = false;
  maxBirthDate: any;
  minBirthDate: any;
  startBirthDate: any;
  maxNormalDate: any;
  minNormalDate: any;
  interpretingFromStartDate: any;
  interpretingToStartDate: any;
  isNewLangPair = false;
  isModify = false;
  datePickerConfig: any = {};
  langEventDataStr: any = {};
  userId: any;
  subscriptionLang: Subscription;

  constructor(
    private modalService: NgbModal,
    private otrCodeLoadService: OtrCodeLoadService,
    private otrHttpRequestService: OtrHttpRequestService,
    private otrUserService: OtrUserService,
    private otrConfirmationDialogService: OtrConfirmationDialogService,
    private activatedRoute: ActivatedRoute,
    private otrLangService: OtrLangService
  ) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      langEventDataStr => {
        this.otrLangService.getValuesForGroup('interProfileStr',
          res => {
            this.langEventDataStr = res;
          }
        );
      }
    );
    if (activatedRoute.snapshot.params.id) {
      this.userId = activatedRoute.snapshot.params.id;
    } else {
      this.userId = 0;
    }
    this.maxBirthDate = {
      year: new Date().getFullYear() - 18,
      month: 12,
      day: 31
    };
    this.minBirthDate = {
      year: new Date().getFullYear() - 90,
      month: 1,
      day: 1
    };
    this.maxNormalDate = {
      year: new Date().getFullYear() + 10,
      month: 1,
      day: 1
    };
    this.minNormalDate = {year: new Date().getFullYear(), month: 1, day: 1};
    this.datePickerConfig = {
      format: 'YYYY-MM-DD',
      showGoToCurrent: true,
      showMultipleYearsNavigation: true
    };
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  ngOnInit() {
    this.otrLangService.getValuesForGroup('interProfileStr',
      res => {
        this.langEventDataStr = res;
      }
    );
    this.loadData();
  }

  loadData() {
    this.otrHttpRequestService.doGetAndCheck(
      this.userId > 0
        ? '/interpreter-profile/load/admin/' + this.userId
        : '/interpreter-profile/load',
      data => {
        if (!data['isError']) {
          this.isLoggedIn = true;
          this.interpreterProfileData = data['result'];
          if (data['result']['szuletes_date']) {
            this.startBirthDate = {
              year: this.toInteger(data['result']['szuletes_date']['year']),
              month: this.toInteger(data['result']['szuletes_date']['month'])
            };
          }
          if (data['result']['erv_tol']) {
            this.interpretingFromStartDate = {
              year: this.toInteger(data['result']['erv_tol']['year']),
              month: this.toInteger(data['result']['erv_tol']['month'])
            };
          }
          if (data['result']['erv_ig']) {
            this.interpretingToStartDate = {
              year: this.toInteger(data['result']['erv_ig']['year']),
              month: this.toInteger(data['result']['erv_ig']['month'])
            };
          }
          if (data['result']['id']) {
            this.interpreterId = data['result']['id'];
          }
        } else {
          if (!data['isNotLoggedin']) {
            this.isLoggedIn = true;
          }
          this.interpreterId = 0;
          this.excludedPeriods = {};
        }
      }
    );
  }

  openExecludePeriod(content) {
    this.excludedPeriodData = {};
    this.loadExecludePeriods();
    this.modalService.open(content);
  }

  loadExecludePeriods() {
    this.otrHttpRequestService.doGetAndCheck(
      '/interpreter-excludedPeriods',
      data => {
        if (!data['isError']) {
          this.excludedPeriods = data['result'];
        } else {
          this.excludedPeriods = {};
        }
      }
    );
  }

  deleteExcludedPeriod(excludedPeriodId) {
    this.otrHttpRequestService.doDeleteAndCheck(
      '/interpreter-excludedPeriods/' + excludedPeriodId,
      res => {
        this.loadExecludePeriods();
      }
    );
  }

  saveInterProfile() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck(
      this.userId > 0
        ? '/interpreter-profile/admin/' + this.userId
        : '/interpreter-profile',
      this.interpreterProfileData,
      res => {
        if (!res['isError']) {
          if (this.interpreterId === 0) {
            this.interpreterId = res['result']['insertId'];
          }
          this.loadData();
        } else {
          this.errMessageData = res;
          for (const key in res) {
            if (this.profileForm.form.controls[key] !== undefined) {
              if (res[key] != null) {
                this.profileForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.profileForm.form.controls[key].setErrors(null);
              }
            }
          }
          // this.loadData();
        }
      }
    );
  }

  insertExcludedPeriod() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck(
      '/interpreter-excludedPeriods/' + this.interpreterId,
      this.excludedPeriodData,
      res => {
        if (!res['isError']) {
          this.loadExecludePeriods();
        } else {
          this.errMessageData = res;
        }
      }
    );
  }

  loadLanguagePairs() {
    this.otrHttpRequestService.doGetAndCheck(
      this.userId > 0
        ? '/interpreter-languagePairs/admin/' + this.userId
        : '/interpreter-languagePairs',
      data => {
        if (!data['isError']) {
          if (data['result']) {
            this.languagePairs = data['result'];
          } else {
            this.languagePairs = {};
          }
        } else {
          this.languagePairs = {};
        }
      }
    );
  }

  openLanguagePairs(content) {
    this.languagePairData = {};
    this.loadLanguagePairs();
    this.otrCodeLoadService.loadCodesByCodeType('NYELV', data => {
      this.langCode = data;
    });
    this.otrCodeLoadService.loadCodesByCodeType('JUTALEK_KOD', data => {
      this.commissionCode = data;
    });
    this.otrCodeLoadService.loadCodesByCodeType('SZAKTERULET', data => {
      this.specializationCode = data;
    });
    this.modalService.open(content, {size: 'lg'});
  }

  deleteLanguagePairs(languagePairsId) {
    this.otrLangService.getValuesForGroup('dialogStr', res => {
      this.otrConfirmationDialogService
        .confirm(res.rendszer_uzenet, res.biztosan_torolni_akarja)
        .then(reqConf => {
          if (reqConf) {
            this.otrHttpRequestService.doDeleteAndCheck(
              '/interpreter-languagePairs/' + languagePairsId,
              res => {
                this.loadLanguagePairs();
              }
            );
          }
        });
    });
  }

  insertLanguagePairs(languagePairsId) {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck(
      '/interpreter-languagePairs/' +
      this.interpreterId +
      (languagePairsId ? '/' + languagePairsId : ''),
      this.languagePairData,
      res => {
        if (!res['isError']) {
          this.isNewLangPair = false;
          this.languagePairData = {};
          this.loadLanguagePairs();
        } else {
          this.errMessageData = res;
          for (const key in res) {
            if (this.languagePairsForm.form.controls[key] !== undefined) {
              if (res[key] != null) {
                this.languagePairsForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.languagePairsForm.form.controls[key].setErrors(null);
              }
            }
          }
        }
      }
    );
  }

  setCommission(languagePairsId, commissionType) {
    this.otrHttpRequestService.doPutAndCheck(
      '/interpreter-languagePairs/' +
      this.interpreterId +
      '/' +
      languagePairsId +
      '/' +
      commissionType,
      {},
      res => {
        this.loadLanguagePairs();
      }
    );
  }

  loadLanguagePairForModify(languagePairsId) {
    this.errMessageData = {};
    this.otrHttpRequestService.doGetAndCheck(
      this.userId > 0
        ? '/interpreter-languagePairs/admin/' +
        this.userId +
        '/' +
        languagePairsId
        : '/interpreter-languagePairs/' + languagePairsId,
      data => {
        if (!data['isError'] && data['result']) {
          this.languagePairData = data['result'][0];
          this.isNewLangPair = true;
          this.isModify = true;
        } else {
          this.languagePairData = {};
          this.isNewLangPair = false;
          this.isModify = false;
        }
      }
    );
  }

  newLangPair() {
    this.otrUserService.getIsLoggedIn(data => {
      if (data) {
        this.languagePairData = {};
        this.isNewLangPair = true;
        this.isModify = false;
      } else {
        this.otrUserService.doLogout();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }

}
