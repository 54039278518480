import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';

import {Location} from '@angular/common';
import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';
import {OtrUserService} from '../../otr-user.service';
import {OtrEventParticipantService} from '../../otr-event-participant.service';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrMainJoinEventService} from '../../otr-main-join-event.service';

import {Observable, Subscription} from 'rxjs-compat';

import {FileUploader} from 'ng2-file-upload';
import {EventStreamHandler} from '../../event-stream-handler';
import {NgxSpinnerService} from 'ngx-spinner';
import {EventComponent} from '../../event/event-component';
import {OtrLangService} from '../../otr-lang.service';
import {OtrLogService} from '../../otr-log.service';
import {ImageModifyService} from '../../image-modify.service';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'app-otr-event-monitor-interpreter',
  templateUrl: './otr-event-monitor-interpreter.component.html',
  styleUrls: ['./otr-event-monitor-interpreter.component.css'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('300ms ease-in', style({transform: 'translateX(0%)'})),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({transform: 'translateX(100%)'})),
      ]),
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(300, animateChild()), {optional: true}),
      ]),
    ]),
  ],
})
export class OtrEventMonitorInterpreterComponent extends EventComponent implements OnInit, OnDestroy {


  constructor(
    private otrUserService: OtrUserService,
    private otrEventParticipantService: OtrEventParticipantService,
    private otrHttpRequestService: OtrHttpRequestService,
    private otrMainJoinEventService: OtrMainJoinEventService,
    private otrLangService: OtrLangService,
    private otrLogService: OtrLogService,
    private spinner: NgxSpinnerService,
    private _location: Location,
    private imageModifyService: ImageModifyService,
    public platform: Platform) {
    super(otrHttpRequestService, otrLogService, 'room', imageModifyService, platform);
    this.otrLangService.getValuesForGroup(
      'interpreterWindow',
      (res) => {
        this.langStr = res;
      }
    );
    // TODO ??? this.roomStatus.room.roomId = this.eventId;
  }

  uploadIsProgress = false;
  tooLargeFile = false;
  publishNumber = 0;
  conference: any;

  inPublish = false;
  hasCamera = true;

  langStr = {
    autonext: '',
    autoshift: '',
    sendmessage: '',
    participants: '',
    chat: '',
    missing: '',
    present: '',
    documents: '',
    tooLargeFile: '',
    organizer: '',
    exit: '',
    settings: '',
  };

  @Input()
  eventId: any;

  myRoom: any = 'conference';

  timerStart: any;
  minutesDisplay: any = '00';
  hoursDisplay: any = '00';
  secondsDisplay: any = '00';
  ticks = 0;
  interpretingTimer: Subscription;


  autoChangeInput = true;
  autoChangeOutput = false;

  displayedStreamId: any = -1;
  rtcConfiguration = {
    iceServers: [],
    iceTransportPolicy: 'relay',
  };


  demoCounter = 0;
  notInDemo = true;

  hasBaseDropZoneOver = false;

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (event.target['tagName'] === 'INPUT' && event.target['id'] === 'chatmsg') {
      return;
    }

    if (event.code === 'KeyM') {
      this.toggleMic();
      event.preventDefault();
    }

    if (event.code === 'KeyN') {
      if (!this.autoChangeInput && (this.roomStatus.room.state === 1 &&
        this.roomStatus.room.speakingUser === -1 && this.roomStatus.room.waitingUsers.length > 0)) {
        this.nextState();
      }
      event.preventDefault();
    }

    if (event.code === 'Space') {
      this.unmute();
      event.preventDefault();
    }
    if (event.code === 'KeyC') {
      if ( !this.autoChangeOutput && this.roomStatus && this.roomStatus.interpreter &&
        this.roomStatus.room && this.roomStatus.room.state === 1 && this.roomStatus.room.languages) {
        let langIndex = this.roomStatus.room.languages.indexOf(this.roomStatus.interpreter.lang);
        if (langIndex !== -1) {
          langIndex ++;
          if (langIndex >= this.roomStatus.room.languages.length) {
            langIndex = 0;
          }
          this.setOutputLang(this.roomStatus.room.languages[langIndex]);
        }
      }
      event.preventDefault();
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {


    if (event.target['tagName'] === 'INPUT' && event.target['id'] === 'chatmsg') {
      return;
    }

    if (event.code === 'ArrowDown') {
      this.maxVolume -= 10;
      if (this.maxVolume < 0) {
        this.maxVolume = 0;
      }
      this.setGains();
      event.preventDefault();
    }
    if (event.code === 'ArrowUp') {
      this.maxVolume += 10;
      if (this.maxVolume > 100) {
        this.maxVolume = 100;
      }
      this.setGains();
      event.preventDefault();
    }

    if (event.code === 'Space') {
      this.mute();
      event.preventDefault();
    }
  }

  onRoomStatusUpdate(): void {
    if (this.roomStatus.show !== -1 && this.roomStatus.show !== undefined) {
      this.roomStatus.displayUserStreamById(this.roomStatus.show, 'videoSpeakingUser');
    }
  }

  eventStateHandler() {

    this.roomStatus.setAllStream(
      (user) => user.id === this.roomStatus.room.speakingUser,
      (user) => user.id === this.roomStatus.room.speakingUser
    );
    if (this.autoChangeInput) {
      if (
        this.roomStatus.room.speakingUser === -1 &&
        this.roomStatus.room.waitingUsers.length > 0
      ) {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/accept')
            .subscribe((data) => {
              this.roomStatus.update(data);
            });
        } else {
          this.roomStatus.accept();
        }
      }
    }
    if (this.autoChangeOutput && this.notInDemo) {
      if (
        this.roomStatus.room.speakingUser !== -1 &&
        this.roomStatus.room.speakingUser !== undefined
      ) {
        const user = this.roomStatus.room.users.find(
          (usr) => usr.id === this.roomStatus.room.speakingUser
        );
        if (this.roomStatus.interpreter.lang === user.lang) {
          const idx = this.roomStatus.room.languages.indexOf(user.lang);
          const lang = this.roomStatus.room.languages[(idx + 1) % 2];
          if (this.httpMode) {
            this.otrHttpRequestService
              .doGet('/room/' + this.eventId + '/lang/' + lang)
              .subscribe(
                (data) => {
                  this.roomStatus.update(data);
                },
                (err) => {
                  console.log('err: ' + JSON.stringify(err));
                }
              );
          } else {
            this.roomStatus.setLang(lang);
          }
        }
      }
    }
  }

  gatheringStateHandler() {
    if (this.roomStatus.localStream) {
      // TODO rommstatus állítja this.roomStatus.localStream.video(this.eventDisplayState.cameraOn);
    }
    this.roomStatus.enableAllStream();
  }

  nextState() {
    if (
      this.roomStatus.room.state === 1 &&
      this.roomStatus.room.waitingUsers.length > 0
    ) {
      if (this.httpMode) {
        this.otrHttpRequestService
          .doGet('/room/' + this.eventId + '/accept')
          .subscribe(
            (data) => {
              this.roomStatus.update(data);
            },
            (err) => {
              console.log('err: ' + JSON.stringify(err));
            }
          );
      } else {
        this.roomStatus.accept();
      }
    }
  }

  demoLang() {
    if (this.demoCounter < 6) {
      this.notInDemo = false;
      this.demoCounter++;
      setTimeout(() => {
        if (this.httpMode) {
          this.otrHttpRequestService
            .doGet('/room/' + this.eventId + '/lang/' + this.roomStatus.room.languages[this.demoCounter % 2])
            .subscribe((data) => {
              this.roomStatus.update(data);
              this.demoLang();
            }, (err) => {
              console.log('err: ' + JSON.stringify(err));
            });
        } else {
          this.roomStatus.setLang(this.roomStatus.room.languages[this.demoCounter % 2]);
        }
      }, 100);
    } else {
      this.notInDemo = true;
    }

  }

  start() {
    if (this.httpMode) {
      this.otrHttpRequestService
        .doGet('/room/' + this.eventId + '/start')
        .subscribe(
          (data) => {
            this.roomStatus.update(data);
            this.demoCounter = 0;
            this.demoLang();
          },
          (err) => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    } else {
      this.roomStatus.start();
    }
    this.otrHttpRequestService.doPostAndCheck(
      '/event-data/finish/' + this.eventId,
      {},
      (data) => {
      }
    );
  }

  stop() {
    if (this.httpMode) {
      this.otrHttpRequestService
        .doGet('/room/' + this.eventId + '/stop')
        .subscribe(
          (data) => {
            this.roomStatus.update(data);
          },
          (err) => {
            console.log('err: ' + JSON.stringify(err));
          }
        );
    } else {
      this.roomStatus.stop();
    }
  }

  sendText() {
    const inputField = <HTMLInputElement>document.getElementById('chatmsg');
    this.onEnter(inputField.value);
  }

  onEnter(value: string) {
    if (value !== '') {
      if (this.httpMode) {
        this.otrHttpRequestService
          .doPost('/room/' + this.eventId + '/text', {text: value})
          .subscribe(
            (data) => {
              this.roomStatus.update(data);
            },
            (err) => {
              console.log('err: ' + JSON.stringify(err));
            }
          );
      } else {
        this.roomStatus.sendText({text: value});
      }
      const inputField = <HTMLInputElement>document.getElementById('chatmsg');
      inputField.value = '';
    }
  }

  setOutputLang(lang) {
    if (this.roomStatus.room.state !== 0 && !this.autoChangeOutput) {
      if (this.httpMode) {
        this.otrHttpRequestService
          .doGet('/room/' + this.eventId + '/lang/' + lang)
          .subscribe(
            (data) => {
              this.roomStatus.update(data);
            },
            (err) => {
              console.log('err: ' + JSON.stringify(err));
            }
          );
      } else {
        this.roomStatus.setLang(lang);
      }
    }
  }


  audioUserStreamById(displayUser) {
    const speakingUser = this.roomStatus.room.users.find(
      (user) => user.id === displayUser
    );
    if (speakingUser) {
      if (this.displayedStreamId !== speakingUser.streamId) {
        if (this.conference) {
          const conferenceInfo = this.conference.info;
          if (conferenceInfo) {
            const stream = conferenceInfo.remoteStreams.find(
              (str) => str.id === speakingUser.streamId
            );
            if (stream !== undefined && speakingUser.subscription !== undefined) {
              const sidiv = document.getElementById(
                'test' + this.displayedStreamId
              );
              if (sidiv) {
                sidiv.remove();
              }
              this.displayedStreamId = speakingUser.streamId;

              const streamId = stream.id;

              // Elég csak a videó, az audió máshol van kezelve
              speakingUser.subscription.audio(true);

              const parent = document.getElementById('otherUserVideo');
              if (parent) {
                let div = document.getElementById('test' + streamId);
                if (!div) {
                  div = document.createElement('div');
                  div.setAttribute('id', 'test' + streamId);
                  div.setAttribute('title', 'Stream#' + streamId);
                  parent.appendChild(div);
                  const video = document.createElement('audio');
                  video.setAttribute('width', '100%');
                  video.setAttribute('height', '100%');
                  video.setAttribute('autoplay', '');
                  video.setAttribute('id', 'audio' + streamId);
                  div.appendChild(video);
                }

                const localVideo: HTMLAudioElement = <HTMLAudioElement>(
                  document.getElementById('audio' + streamId)
                );
                try {
                  localVideo.srcObject = stream.mediaStream;
                } catch (error) {
                  localVideo.src = URL.createObjectURL(stream.mediaStream);
                }

                localVideo.volume = 0;
              }
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    const me = this;
    this.myRoom = 'conference' + this.eventId;
    this.init();
  }

  setShowMuteMessage(show: boolean): void {
    // this.showMuteMessage = show;
  }

  createToken(room, userName, role, callback) {
    const req = new XMLHttpRequest();
    const url = '/createTokenByName/';
    const body = {
      room: room,
      username: userName,
      role: role,
    };
    const me = this;
    req.onreadystatechange = function () {
      if (req.readyState === 4) {
        callback.call(me, req.responseText);
      }
    };
    req.open('POST', url, true);
    req.setRequestHeader('Content-Type', 'application/json');
    req.send(JSON.stringify(body));
  }

  mute() {
    if (this.roomStatus.localStream && !this.eventDisplayState.muteOn) {
      console.log("mute on keyboard");
      this.eventDisplayState.muteOn = true;
      this.roomStatus.localStream.audio(false);
    }
  }

  unmute() {
    if (this.roomStatus.localStream && this.eventDisplayState.muteOn) {
      this.eventDisplayState.muteOn = false;
      this.roomStatus.localStream.audio(this.eventDisplayState.micOn);
    }
  }

  toggleMic() {
    if (this.roomStatus.localStream) {
      if (this.eventDisplayState.micOn) {
        this.eventDisplayState.micOn = false;
        if (this.interpretingTimer) {
          this.interpretingTimer.unsubscribe();
        }
      } else {
        this.eventDisplayState.micOn = true;
        const timer = Observable.timer(1, 1000);
        this.interpretingTimer = timer.subscribe((t) => {
          this.ticks = t;

          this.secondsDisplay = ('' + (this.ticks % 60)).padStart(2, '0');
          this.minutesDisplay = ('' + (Math.floor(this.ticks / 60) % 60)).padStart(2, '0');
          this.hoursDisplay = ('' + (Math.floor(this.ticks / 60 / 60))).padStart(2, '0');
        });
      }
      this.roomStatus.localStream.audio(this.eventDisplayState.micOn);
      this.setAudioVideoEnabled(this.eventDisplayState.micOn, this.videoEnabled());
    }
  }

  toggleCamera() {
    if (this.roomStatus.localStream && this.roomStatus.room.state === 0) {
      this.setAudioVideoEnabled(this.audioEnabled(), !this.videoEnabled());
    }

  }

  backClicked() {
    this._location.back();
  }


  ngOnDestroy() {
    this.destroy();

    if (this.conference) {
      this.conference.leave();
    }
    this.otrMainJoinEventService.loadEvents();
  }

}
