import {ConfirmationDialogComponent} from './confirmation-dialog/confirmation-dialog.component';
import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class OtrConfirmationDialogService {

  constructor(private modalService: NgbModal) {
  }

  public confirm(inTitle, inMessage) {
    const modalRef = this.modalService.open(ConfirmationDialogComponent);
    modalRef.componentInstance.title = inTitle;
    modalRef.componentInstance.message = inMessage;
    modalRef.componentInstance.changeRef.markForCheck();
    return modalRef.result;
  }
}
