<ng-template #ratingTemplate let-fill="fill">
  <span *ngIf="fill === 100" class="star full">&#9733;</span>
  <span *ngIf="fill === 0 || isNaN(fill)" class="star">&#9733;</span>
  <span *ngIf="fill < 100 && fill > 0" class="star">
    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
  </span>
</ng-template>
<div class="simypanel panel panel-primary">

  <div *ngIf="mode == 'X'" class="darkest-bkg"
       style="display: flex; width: 100vw; height: 100vh;align-items: center;justify-content: center; flex-direction: column">


    <div>
      <div style="position: relative; height: 100px">
        <a>
          <img style="width: 200px;" src="../../assets/images/logo_feher_horizont.png">
        </a>
        <div class="menuListDiv flagMenuListDiv" style="position: absolute;top:45px;;width:100%;text-align: right;">
          <button type="button" class="buttonImgFlag" (click)="selectLang()">
            <img *ngIf="currentLang != null && currentLang != ''" class="imgInButton" src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
          </button>
        </div>
      </div>
    </div>

    <div class="card dark-bkg">


      <form (ngSubmit)="beforeConnect = false" novalidate>

        <div class="preview">
        <div *ngIf="videoPreview" style="margin:10px;">
          <div class="test" style="width: 100%">
            <div class='test_error' [ngClass]="{'test_ok':statuses.network.server,'test_error':!statuses.network.server}">S</div>
            <div class='test_ok' [ngClass]="{'test_ok':statuses.network.websocket,'test_error':!statuses.network.websocket}">W</div>
            <div [ngClass]="{'test_ok':statuses.camera,'test_error':!statuses.camera}">C</div>
            <div [ngClass]="{'test_ok':statuses.stream.room,'test_error':!statuses.stream.room}">R</div>
            <div [ngClass]="{'test_ok':statuses.stream.input.audio,'test_error':!statuses.stream.input.audio}">A</div>
            <div [ngClass]="{'test_ok':statuses.stream.input.video,'test_error':!statuses.stream.input.video}">V</div>

            <div [ngClass]="{'test_ok':statuses.stream.publish,'test_error':!statuses.stream.publish}">P</div>
            <div [ngClass]="{'test_ok':statuses.stream.subscribe,'test_error':!statuses.stream.subscribe}">S</div>
            <div [ngClass]="{'test_ok':statuses.stream.output.audio,'test_error':!statuses.stream.output.audio}">A</div>
            <div [ngClass]="{'test_ok':statuses.stream.output.video,'test_error':!statuses.stream.output.video}">V</div>
            <div>{{statuses.availableRid}}</div>
          </div>
          <video style="width: 300px; height: auto;" id="testLocalVideo" playsinline autoplay muted > </video>

          <div style="text-align: center">
            {{previewMessage}}
          </div>
        </div>
          <div>
            <div class="card-title" style="background-color: rgb(59, 58, 57);">{{eventDataStr.esemeny_adatai}}</div>
            <div class="form-content" style="padding-left: 15px;padding-right: 15px">


              <div class="form-group">
                <label>{{eventDataStr.esemeny_neve}}</label>
                <input class="form-control  input-lg" disabled value="{{eventData.esemeny_neve}}" >
              </div>
              <div class="form-group">
                <label>{{eventDataStr.kezdo_idopont}}</label>
                <input class="form-control  input-lg" disabled value="{{ eventData.esemeny_kezdete  | simyDate: currentLang }}" >
              </div>


            </div>
            <div [hidden]="!allowToConnect" *ngIf="currentStatusStr === ''" class="form-group" style="text-align: right">
              <button type="submit" (click)="connect()" class="button">{{eventQueryStr.csatlakozas}}</button>
            </div>

            <div *ngIf="currentStatusStr !== ''" class="form-group" style="padding: 15px">
              {{currentStatusStr}}
            </div>
          </div>

        </div>


      </form>

    </div>

  </div>

  <div *ngIf="eventMode === 'EVENT'">
    <div *ngIf="mode == 'U' && showMonitor">
      <app-otr-event-monitor-partner eventId="{{eventId}}" (closeHandler)="this.hideMonitor()"></app-otr-event-monitor-partner>
    </div>
    <div *ngIf="mode == 'U' && !showMonitor">
      <div  class="darkest-bkg" style="padding: 15px; height: 100vh;display:flex;align-items: center;justify-content: center">
        <div class="card dark-bkg" style="max-width: 300px;">

          <div class="card-title" style="background-color: rgb(59, 58, 57);">{{eventDataStr.ertekeles}}</div>

          <form #rateForm="ngForm" novalidate>
            <div class="form-content" style="padding-left: 15px;padding-right: 15px">
              <div style="text-align: center;height: 50px">
                <ngb-rating [(rate)]="rateData.rating1" [starTemplate]="ratingTemplate"
                             max="5"></ngb-rating><br/>
                <span style="color: red">{{ errMessageData.rating1 }}</span>
              </div>
              <div class="form-group">
                <label>{{eventDataStr.velemeny}}</label>
                <textarea rows="3" cols="30" class="form-control  input-lg"
                          style="background-color: rgb(59, 58, 57);color: white;"
                          [disabled]="isRateIsDone" [(ngModel)]="rateData.velemeny1"
                          name="velemeny1"
                          placement="top" ngbTooltip="{{ errMessageData.velemeny1 }}"></textarea>
              </div>
            </div>
            <div *ngIf="!isRateIsDone" class="form-group" style="text-align: right">
              <button (click)="exit()" class="button">{{eventDataStr.tovabb}}</button>
              <button (click)="saveRate()" class="button">{{eventDataStr.ertekeles}}</button>
            </div>
          </form>

        </div>
      </div>
    </div>
    <div *ngIf="mode == 'T' && showMonitor">
      <app-otr-event-monitor-interpreter eventId="{{eventId}}"></app-otr-event-monitor-interpreter>
    </div>
    <div *ngIf="mode == 'C' && showMonitor">
      <app-otr-conference-monitor-interpreter eventId="{{eventId}}"></app-otr-conference-monitor-interpreter>
    </div>
    <div *ngIf="isDirect && showMonitor && !beforeConnect">
      <app-otr-event-monitor-partner eventId="{{eventId}}" (closeHandler)="this.hideMonitor()"></app-otr-event-monitor-partner>
    </div>

    <div *ngIf="isDirect && showMonitor && beforeConnect">
      <div class="darkest-bkg"
           style="display: flex; width: 100vw; height: 100vh;align-items: center;justify-content: center; flex-direction: column">
        <div>
          <div style="position: relative; height: 100px">
            <a>
              <img style="width: 200px;" src="../../assets/images/logo_feher_horizont.png">
            </a>
            <div class="menuListDiv flagMenuListDiv" style="position: absolute;top:45px;;width:100%;text-align: right;">
              <button type="button" class="buttonImgFlag" (click)="selectLang()">
                <img *ngIf="currentLang != null && currentLang != ''" class="imgInButton" src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
              </button>
            </div>
          </div>
        </div>

        <div class="card dark-bkg" style="width: 300px;">

          <div class="card-title" style="background-color: rgb(59, 58, 57);">{{eventDataStr.esemeny_adatai}}</div>

          <form novalidate>
            <div class="form-content" style="padding-left: 15px;padding-right: 15px">

              <div class="form-group">
                <label>{{eventDataStr.esemeny_neve}}</label>
                <input class="form-control  input-lg" disabled value="{{eventData.esemeny_neve}}" >
              </div>
              <div class="form-group">
                <label>{{eventDataStr.kezdo_idopont}}</label>
                <input class="form-control  input-lg" disabled value="{{ eventData.esemeny_kezdete  | simyDate: currentLang }}" >
              </div>


            </div>
            <div *ngIf="currentStatusStr === ''" class="form-group" style="text-align: right">
              <button (click)="connect()" type="button" class="button">{{eventQueryStr.csatlakozas}}</button>
            </div>

            <div *ngIf="currentStatusStr !== ''" class="form-group" style="padding: 15px">
              {{currentStatusStr}}
            </div>
          </form>

        </div>

      </div>
    </div>

  </div>
  <div *ngIf="eventMode === 'CONFERENCE'">
    <div class="no-remote-users">
      <span>Wrong link</span>
    </div>
  </div>
</div>
