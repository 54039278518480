<ng-template #excludedPeriodsDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Kizárt időszakok</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
      <tr>
        <th>Kezdete</th>
        <th>Vége</th>
        <th>Tól-</th>
        <th>-Ig</th>
        <th>Ismétlődés (hh:mm)</th>
        <th>Törlés</th>
      </tr>
      </thead>
      <tbody *ngIf="excludedPeriods.length > 0">
      <tr *ngFor="let excludedPeriod of excludedPeriods">
        <td>{{ excludedPeriod.idoszak_tol }}</td>
        <td>{{ excludedPeriod.idoszak_ig }}</td>
        <td>{{ excludedPeriod.ido_tol }}</td>
        <td>{{ excludedPeriod.ido_ig }}</td>
        <td>{{ excludedPeriod.gyakorisag }}</td>
        <td>
          <button type="button" class="btn btn-lg btn-outline-primary"
                  (click)="deleteExcludedPeriod(excludedPeriod.id)">Törlés
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-md-10">
        <form (ngSubmit)="insertExcludedPeriod(interpreterProfileData.id)" #excludedPeriodForm="ngForm" novalidate>
          <div class="form-group">
            <div class="input-group">
              <label for="name">Kezdete</label>
              <input type="text" class="form-control" placeholder="yyyy-mm-dd"
                     [(ngModel)]="excludedPeriodData.idoszak_tol" id="idoszak_tol" name="idoszak_tol" autocomplete="off"
                     ngbDatepicker #dft="ngbDatepicker" [minDate]="minNormalDate" [maxDate]="maxNormalDate">
              <div *ngIf="errMessageData.idoszak_tol" class="alert alert-danger">
                <div *ngIf="errMessageData.idoszak_tol">
                  {{ errMessageData.idoszak_tol }}
                </div>
              </div>
              <div class="input-group-append" style="margin-right:15px;">
                <button class="btn btn-outline-secondary" (click)="dft.toggle()" type="button">
                  <img src="../../../assets/images/calendar-icon.svg"
                       style="width: 1.2rem; height: 1rem; cursor: pointer;"/>
                </button>
              </div>
              <label for="name">Vége</label>
              <input type="text" class="form-control" placeholder="yyyy-mm-dd"
                     [(ngModel)]="excludedPeriodData.idoszak_ig" id="idoszak_ig" name="idoszak_ig" autocomplete="off"
                     ngbDatepicker #dtt="ngbDatepicker" [minDate]="minNormalDate" [maxDate]="maxNormalDate">
              <div *ngIf="errMessageData.idoszak_ig" class="alert alert-danger">
                <div *ngIf="errMessageData.idoszak_ig">
                  {{ errMessageData.idoszak_ig }}
                </div>
              </div>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="dtt.toggle()" type="button">
                  <img src="../../../assets/images/calendar-icon.svg"
                       style="width: 1.2rem; height: 1rem; cursor: pointer;"/>
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <label for="name">Tól</label>
              <input type="text" class="form-control" [(ngModel)]="excludedPeriodData.ido_tol" name="ido_tol">
              <div *ngIf="errMessageData.ido_tol" class="alert alert-danger">
                <div *ngIf="errMessageData.ido_tol">
                  {{ errMessageData.ido_tol }}
                </div>
              </div>
              <label for="name">Ig</label>
              <input type="text" class="form-control" [(ngModel)]="excludedPeriodData.ido_ig" name="ido_ig">
              <div *ngIf="errMessageData.ido_ig" class="alert alert-danger">
                <div *ngIf="errMessageData.ido_ig">
                  {{ errMessageData.ido_ig }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="name">Ismétlődés</label>
            <input type="text" class="form-control" [(ngModel)]="excludedPeriodData.gyakorisag" name="gyakorisag">
            <div *ngIf="errMessageData.gyakorisag" class="alert alert-danger">
              <div *ngIf="errMessageData.gyakorisag">
                {{ errMessageData.gyakorisag }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <button type="submit" class="btn btn-success">Mentés</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
  </div>
</ng-template>

<ng-template #languagePairsDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">{{langEventDataStr.nyelvparok}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="row animate formExtDiv">
        <div class="formInnerDiv">
          <div class="card">
            <div class="card-block">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>{{langEventDataStr.melyik_nyelvrol}}</th>
                  <th>{{langEventDataStr.melyik_nyelvre}}</th>
                  <th>{{langEventDataStr.szakterulet}}</th>
                  <th>{{langEventDataStr.oradij_brutto}}</th>
                  <th *ngIf="userId != 0">{{langEventDataStr.jutalek}}</th>
                  <th>{{langEventDataStr.muveletek}}</th>
                </tr>
                </thead>
                <tbody *ngIf="languagePairs.length > 0">
                <tr *ngFor="let languagePair of languagePairs">
                  <td>{{ languagePair.nyelv_1 }}</td>
                  <td>{{ languagePair.nyelv_2 }}</td>
                  <td *ngIf="languagePair.szakterulet">{{ languagePair.szakterulet }}</td>
                  <td *ngIf="!languagePair.szakterulet">-</td>
                  <td>{{ languagePair.oradij }}</td>
                  <td *ngIf="userId != 0">
                    <select *ngIf="commissionCode.length > 0" class="form-control input-lg formSelectInputGroup"
                            #commissionSelect (change)="setCommission(languagePair.id, commissionSelect.value)"
                            name="commission">
                      <option *ngFor="let commissionCode_ of commissionCode" [value]="commissionCode_.kod"
                              [selected]="languagePair.jutalek_kod == commissionCode_.kod">
                        {{ commissionCode_.nev }}
                      </option>
                    </select>
                  </td>
                  <td style="text-align:center;">
                    <a class="function"
                       (click)="loadLanguagePairForModify(languagePair.id)">
                      {{langEventDataStr.modositas}}
                    </a><br/>
                    <a type="button" class="function"
                       (click)="deleteLanguagePairs(languagePair.id)">
                      {{langEventDataStr.torles}}
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
              <div>
                <a class="button" style="float: right"
                        (click)="newLangPair()">{{langEventDataStr.uj_nyelvpar}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isNewLangPair" class="row animate formExtDiv">
        <div class="col-lg-10 formInnerDiv">
          <div class="card">
            <div class="card-header card-info">
              <span class="card-title">&nbsp;</span>
            </div>
            <div class="card-block">
              <form (ngSubmit)="insertLanguagePairs(languagePairData.id)" #languagePairsForm="ngForm" novalidate>
                <div class="form-content">
                  <div class="input-group">
                    <div class="form-group formSelectInputGroupDiv">
                      <label>{{langEventDataStr.nyelv_1}} *</label>
                      <select *ngIf="langCode.length > 0" [disabled]="isModify"
                              class="form-control input-lg formSelectInputGroup" [(ngModel)]="languagePairData.nyelv_1"
                              name="nyelv_1">
                        <option *ngFor="let langCode_ of langCode" [value]="langCode_.kod">
                          {{ langCode_.nev }}
                        </option>
                      </select>
                      <div *ngIf="errMessageData.nyelv_1" class="alert alert-danger">
                        <div *ngIf="errMessageData.nyelv_1">
                          {{ errMessageData.nyelv_1 }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group formSelectInputGroupDiv">
                      <label class="formSelectInputGroupRight">{{langEventDataStr.nyelv_2}} *</label>
                      <select *ngIf="langCode.length > 0" [disabled]="isModify"
                              class="form-control input-lg formSelectInputGroup formSelectInputGroupRight"
                              [(ngModel)]="languagePairData.nyelv_2" name="nyelv_2">
                        <option *ngFor="let langCode_ of langCode" [value]="langCode_.kod">
                          {{ langCode_.nev }}
                        </option>
                      </select>
                      <div *ngIf="errMessageData.nyelv_2" class="alert alert-danger">
                        <div *ngIf="errMessageData.nyelv_2">
                          {{ errMessageData.nyelv_2 }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="name">{{langEventDataStr.szakterulet}}</label>
                    <select *ngIf="specializationCode.length > 0" class="form-control input-lg" multiple="true"
                            [(ngModel)]="languagePairData.szakterulet" name="szakterulet">
                      <option *ngFor="let specializationCode_ of specializationCode" [value]="specializationCode_.kod">
                        {{ specializationCode_.nev }}
                      </option>
                    </select>
                    <div *ngIf="errMessageData.szakterulet" class="alert alert-danger">
                      <div *ngIf="errMessageData.szakterulet">
                        {{ errMessageData.szakterulet }}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="name">{{langEventDataStr.oradij_brutto}} *</label>
                    <input type="text" class="form-control input-lg" [(ngModel)]="languagePairData.oradij"
                           name="oradij">
                    <div *ngIf="errMessageData.oradij" class="alert alert-danger">
                      <div *ngIf="errMessageData.oradij">
                        {{ errMessageData.oradij }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn btn-outline-primary">{{langEventDataStr.mentes}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('Close click')">Close</button>
  </div>
</ng-template>

<div *ngIf="isLoggedIn" class="animate">
  <div class="page-navbar nav ">
    <div class="row" style="width: 100%">
      <h3>{{langEventDataStr.tolmacs_adatkarbantartas}}</h3>

      <a class="button ml-auto" [hidden]="interpreterProfileData.id == null"
         (click)="openLanguagePairs(languagePairsDialog)">{{langEventDataStr.nyelvparok}}</a>

    </div>
  </div>

  <div>
    <div class="col-md-12 col" style="padding: 20px">
      <div class="card">

        <div class="card-title">{{langEventDataStr.adatok}}</div>
        <div style="margin: 15px">

          <form (ngSubmit)="saveInterProfile()" #profileForm="ngForm" novalidate>
            <div class="form-content">
              <div class="row" style="align-items: flex-start;">
                <div class="form-group col-md-3">
                  <label for="neme">{{langEventDataStr.neme}} *</label>
                  <select class="form-control input-lg" name="neme" id="neme" [(ngModel)]="interpreterProfileData.neme">
                    <option value="M" class="custom_underline_option">{{langEventDataStr.ferfi}}</option>
                    <option value="F" class="custom_underline_option">{{langEventDataStr.no}}</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="szuletes_date">{{langEventDataStr.szuletesi_datum}} *</label>
                  <div class="dp-picker-day">
                    <input type="text" class="form-control input-lg" autocomplete="off" [dpDayPicker]="datePickerConfig"
                           placeholder="yyyy-mm-dd" [(ngModel)]="interpreterProfileData.szuletes_date"
                           name="szuletes_date" mode="day" [theme]="'dp-material dp-main'" id="szuletes_date"
                           placement="top" ngbTooltip="{{ errMessageData.szuletes_date }}">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-3">
                  <label for="telefon">{{langEventDataStr.telefonszam}}</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.telefon"
                         name="telefon" id="telefon"
                         placement="top" ngbTooltip="{{ errMessageData.telefon }}">
                </div>
                <div class="form-group col-9">
                  <label for="partner_nev">{{langEventDataStr.cegnev}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.partner_nev"
                         name="partner_nev" id="partner_nev"
                         placement="top" ngbTooltip="{{ errMessageData.partner_nev }}">
                </div>
              </div>
              <div class="row" style="align-items: flex-end;">
                <div class="form-group col-3">
                  <label for="szla_orszag">{{langEventDataStr.szekhely_orszag}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.szla_orszag"
                         name="szla_orszag" id="szla_orszag"
                         placement="top" ngbTooltip="{{ errMessageData.szla_orszag }}">
                </div>
                <div class="form-group col-2">
                  <label for="szla_irsz">{{langEventDataStr.szekhely_iranyitoszam}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.szla_irsz"
                         name="szla_irsz" id="szla_irsz"
                         placement="top" ngbTooltip="{{ errMessageData.szla_irsz }}">
                </div>
                <div class="form-group col-3">
                  <label for="szla_helyseg">{{langEventDataStr.szekhely_helyseg}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.szla_helyseg"
                         name="szla_helyseg" id="szla_helyseg"
                         placement="top" ngbTooltip="{{ errMessageData.szla_helyseg }}">
                </div>
                <div class="form-group col-4">
                  <label for="szla_cim">{{langEventDataStr.szekhely_cim}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.szla_cim"
                         name="szla_cim" id="szla_cim"
                         placement="top" ngbTooltip="{{ errMessageData.szla_cim }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-5">
                  <label for="penzintezet">{{langEventDataStr.szamlavezeto_penzintezet}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.penzintezet"
                         name="penzintezet" id="penzintezet"
                         placement="top" ngbTooltip="{{ errMessageData.penzintezet }}">
                </div>
                <div class="form-group col-4">
                  <label for="szamlaszam">{{langEventDataStr.szamlaszam}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.szamlaszam"
                         name="szamlaszam" id="szamlaszam"
                         placement="top" ngbTooltip="{{ errMessageData.szamlaszam }}">
                </div>
                <div class="form-group col-3">
                  <label for="adoszam">{{langEventDataStr.adoszam}} *</label>
                  <input type="text" class="form-control input-lg" [(ngModel)]="interpreterProfileData.adoszam"
                         name="adoszam" id="adoszam"
                         placement="top" ngbTooltip="{{ errMessageData.adoszam }}">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12">
                  <label for="szakteruletek">{{langEventDataStr.specialitasok_szakteruletek}}</label>
                  <textarea rows="3" cols="30" class="form-control input-lg"
                            [(ngModel)]="interpreterProfileData.szakteruletek" name="szakteruletek" id="szakteruletek"
                            placement="top" ngbTooltip="{{ errMessageData.szakteruletek }}"></textarea>
                </div>
              </div>
            </div>
            <div class="form-group" style="text-align:center;">
              <div style="display:inline-block;float:right;margin:15px;">
                <button type="submit" class="button">{{langEventDataStr.mentes}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
