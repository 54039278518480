import {Injectable} from '@angular/core';
import {OtrHttpRequestService} from './otr-http-request.service';

@Injectable()
export class OtrCodeLoadService {

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
  }

  loadCodesByCodeType(codeType, callback) {
    this.otrHttpRequestService.doGetAndCheck('/code/' + codeType, data => {
      if (!data['isError']) {
        callback(data['result']);
      }
    });
  }
}
