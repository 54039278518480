import {Injectable} from '@angular/core';
import {OtrHttpRequestService} from './otr-http-request.service';

@Injectable()
export class OtrUserService {

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
  }

  getMenus(callback) {
    this.otrHttpRequestService.doGetAndCheck('/auth/menu', data => {
      if (!data['isError']) {
        callback(data['result']);
      }
    });
  }

  getIsLoggedIn(callback) {
    this.otrHttpRequestService.doGetAndCheck('/auth/user', data => {
      if (data && !data['isError'] && data['result']['isLoggedin']) {
        callback(true);
      } else {
        if (data && !data['isError'] && data['result']) {
          callback(false, data['result']['lng']);
        } else {
          callback(false);
        }
      }
    });
  }

  getUserData(callback) {
    this.otrHttpRequestService.doGetAndCheck('/auth/user', data => {
      if (!data['isError']) {
        callback(data['result']);
      }
    });
  }

  doChangeLang(inLang, callback) {
    this.otrHttpRequestService.doPostAndCheck('/changeLang', {'lang': inLang}, data => {
      callback(data);
    });
  }

  doLogout() {
    this.otrHttpRequestService.doLogout();
  }

}
