import { Pipe, PipeTransform } from '@angular/core';

const options: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
  // timeZone: 'UTC',
};

@Pipe({
  name: 'simyDate'
})
export class SimyDatePipe implements PipeTransform {


  transform(value: string, ...args: string[]): string {
    if (value == null) { return ''; }
    const datePattern = /^(\d{4})-(\d{2})-(\d{2})\s(\d{1,2}):(\d{2}):(\d{2})$/;
    if (value.length === 16) {
      value += ':00';
    }
    const [, year, month, day, rawHour, min] = datePattern.exec(value);
    const date = new Date(`${year}-${month}-${day}T${('0' + rawHour).slice(-2)}:${min}:00`);
    return new Intl.DateTimeFormat(args[0], options).format(date);
  }

}
