<div *ngIf="isLoggedIn" class="animate">
  <div class="page-navbar nav ">
    <div class="row" style="width: 100%">
      <h3>{{langEventDataStr.felhasznaloi_adatok}}</h3>
    </div>
  </div>


  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col" style="padding: 15px">
        <div class="card">
          <div class="card-title"> {{langEventDataStr.e_mail_cim}}: &nbsp;&nbsp;&nbsp;{{userData.email}}</div>
          <div class="card-subtitle"></div>
          <div class="card-body" style="overflow-x:auto; padding-right: 20px;padding-left: 20px;">

            <div style="float:right;">
              <div style="width:125px;height:125px;">
                <label title="{{langEventDataStr.feltoltes}}" class="btn-file-upload"
                       style="float:right;cursor:pointer;">
                  <i class="fa fa-file fa-fw buttonInDropZone"></i>
                  <input type="file" name="file" ng2FileSelect [uploader]="uploader" hidden/>
                </label>

                <button *ngIf="userData.avatarId" title="{{langEventDataStr.torles}}" type="button" class="buttonImg"
                        (click)="deleteAvatar()">
                  <img class="imgInButton" src="../../../assets/images/torles.png" alt=""/>
                </button>

                <img *ngIf="userId == 0" src="/user-avatar" class="otrUserAvatar" style="width:125px;height:125px;"/>
                <img *ngIf="userId > 0" src="/user-avatar/admin/{{userId}}" class="otrUserAvatar"
                     style="width:125px;height:125px;"/>
              </div>
            </div>
            <div style="float:left;width:75%;">
              <form (ngSubmit)="saveProfile()" #profileForm="ngForm" novalidate>
                <div class="form-content">
                  <div class="form-group">

                  </div>
                  <div class="form-group">
                    <label>{{langEventDataStr.keresztnev}} *</label>
                    <input type="text" class="form-control input-lg" [(ngModel)]="userData.keresztnev"
                           name="keresztnev"
                           placement="top" ngbTooltip="{{ errMessageData.keresztnev }}">
                  </div>
                  <div class="form-group">
                    <label>{{langEventDataStr.vezeteknev}} *</label>
                    <input type="text" class="form-control input-lg" [(ngModel)]="userData.vezeteknev"
                           name="vezeteknev"
                           placement="top" ngbTooltip="{{ errMessageData.vezeteknev }}">
                  </div>
                  <div class="form-group">
                    <label>{{langEventDataStr.nyelv}} *</label>
                    <select *ngIf="langCode.length > 0" class="form-control input-lg"
                            [(ngModel)]="userData.nyelv" name="nyelv"
                            placement="top" ngbTooltip="{{ errMessageData.tipus }}">
                      <option *ngFor="let langCode_ of langCode" [value]="langCode_.kod">
                        {{ langCode_.nev }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <div class="input-group">
                      <label>{{langEventDataStr.calendar}}</label>
                      <input readonly class="form-control input-lg"
                              [value]="'https://'+host+'/calendar/'+userData.calendar_id" name="calendar"
                              placement="top" ngbTooltip="{{ errMessageData.calendar }}">
                      <button (click)="copyToClipboard()"  class="btn btn-outline-secondary" type="button" id="button-addon2"><i class="fa fa-copy"></i></button>
                    </div>
                  </div>
                </div>
                <div class="form-group" style="float: right">
                  <button type="submit" class="button">{{langEventDataStr.mentes}}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
