<div class="container-fluid event-monitor darkest-bkg" style="margin-top: 0;">
  <div class="player">
    <div class="partheader">
      <button class="btn btn-danger"
              style="
                    float: left;
                    z-index: 100;
                    position: relative;
                    height: 40px;
                    padding-right: 16px;
                    justify-content: center;
                    display: flex;
                    line-height: 22px;"
              (click)="backClicked()">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="currentColor" class="bi bi-door-open"
             viewBox="0 0 16 16">
          <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
          <path
            d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
        </svg>
        {{langStr.exit}}
      </button>
      <div class="room-name">{{roomStatus.room.name}}</div>
      <div class="toolar-buttons" style="float:right;margin-right:10px;">
        <div class="participant-toggle" (click)="eventDisplayState.showParticipants()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people-fill"
               viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
            <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
          </svg>

          <span class="position-absolute top-10 start-100 translate-middle badge rounded-pill"
                [ngClass]="{'bg-danger':roomStatus.interpreterIsOffline,'bg-success':!roomStatus.interpreterIsOffline}">
            {{roomStatus.allUserList.length}}
          </span>
        </div>
        <div class="chat-toggle" (click)="eventDisplayState.showChat()" style="cursor:pointer;float:left;margin-right:10px;position: relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat"
               viewBox="0 0 16 16">
            <path
              d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
          </svg>
          <span
            [style.display]="roomStatus.newMessageCount == 0?'none':''"
            class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">{{roomStatus.newMessageCount}}</span>
        </div>
        <div class="files-toggle" (click)="eventDisplayState.showDocuments()"
             style="cursor:pointer;float:left;margin-right:10px;position: relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-files"
               viewBox="0 0 16 16">
            <path
              d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"/>
          </svg>
          <span
            [style.display]="roomStatus.newFileCount == 0?'none':''"
            class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">{{roomStatus.newFileCount}}</span>
        </div>
        <div *ngIf="roomStatus.room.state == 0" style="cursor:pointer;float:left;margin-right:10px;"
             ngbPopover="{{langStr.gatheringInfo}}">
          <span class="fa fa-info-circle"></span>
        </div>
        <div class="rightPanel-close" *ngIf="eventDisplayState.rightPanelVisible" (click)="eventDisplayState.closeRightPanel()"
             style="cursor:pointer;float:left;margin-right:10px;">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
               viewBox="0 0 16 16">
            <path
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </div>
      </div>
    </div>
    <div [ngClass]="{'video-area':eventDisplayState.rightPanelVisible,'video-area-wide':!eventDisplayState.rightPanelVisible}">
      <button (click)="eventDisplayState.toggleFitById('canvas-output')"
              class="btn"
              style="color: white;width: 40px;top: 10px;position: absolute;left: 10px;padding-top:11px;padding-left:8px;padding-right:8px;z-index: 200;background-color: #3b3a39; ">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-aspect-ratio"
             viewBox="0 0 16 16">
          <path
            d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
          <path
            d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z"/>
        </svg>
      </button>
      <div id="otherUserVideo" class="speaker-video" [ngClass]="{'blackbkg':!eventDisplayState.hasCamera}">
        <ngx-spinner bdColor="#333333" size="large" color="#ffffff" type="ball-8bits"
                     [fullScreen]="false" [hidden]="!serverConnection.inPublish">
          <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <video id="localVideo" autoplay playsinline width="100%" height="100%" style="display: none"></video>
        <canvas [hidden]="!videoEnabled()"  id="canvas-output" width="{{outputWidth}}" height="{{outputHeight}}" style="object-fit:contain;width: 100%;height: 100%"></canvas>
      </div>

      <div *ngIf="roomStatus.interpreter" id="interpreterVideoContainer"
           [hidden]="roomStatus.interpreter.subscription == null"
           [style.display]="roomStatus.room.state == 0?'':'none'"
           style=" position: absolute; bottom: 5px; left: 5px; z-index: 100; overflow:hidden; width:180px; height: 135px;">
        <div [hidden]="roomStatus.interpreter.subscription == null || roomStatus.interpreter.videoState == 0"  id="interpreterVideo" style="position: absolute;width:100%; height: 100%; border: 1px #323131 solid;"></div>
        <div [hidden]="roomStatus.interpreter.subscription == null || roomStatus.interpreter.videoState == 1" id="interpreterImage"
             style="position:absolute;background-size: cover;width: 100%; height: 100%;background-position: center; border-radius: 7px; left: 0; top: 0;"
             [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + roomStatus.interpreter.id + ')'}">
        </div>
        <div style="position: absolute;left: 10px;top:5px;">
          <span class="stroke">{{langStr.interpreter}}</span>
        </div>
        <div style="position: absolute;right: 10px;bottom:5px;width: 100px;text-align:right;">
          <span class="stroke">{{roomStatus.interpreter.nickname}}</span>
        </div>
      </div>
      <div *ngIf="showMuteMessage" [@enterAnimation] id="unmuteaudio"
           style="position: absolute;width: 100%; height: 100%; top: 0; left: 0; display: flex; align-items: center; justify-content: center;">
        <span class="stroke" style="color: #ff7070; font-size: 24px; text-align: center;">{{langStr.turnonmic}}</span>
      </div>
    </div>
    <div class="other-videos">
      <ng-template ngFor let-item [ngForOf]="roomStatus.userList">
        <div *ngIf="item.pinned" #userPinned id="Partner{{item.id}}Container" data-userId="{{item.id}}" class="videoContainer">
          <video id="Partner{{item.id}}VideoContainer" [hidden]="item.videoState === 0"
                 style="width:100%;height:100%;overflow:hidden;position: absolute;left: 0;top: 0;" autoplay>
          </video>
          <div id="Partner{{item.id}}" [hidden]="item.videoState === 1"
               style="background-size: cover;width: 80px;height: 80px;background-position: center;  border-radius: 15px;  position: absolute;  left: 50px;  top: 10px;"
               [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}"
               [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
          </div>
          <div id="Partner{{item.id}}CanvasContainer"
               style="width:16px;height:16px;overflow:hidden;position: absolute;right: 10px;top:10px;">
            <canvas width="16" height="16" style="width:16px;height:16px;" id="Partner{{item.id}}Canvas"></canvas>
          </div>
          <div id="Partner{{item.id}}Name"
               style="position: absolute;left: 1px;bottom:1px;width: 178px;text-align:center;overflow: hidden">
            <span class="stroke">{{item.name}}</span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="rightPanel" [hidden]="!eventDisplayState.rightPanelVisible">
    <div class="participants darkest-bkg" [hidden]="!eventDisplayState.participantsVisible">
      <div class="partheader">
        <div>{{langStr.presenters}}</div>
      </div>
      <div class="partsubheader">
        <div>{{langStr.presenter}}</div>
      </div>
      <div @list id="missingUsers"
           style="min-height:100px;max-height:100px;overflow-y:scroll;border-bottom:1px solid #323131">
        <div *ngIf="roomStatus.speakingUserHandler != null">
          <div @items class="chatrow" style="min-height: 50px;color: white;position: relative;">
            <div id="UserMS{{roomStatus.speakingUserHandler.id}}"
                 style="float:left;margin-right: 10px; background-size: cover;width: 40px;height: 40px;background-position: center;  border-radius: 7px;  position: relative;  left: 0;  top: 0;"
                 [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + roomStatus.speakingUserHandler.id + ')'}"
                 class="speaking">
            </div>
            <span class="sender">{{roomStatus.speakingUserHandler.name}}</span><br/>
            <span *ngIf="roomStatus.speakingUserHandler.type == 'T'" class="message">{{langStr.interpreter}}</span>
            <span *ngIf="roomStatus.speakingUserHandler.type == 'U' && roomStatus.speakingUserHandler.id == roomStatus.room.userTree.id"
                  class="message">{{langStr.organizer}}</span>

            <div id="PartnerS{{roomStatus.speakingUserHandler.id}}Lang" style="position: absolute;right: 5px;bottom:10px;">
              <span class="stroke">{{roomStatus.speakingUserHandler.lang}}</span>
            </div>
          </div>
        </div>
      </div>

      <div>


        <div id="currentContainer" style="width:100%;height:calc(100vh - 342px);overflow-y: scroll;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.allUserList">
            <div @items class="chatrow" style="position:relative;min-height: 50px;color: white;cursor: pointer;" (click)="selectSpeaker(item.id)">
              <div id="UserP{{item.id}}"
                   style="float:left;margin-right: 10px; background-size: cover;width: 40px;height: 40px;background-position: center;  border-radius: 7px;  position: relative;  left: 0;  top: 0;"
                   [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}"
                   [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
              </div>
              <span class="sender">{{item.name}}</span><br/>
              <span *ngIf="item.type == 'T'" class="message">{{langStr.interpreter}}</span>
              <span *ngIf="item.type == 'U' && item.id == roomStatus.room.userTree.id"
                    class="message">{{langStr.organizer}}</span>
              <div id="Partner{{item.id}}Lang" style="position: absolute;right: 5px;bottom:10px;">
                <span class="stroke">{{item.lang}}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="documents darkest-bkg" [hidden]="!eventDisplayState.documentsVisible">
      <div>
        <div class="formInnerDiv">
          <div style="border: 1px solid #323131;position: relative;">
            <div class="partheader">
              <div style="float:left">{{langStr.documents}}</div>
              <label class="btn-file-upload" style="float:right;">
                <em class="fa fa-file fa-fw buttonInDropZone"></em>
                <input type="file" name="file" ng2FileSelect [uploader]="fileHandler.uploader" multiple hidden/>
              </label>
            </div>
            <div ng2FileDrop [ngClass]="{'nv-file-over': hasBaseDropZoneOver}" (fileOver)="fileOverBase($event)"
                 [uploader]="fileHandler.uploader" class="well my-drop-zone"
                 style="overflow-y: scroll;  height: calc(100vh - 198px);position: absolute;">
              <ng-template ngFor let-item [ngForOf]="roomStatus.room.files">
                <div class="filerow" style="position:relative;" (click)="fileHandler.downloadFile(item.id);$event.stopPropagation()">
                  <i style="position:absolute;right:5px;top:5px;" title="Törlés" class="fa fa-remove fa-fw buttonIcon"
                     aria-hidden="true" (click)="fileHandler.deleteFile(item.id);$event.stopPropagation()"></i>
                  <span class="filename" >{{item.name}}</span><br/><span class="fileuploader">{{item.userName}}</span>
                </div>
              </ng-template>
            </div>
            <div class="fileAlertBox" *ngIf="fileHandler.tooLargeFile">
              {{langStr.tooLargeFile}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat darkest-bkg" [hidden]="!eventDisplayState.chatVisible">
      <div class="partheader">
        <div>{{langStr.chat}}</div>
      </div>
      <div style="height: 100%">
        <div class="input-group mb-3 darkest-bkg" style="margin-bottom: 0 !important;">
          <input type="text" class="form-control darkest-bkg" id="chatmsg" #chatmsg
                 (keyup.enter)="onEnter(chatmsg.value)">
          <div class="input-group-append">
            <button class="btn btn-secondary darkest-bkg" (click)="sendText()" type="button" placement="auto"
                    ngbTooltip="{{langStr.sendmessage}}"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
          </div>
        </div>
        <div id="chatmsgs" class="darkest-bkg"
             style="overflow:auto;height: calc(100vh - 232px);width:auto;border:1px #323131 solid;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.room.texts">
            <div @items class="chatrow" [ngClass]="{'question':item.isQuestion == true}" >
              <span *ngIf="item.isQuestion" class="sender">{{item.userName}}</span>&nbsp;
              <span *ngIf="!item.isQuestion" class="sender">{{item.user.nickname}}</span>&nbsp;
              <span class="dateTime">{{item.dateTime}}</span><br/><span class="message">{{item.text}}</span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="control-area">
    <div>
      <div>
        <button (click)="toggleCamera()" [disabled]="!eventDisplayState.hasCamera"
                [ngClass]="{'btn-success': videoEnabled() ,'btn-secondary': !videoEnabled()}"
                class="btn"
                style="width: 60px;margin-right:5px;top: 5px;position: absolute;left: 5px;padding-left:8px;padding-right:8px;z-index: 200;">
          <i class="fa"
             [ngClass]="{'fa-video-camera':videoEnabled(),'fa-video-slash':!videoEnabled()}"
             aria-hidden="true"></i>
        </button>
        <button (click)="toggleMic()" *ngIf="roomStatus.room.state == 0"
                [ngClass]="{'btn-secondary':eventDisplayState.muteOn,'btn-success':!eventDisplayState.muteOn}"
                class="btn"
                style="width: 60px;margin-left:5px;position: absolute;left: 65px;top: 5px;padding-left:8px;padding-right:8px;z-index: 200;">
          <i class="fa" [ngClass]="{'fa-microphone':!eventDisplayState.muteOn,'fa-microphone-slash':eventDisplayState.muteOn}" aria-hidden="true"></i>
        </button>
        <div class="slidecontainer"
             style="width: 165px;left: 66px;top:45px;position: absolute;">
          <input type="range" min="0" max="100" [(ngModel)]="eventDisplayState.maxVolume" class="slider" id="volumeSlider" (change)="setGains()">
          <div class="volumedot" [ngStyle]="eventDisplayState.getMaxVolume()">
            {{eventDisplayState.maxVolume}}
          </div>
        </div>
        <button *ngIf="roomStatus.room.state == 1" class="btn"
                style="width: 289px;position: absolute;top: 75px;left: 5px;height:33px"
                [ngClass]="{'greenglowbutton':eventDisplayState.speakingStatus==='2','btn-secondary':eventDisplayState.speakingStatus === '0','btn-success':eventDisplayState.speakingStatus === '1','btn-warning':eventDisplayState.speakingStatus==='3'}"
                (click)="nextState()"><em class="fa fa-microphone"></em></button>
        <button *ngIf="roomStatus.room.state == 1" class="btn"
                style="width: 60px;position: absolute;left: 70px;top: 5px;padding-left: 8px;padding-right: 8px;"
                [ngClass]="{'btn-danger':eventDisplayState.muteOn,'btn-secondary':!eventDisplayState.muteOn}" (click)="toggleMic()">MUTE
        </button>
      </div>
      <div *ngIf="roomStatus.speakingUserHandler != undefined" style="position:absolute;right: 5px;top: 5px;">
        <ng-template ngFor let-item [ngForOf]="roomStatus.room.languages">
          <button style="width:62px;" class="btn" id="outputLang{{item}}"
                  [ngClass]="{'btn-light':(roomStatus.speakingUserHandler.lang!=item),'btn-success':roomStatus.speakingUserHandler.lang==item }"
                  type="button" (click)="setLang(item)">{{item}}</button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
