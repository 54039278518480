import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrUserService} from '../../otr-user.service';
import {FileUploader} from 'ng2-file-upload';
import {OtrLangService} from '../../otr-lang.service';
import {OtrConfirmationDialogService} from '../../otr-confirmation-dialog.service';
import {Subscription} from 'rxjs/Subscription';
import {OtrCodeLoadService} from '../../otr-code-load.service';

@Component({
  selector: 'app-otr-user-profile',
  templateUrl: './otr-user-profile.component.html'
})
export class OtrUserProfileComponent implements OnInit, OnDestroy {

  @ViewChild('profileForm') profileForm: HTMLFormElement;
  public uploader: FileUploader;
  URL: any;
  uploadIsProgress = false;
  errMessageData: any = {};
  userData: any = {};
  isLoggedIn = false;
  langEventDataStr: any = {};
  userId: any;
  subscriptionLang: Subscription;
  langCode: any = {};
  host: string;

  constructor(private router: Router,
              private otrHttpRequestService: OtrHttpRequestService, private otrUserService: OtrUserService,
              private otrConfirmationDialogService: OtrConfirmationDialogService, private otrCodeLoadService: OtrCodeLoadService,
              private activatedRoute: ActivatedRoute, private otrLangService: OtrLangService) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      langEventDataStr => {
        this.otrLangService.getValuesForGroup('userProfileStr', res => {
          this.langEventDataStr = res;
        });
      });
    if (activatedRoute.snapshot.params.id) {
      this.userId = activatedRoute.snapshot.params.id;
    } else {
      this.userId = 0;
    }
    this.URL = '/user-avatar';
    this.uploader = new FileUploader({url: this.URL, removeAfterUpload: true, autoUpload: true});
  }

  ngOnInit() {
    this.otrLangService.getValuesForGroup('userProfileStr', res => {
      this.langEventDataStr = res;
    });
    this.otrCodeLoadService.loadCodesByCodeType('NYELV', data => {
      this.langCode = data;
    });
    this.otrHttpRequestService.doGetAndCheck((this.userId > 0 ? '/user-profile/load/admin/' + this.userId : '/user-profile/load'), data => {
      if (!data['isError']) {
        this.isLoggedIn = true;
        this.userData = data['result'];
      } else {
        this.userData = {};
        if (!data['isNotLoggedin']) {
          this.isLoggedIn = true;
        }
      }
    });
    this.uploader.onCompleteAll = () => {
      this.uploadIsProgress = false;
      window.location.reload();
    };
    this.uploader.onProgressAll = (progress: any) => {
      this.uploadIsProgress = true;
    };
    this.host = window.location.host;
  }

  saveProfile() {
    this.otrHttpRequestService.doPostAndCheck(
      (this.userId > 0 ?
        '/user-profile/admin/' + this.userId :
        '/user-profile'), this.userData, res => {
        if (res['isError']) {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key)) {
              if (this.profileForm.form.controls[key] !== undefined) {
                if (res[key] != null) {
                  this.profileForm.form.controls[key].setErrors({'incorrect': true});
                } else {
                  this.profileForm.form.controls[key].setErrors(null);
                }
              }
            }
          }
        }
      }
    );
  }

  deleteAvatar() {
    this.otrLangService.getValuesForGroup('dialogStr', res => {
      this.otrConfirmationDialogService.confirm(res.rendszer_uzenet, res.biztosan_torolni_akarja).then(reqConf => {
        if (reqConf) {
          this.otrHttpRequestService.doDeleteAndCheck('/user-avatar', () => {
            window.location.reload();
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }

  copyToClipboard() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText('https://' + this.host + '/calendar/' + this.userData.calendar_id).then(function() {
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }
  }
}
