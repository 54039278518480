<div *ngIf="isLoggedIn" class="animate">
  <div class="page-navbar nav ">
    <div class="row" style="width: 100%">
      <h3>{{langEventDataStr.teljesitmenyek}}</h3>
    </div>
  </div>

  <div class="container-fluid " style="height: calc( 100vh - 51px)">
    <div class="row">
      <div class="col-md-12 col" style="padding: 15px">
        <div class="card">
          <div class="card-title"></div>
          <div class="card-subtitle"></div>
          <div class="card-body" style="overflow-x:auto;">
            <table class="table table-striped" role="none" id="invoiceinterpretertable">
              <thead>
              <tr>
                <th>{{langEventDataStr.esemeny_kelte}}</th>
                <th>{{langEventDataStr.szamlazva}}</th>
                <th>{{langEventDataStr.brutto_ertek}}</th>
                <th>{{langEventDataStr.partner}}</th>
              </tr>
              </thead>
              <tbody *ngIf="invoiceData.length > 0">
              <tr *ngFor="let invoiceData_ of invoiceData">
                <td>{{ invoiceData_.esemeny_kezdete }}</td>
                <td>{{ invoiceData_.fizetve_date }}</td>
                <td>{{ invoiceData_.vegosszeg_brutto }}</td>
                <td>{{ invoiceData_.partner_nev }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
