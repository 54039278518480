import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrUserService} from '../../otr-user.service';
import {OtrLangService} from '../../otr-lang.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-otr-forgotten-password',
  templateUrl: './otr-forgotten-password.component.html'
})
export class OtrForgottenPasswordComponent implements OnInit, OnDestroy {

  @ViewChild('newPassForm') newPassForm: HTMLFormElement;
  @ViewChild('newPassReqForm') newPassReqForm: HTMLFormElement;
  menus = [];
  isNotLoggedIn = false;
  passwordData: any = {};
  errMessageData: any = {};
  token: any;
  isNewPassRequest = false;
  langEventDataStr: any = {};
  navBarStr: {
    altalanos_szerzodesi_feltetelek: string,
    adatvedelem: string
  };
  subscriptionLang: Subscription;
  currentLang = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router,
              private otrHttpRequestService: OtrHttpRequestService, private otrUserService: OtrUserService,
              private otrLangService: OtrLangService) {
    if (activatedRoute.snapshot.params.token) {
      this.token = activatedRoute.snapshot.params.token;
    } else {
      this.isNewPassRequest = true;
    }
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      lang => {
        this.currentLang = lang;
        this.otrLangService.getValuesForGroup('forgPasswStr', res => {
          this.langEventDataStr = res;
        });
        this.otrUserService.getMenus(menuData => {
          this.menus = menuData;
        });
        this.otrLangService.getValuesForGroup('navBarStr', res => {
          this.navBarStr = res;
        });
      });
  }

  ngOnInit() {
    this.otrLangService.getValuesForGroup('forgPasswStr', res => {
      this.langEventDataStr = res;
      this.currentLang = this.otrLangService.getCurrentLang();

    });
    this.otrLangService.getValuesForGroup('navBarStr', res => {
      this.navBarStr = res;
    });
    this.otrUserService.getIsLoggedIn((data, lng) => {
      if (!data) {
        this.isNotLoggedIn = true;
        this.currentLang = lng;
        this.otrUserService.getMenus(menuData => {
          this.menus = menuData;
        });
      } else {
        this.router.navigate(['/home']).then();
      }
    });
  }

  newPassword() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPutAndCheck('/new-password/' + this.token, this.passwordData, res => {
        if (res['isError']) {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key) && this.newPassForm.form.controls[key]) {
              if (res[key] != null) {
                this.newPassForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.newPassForm.form.controls[key].setErrors(null);
              }
            }
          }
        } else {
          this.router.navigate(['auth']);
        }
      }
    );
  }

  newPasswordRequest() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck('/new-password', this.passwordData, res => {
        if (res['isError']) {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key) && this.newPassForm.form.controls[key]) {
              if (res[key] != null) {
                this.newPassReqForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.newPassReqForm.form.controls[key].setErrors(null);
              }
            }
          }
        } else {
          this.router.navigate(['auth']);
        }
      }
    );
  }

  selectLang() {
    this.otrLangService.selectLang('', '');
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }
}
