<div *ngIf="isLoggedIn" class="animate">
  <div class="page-navbar nav ">
    <div class="row" style="display:flex; flex-wrap: nowrap; width: 100%">
      <h3 style="flex-shrink: 0">{{langEventDataStr.esemeny_lekerdezes}}</h3>
      <div class="ml-auto" *ngIf="userType == 'U'" style="display: flex; flex-wrap: nowrap;">
        <a class="button" (click)="newEvent()"><i class="bi-calendar-plus"></i><span class="hide-on-small">{{langEventDataStr.uj_esemeny}}</span></a>
        <a class="button" (click)="newConference()"><i class="bi-table"></i><span class="hide-on-small">{{langEventDataStr.uj_konferencia}}</span></a>
      </div>
    </div>
  </div>


  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col" style="padding: 15px">
        <div class="card">
          <div class="card-title"></div>
          <div class="card-subtitle">
            <input type="checkbox" style="margin: 10px 10px 0 10px;" [checked]="onlyWaiting" (change)="onlyWaitingChange()">
            <a class="function" (click)="onlyWaitingChange()">{{langEventDataStr.varakozo}}</a>
          </div>
          <div class="card-body" style="overflow-x:auto;">
            <table class="table table-striped" id="eventstable">
              <caption></caption>
              <thead>
              <tr>
                <th scope="col">{{langEventDataStr.esemeny_neve}}</th>
                <th scope="col">{{langEventDataStr.esemeny_kelte}}</th>
                <th scope="col">{{langEventDataStr.statusz}}</th>
                <th scope="col">{{langEventDataStr.muveletek}}</th>
              </tr>
              </thead>
              <tbody *ngIf="eventQueries.length > 0">
              <tr *ngFor="let eventQuery_ of eventQueries" style="height: 60px;"
              [style.backgroundColor]="eventQuery_.isAnswerAble || eventQuery_.isJoinble? 'rgba(100,160,214,0.2)': ''"
              >

                <td *ngIf="eventQuery_.tipus == 'EVENT'">
                  <a class="nav-link function"
                     [routerLink]="['/event/event-data', eventQuery_.id]">{{ eventQuery_.esemeny_neve }}</a>
                </td>
                <td *ngIf="eventQuery_.tipus == 'CONFERENCE'">
                  <a class="nav-link function"
                  [routerLink]="['/event/conference-data', eventQuery_.id]">{{ eventQuery_.esemeny_neve }}</a>
                </td>
                <td *ngIf="eventQuery_.tipus != 'EVENT' && eventQuery_.tipus != 'CONFERENCE'">
                  <a class="nav-link function"
                  [routerLink]="['/event/event-data', eventQuery_.id]">{{ eventQuery_.esemeny_neve }}</a>
                </td>

                <td>{{ eventQuery_.esemeny_kezdete  | simyDate: currentLang  }}</td>
                <td>{{ getStatusText(eventQuery_.esemeny_statusz_kod) }}</td>
                <td>
                  <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); grid-gap: 4px;">
                    <a *ngIf="eventQuery_.isNotAnswered && eventQuery_.isAnswerAble" class="function"
                            (click)="acceptInvite('A', eventQuery_.id)">{{langEventDataStr.elfogadas}}
                    </a>
                    <a *ngIf="!eventQuery_.isNotAnswered && eventQuery_.isAnswerAble && eventQuery_.isAccept && !eventQuery_.isMine"
                      class="function"
                      (click)="controlInvite('C', eventQuery_.id)">{{langEventDataStr.lemondas}}
                    </a>
                    <a *ngIf="eventQuery_.isNotAnswered && eventQuery_.isAnswerAble" class="function"
                            (click)="controlInvite('D', eventQuery_.id)">{{langEventDataStr.elutasitas}}
                    </a>
                    <a *ngIf="eventQuery_.isInviteable" class="function"
                            (click)="participants(eventQuery_.id)">{{langEventDataStr.resztvevok}}
                    </a>
                    <a *ngIf="eventQuery_.isJoinble" class="function"
                            (click)="goEventMonitor(eventQuery_.id)">{{langEventDataStr.csatlakozas}}
                    </a>
                    <a *ngIf="eventQuery_.isMine && eventQuery_.isDeleteable" class="function"
                            (click)="deleteEvent(eventQuery_.id)">{{langEventDataStr.torles}}
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
