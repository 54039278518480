import {EventStreamHandler} from '../event-stream-handler';
import {EventComponent} from './event-component';

const kAquire = Symbol('aquire');
const kLocked = Symbol('locked');
const kQueue = Symbol('queue');
const kRelease = Symbol('release');


class Mutex {
  get locked() {
    return this[kLocked];
  }

  constructor() {
    this[kLocked] = false;
    this[kQueue] = [];
  }

  [kAquire]() {
    if (this[kLocked] === false) {
      this[kLocked] = true;
      return Promise.resolve();
    }

    return new Promise((resolve) => this[kQueue].push(resolve));
  }

  [kRelease]() {
    if (this[kQueue].length) {
      this[kQueue].shift()();
    } else {
      this[kLocked] = false;
    }
  }

  use(fn) {
    return this[kAquire]().then(() => {
      return Promise.resolve().then(fn).then(
        (result) => {
          this[kRelease]();
          return result;
        },
        (error) => {
          this[kRelease]();
          throw error;
        }
      );
    });
  }
}

const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val) =>
      hasCanceled_ ? reject({isCanceled: true}) : resolve(val)
    );
    promise.catch((error) =>
      hasCanceled_ ? reject({isCanceled: true}) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

export class UserHandler {
  static mutex = new Mutex();
  id: number;
  type: string;
  name: string;
  nickname: string;
  lang: string;
  streamId: string = undefined;
  enabled: boolean;
  streamStatus: number;
  state: string;
  videoState: number;
  audioState: number;

  selfId: number;

  subscription: EventStreamHandler = undefined;

  inSubscribe = false;
  gainNode: GainNode;

  conference: any;
  audioContext: AudioContext;
  pinned = false;
  pinnedBefore = false;
  component: EventComponent;
  userHasVideo = true;
  inReconnect = false;

  constructor() {
    this.streamStatus = 0;
  }

  setup(conference: any, audioContext: AudioContext, component: EventComponent) {
    this.conference = conference;
    this.audioContext = audioContext;
    this.component = component;
    if (this.id !== this.selfId) {
      UserHandler.mutex.use(async () => {
        await this.connect();
      });
    }
  }

  trySubscribeStream(stream) {
    if (this.inSubscribe === true) {
      return;
    }
    this.streamStatus = 1;
    this.inSubscribe = true;
    const me = this;
    if (this.conference) {
      this.userHasVideo = stream.settings.video.length > 0 !== undefined;
      let videoCapabilities: any = false;
      console.log('video settings', stream.settings.video);
      if (stream.settings.video !== undefined) {
        /*
        console.log(stream.settings.video)
        const max = stream.settings.video.reduce((acc, videoPublishParams) => {
          const size = videoPublishParams.resolution.width * videoPublishParams.resolution.height;
          return acc[0] > size ? acc : [size, videoPublishParams];
        }, [0, '']);
        console.log('video settings ', max[1].resolution);
        */
        videoCapabilities = true; // { codecs: [max[1].codec], resolution: max[1].resolution };
      }

      videoCapabilities = me.component.subscribeOptions; // {};
      /*{
          codecs: [stream.settings.video[0].codec],
          resolution: stream.settings.video[0].resolution
        };
*/
      const cancelablePromise = makeCancelable(
        this.conference.subscribe(stream, {audio: stream.source.audio === undefined ? false : {}, video: videoCapabilities})
    );
      cancelablePromise.promise.then((subscription) => {

        this.streamStatus = 2;
        if (stream.source.audio !== undefined) {
          if (!this.audioContext) {
            this.audioContext = new AudioContext();
          }
          const source = this.audioContext.createMediaStreamSource(stream.mediaStream);
          this.gainNode = this.audioContext.createGain();
          this.gainNode.gain.setValueAtTime(0.0, this.gainNode.context.currentTime);

          source.connect(this.gainNode);
          this.gainNode.connect(this.audioContext.destination);
        }
        if (this.subscription) {
          this.subscription.audioVideo(false);
          this.subscription.close();
        }
        const subscriptionObject = new EventStreamHandler(stream, subscription, this.gainNode, stream.id, this);

        subscriptionObject.streamEndedCallback = () => {
          if (this.subscription) {
            const oldVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('video' + this.subscription.streamId));
            this.pinnedBefore = this.pinned;
            this.pinned = false;
            this.streamStatus = 10;
            if (oldVideo) {
              oldVideo.parentNode.removeChild(oldVideo);
            }
            delete this.subscription;
            // try to reconnect
          }
        };

        subscriptionObject.audioVideo(true);
        this.subscription = subscriptionObject;
        this.subscription.streamId = stream.id;
        if (this.component?.roomStatus?.displayedStreamId === stream.id) {
          if (this.component && this.component.roomStatus) {
            console.log('displayed stream id reseted');
            this.component.roomStatus.displayedStreamId = null;
          }
        }

        this.subscription.userId = this.id;
        const previousVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('video' + stream.id));
        if (previousVideo) {
          previousVideo.remove();
        }
        const video = document.createElement('video');
        video.setAttribute('width', '100%');
        video.setAttribute('height', '100%');
        video.setAttribute('autoplay', 'true');
        video.setAttribute('playsinline', '');
        video.setAttribute('id', 'video' + stream.id);
        let div;
        if (this.type === 'T') {
          div = document.getElementById('interpreterVideo');
        } else {
          div = document.getElementById('UserP' + this.id);
        }
        if (div !== undefined && div !== null) {
          div.appendChild(video);
        } else {
          // console.log(this.id, this.type, this.name);
        }

        const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('video' + stream.id));
        if (localVideo) {
          console.debug('UserHandler', 'trySubscribeStream', 'tag', 'video' + stream.id);
          try {
            localVideo.srcObject = stream.mediaStream;
          } catch (error) {
            try {
              localVideo.src = URL.createObjectURL(stream.mediaStream);
            } catch (error) {
              // cant show video
            }
          }
          localVideo.setAttribute('playsinline', '');
          localVideo.volume = 0.0;
          if (div !== undefined && this.type !== 'T') {
            div.removeChild(video);
          }
          me.inSubscribe = false;
          if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
          }
          this.pinned = this.pinnedBefore;
        }
        // else: there is no localVideo => we are in the destroy cycle

      }, function (err) {
        me.streamStatus = 3;
        // console.error(stream.id, 'subscribe failed:', err);
        me.inSubscribe = false;
      });

      // cancelablePromise.promise.then((subscription) => {
      setTimeout(() => {
        if (!this.subscription) {
          cancelablePromise.cancel();
          // console.error(stream.id, 'subscribe timeout');
          me.streamStatus = 3;
        }
        me.inSubscribe = false;
      }, 5000);
    } else {
      this.inSubscribe = false;
    }

  }

  async connect() {

    console.debug('UserHandler', 'connect', (this.subscription === undefined), this.streamId !== undefined, this.id !== this.selfId);
    if (
      this.conference &&
      (this.subscription === undefined) &&
      this.streamId !== undefined &&
      this.id !== this.selfId
    ) {
      // if (this.conference.published) {
        const conferenceInfo = this.conference.info;
        if (conferenceInfo) {
          const stream = conferenceInfo.remoteStreams.find(str => str.id === this.streamId);
          if (stream !== undefined && stream.origin !== conferenceInfo.self.id) {
            this.trySubscribeStream(stream);
          }
        }
      // }
    }
  }

  resetStream(): void {

    console.debug('UserHandler', 'resetStream');
    const previousVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById('video' + this.streamId));
    if (previousVideo) {
      previousVideo.remove();
    }
    this.streamId = undefined;
  }

  setStreamId(streamId: string): void {

    if (this.streamId) {
      if (this.streamId !== streamId || this.streamStatus === 3 || this.streamStatus === 10) {
        console.debug('UserHandler', 'setStreamId', streamId, 'id', this.id, 'old stream id', this.streamId, 'status',  this.streamStatus);
        // disconnect before connect
        if (this.subscription) {
          this.subscription.close();
        }
        this.subscription = undefined;
        this.streamId = streamId;
        if (this.id !== this.selfId) {
          UserHandler.mutex.use(async () => {
            await this.connect();
          });
        }

      }
    } else {
      this.streamId = streamId;
      if (this.id !== this.selfId) {
        UserHandler.mutex.use(async () => {
          await this.connect();
        });
      }
    }
  }

  update(user: UserHandler) {
    if (user.type) {
      this.type = user.type;
    }
    if (user.name) {
      this.name = user.name;
    }
    if (user.nickname) {
      this.nickname = user.nickname;
    } else {
      this.nickname = this.name;
    }
    if (user.lang) {
      this.lang = user.lang;
    }
      /*
          if (this.id === this.selfId && this.conference) {
            const conferenceInfo = this.conference.info;
            if (conferenceInfo) {
              const participant = conferenceInfo.participants.find( p => p.id === conferenceInfo.self.id);
              if (participant) {
                const stream = conferenceInfo.remoteStreams.find( s => s.origin === conferenceInfo.self.id);
                if (stream) {
                  user.streamId = stream.id;
                } else {
                 // this.component.serverConnection.reJoinConference();
                  this.component.serverConnection.publishStream(this.component.serverConnection);
                }
              }
            }
          }
      */
    if (user.streamId) {
      this.setStreamId(user.streamId);
    } else {
      if (this.id === this.selfId) {
        if (this.component?.roomStatus?.localStream?.subscription?.id) {
          this.component.serverConnection.setStreamId(
            this.component.serverConnection,
            this.component.roomStatus.localStream.subscription.id
          );
        }
      }
    }
    if (user.videoState !== undefined) {
      this.videoState = user.videoState;
    }
    if (user.audioState !== undefined) {
      this.audioState = user.audioState;
    }
  }


  displayStream(targetVideoTag): boolean {
    // Elég csak a videó, az audio máshol van kezelve
    console.debug('UserHandler', 'displayStream', 'tag', targetVideoTag);
    if (this.subscription) {
      this.subscription.video(true);
      const localVideo: HTMLVideoElement = (<HTMLVideoElement>document.getElementById(targetVideoTag));
      if (localVideo) {
        try {
          localVideo.srcObject = this.subscription.stream.mediaStream;
        } catch (error) {
          localVideo.src = URL.createObjectURL(this.subscription.stream.mediaStream);
        }
        localVideo.setAttribute('playsinline', '');
        localVideo.volume = 0;
        return true;
      }
    } else {
      UserHandler.mutex.use(async () => {
        await this.connect();
      });
    }
    return false;
  }
}
