import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {OtrHttpRequestService} from '../otr-http-request.service';
import {OtrLangService} from '../otr-lang.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-otr-invoice-client',
  templateUrl: './otr-invoice-client.component.html'
})
export class OtrInvoiceClientComponent implements AfterViewInit, OnInit, OnDestroy {
  errMessageData: any = {};
  isLoggedIn = false;
  langEventDataStr: any = { };
  invoiceData: any = {};
  subscriptionLang: Subscription;

  public tableWidget: any;

  constructor(
    private otrHttpRequestService: OtrHttpRequestService,
    private otrLangService: OtrLangService
  ) {
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      () => {
        this.initDatatable();
        this.loadInvoiceStr();
      }
    );
  }

  loadInvoiceStr(): void {
    this.otrLangService.getValuesForGroup('clientInvoiceStr',
      res => {
        this.langEventDataStr = res;
      }
    );
  }

  ngOnInit() {
    this.loadInvoiceStr();
  }

  ngAfterViewInit(): void {
    this.reInitDatatable();
    this.loadInvoices();
  }

  loadInvoices() {
    this.otrHttpRequestService.doGetAndCheck(
      '/invoice-client/loadInvoices',
      data => {
        if (!data['isError']) {
          this.isLoggedIn = true;
          this.invoiceData = data['result'];
          this.reInitDatatable();
        } else {
          if (!data['isNotLoggedin']) {
            this.isLoggedIn = true;
          }
          this.invoiceData = {};
        }
      }
    );
  }

  private initDatatable(): void {
    const exampleId: any = $('#invoicetable');
    this.tableWidget = exampleId.DataTable({
      select: true, destroy: true,
      stateSave: true,
      language: {
        url: this.otrLangService.getCurrentDataTableLangUrl()
      },
      stateSaveCallback: function (settings, data) {
        localStorage.setItem(
          'DataTables_' + settings.sInstance,
          JSON.stringify(data)
        );
      },
      stateLoadCallback: function (settings) {
        return JSON.parse(
          localStorage.getItem('DataTables_' + settings.sInstance)
        );
      }
    });
  }

  private reInitDatatable(): void {
    if (this.tableWidget) {
      this.tableWidget.destroy();
      this.tableWidget = null;
    }
    setTimeout(() => this.initDatatable(), 0);
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }
}
