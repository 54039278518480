<!-- <div class="container-fluid" style="height: inherit; margin-top: 5px;">
  <div style="width: 450px;float:left;border:1px solid #aaaaaa;height:calc(100vh - 10px)">
    <div class="partheader"  >
      <div style="float:left">{{roomStatus.room.name}} - {{publishNumber}}</div>

        <div *ngIf="roomStatus.room.state == 0" #gatherPopover style="float:right;margin-right:10px;"  ngbPopover="{{langStr.gatheringInfo}}">
          <span class="fa fa-info-circle" ></span>
        </div>
    </div>



    <div style="position: relative;width:450px;height:calc(100vh - 160px);display: flex;align-items: center;justify-content: center;">
      <div id="otherUserVideo" style="position: relative;width: 322px; height: 242px;margin:auto;border: 1px #aaaaaa solid; ">


      </div>
      <div style="width:120px; position: absolute; top: 5px; right: 5px; z-index: 100" title="local medias">
        <div id="myVideo" style="width:122px; height: 92px; border: 1px #aaaaaa solid;" title="my camera">
          <ngx-spinner
bdOpacity = 0.9
bdColor = "#333333"
size = "large"
color = "#ffffff"
type = "ball-8bits"
[fullScreen] = "false"
>
<p style="color: white" > Loading... </p>
</ngx-spinner>
          <video id="localVideo" autoplay width="100%" height="100%"></video>
        </div>
      </div>
      <div
      [style.display]="roomStatus.room.state == 0?'':'none'"
            style=" position: absolute; bottom: 5px; left: 5px; z-index: 100;overflow:hidden;width:120px; height: 90px;">
        <div id="interpreterVideo" style="width:120px; height: 90px; border: 1px #aaaaaa solid;"></div>
        <div style="position: absolute;left: 10px;top:5px;">
          <span class="stroke">{{langStr.interpreter}}</span>
        </div>
        <div style="position: absolute;right: 10px;bottom:5px;width: 100px;text-align:right;">
          <span class="stroke">{{roomStatus.interpreter.name}}</span>
        </div>
      </div>


    </div>




    <div class="" style="height: 110px;border-top:1px solid #aaaaaa;padding-top:10px;position:relative;padding-bottom:16px;background-color:#dddddd">
      <div class="col-auto">

        <div>
          <div class="slidecontainer" style="position: absolute;width:165px;left:5px;">
            <input type="range" min="0" max="100" [(ngModel)]="maxVolume" class="slider" id="volumeSlider" (change)="setGains()">
            <div class="volumedot" [ngStyle]="getMaxVolume()">
              {{maxVolume}}
            </div>
          </div>
          <button class="btn" style="width:40px;margin-right:5px;top: 45px;position: absolute;left: 5px;padding-left:8px;padding-right:8px;" (click)="toggleCamera()" [ngClass]="{'btn-danger':cameraOn,'btn-secondary':!cameraOn}"><i class="fa fa-video-camera" aria-hidden="true" ></i></button>
          <button class="btn" style="width:300px;margin:5px;position: absolute;top: 40px;left: 50px;" [ngClass]="{'greenglowbutton':speakingStatus==2,'btn-secondary':speakingStatus == 0,'btn-success':speakingStatus == 1,'btn-warning':speakingStatus==3}" (click)="nextState()"><i class="fa fa-microphone" ></i></button>
          <button class="btn" style="width:65px;margin-left:5px;position: absolute;left:  362px;top: 45px;padding-left:8px;padding-right:8px;" [ngClass]="{'btn-danger':muteOn,'btn-secondary':!muteOn}" (mousedown)="mute()" (mouseup)="unmute()">MUTE</button>

          <!-- <button class="btn" (click)="toggleCamera()" [ngClass]="{'btn-outline-danger':cameraOn}"><i class="fa fa-video-camera" aria-hidden="true" ></i></button>
          <button class="btn" style="width:250px" [ngClass]="{'glowbutton':speakingStatus==2,'btn-outline-danger':speakingStatus == 1,'btn-outline-warning':speakingStatus==3}" (click)="nextState()"><i class="fa fa-microphone" ></i></button>
          <button class="btn" [ngClass]="{'btn-danger':muteOn}" (mousedown)="mute()" (mouseup)="unmute()"><i class="fa fa-microphone-slash"></i></button> -->
        <!-- </div>
        <div style="float:right;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.room.languages">
            <button style="width:62px;" class="btn" id="outputLang{{item}}" [ngClass]="{'btn-light':roomStatus.self.lang!=item,'btn-success':roomStatus.self.lang==item }" type="button" (click)="setLang(item)">{{item}}</button>
          </ng-template>
        </div>
      </div>


    </div> -->



  <!-- </div>
  <div style="margin-left:10px;margin-right:10px;padding:0px; float:left; width: 180px;border:1px solid #aaaaaa;height: calc(100vh - 10px);">
    <div class="partheader">
      <div style="float:left">{{langStr.participants}}</div>
    </div>
    <div class="partsubheader">
      <div style="float:left">{{langStr.missing}}</div>
    </div>
    <div @list id="missingUsers" style="min-height:100px;max-height:100px;overflow-y:scroll;border-bottom:1px solid #aaaaaa">
      <ng-template ngFor let-item [ngForOf]="missingUserList" let-i="index">
        <div @items class = "chatrow">
          <span class="sender">{{item.name}}</span>
        </div>
      </ng-template>
    </div>
    <div>
      <div class="partsubheader">
        <div style="float:left">{{langStr.present}}</div>
      </div>
      <div id="currentContainer" style="width:100%;height:calc(100vh - 194px);overflow-y: scroll;">

        <ng-template ngFor let-item [ngForOf]="userList" let-i="index">
          <div @items id="Partner{{item.id}}Container" class="videoContainer" >
            <div id="Partner{{item.id}}" style="min-height:140px;background-size: cover;"
            [ngStyle]="{ 'background-image': 'url(/user-avatar/id/' + item.id + ')'}" [ngClass]="{'speaking':item.state=='S','waiting':item.state=='W','notenabled':(item.state=='O' && !item.enabled),'other':(item.state=='O' && item.enabled)}">
            </div>
            <div id="Partner{{item.id}}CanvasContainer" style="width:16px;height:16px;overflow:hidden;position: absolute;right: 10px;bottom:10px;">
              <canvas width="16" height="16" style="width:16px;height:16px;" id="Partner{{item.id}}Canvas"></canvas>
            </div>
            <div id="Partner{{item.id}}Lang" style="position: absolute;left: 10px;top:5px;">
              <span class="stroke">{{item.lang}}</span>
            </div>
            <div id="Partner{{item.id}}Name" style="position: absolute;right: 10px;top:5px;width: 100px;text-align:right;">
              <span class="stroke">{{item.name}}</span>
            </div>

            <div id="Partner{{item.id}}Mute" style="position: absolute;right: 5px;bottom:5px;width: 20px;text-align:center;background-color:white;border:1px solid #aaaaaa;" (click)="toggleUserEnabled(item.id)">
              <i class="fa" [ngClass]="{'fa-volume-up':item.enabled,'fa-volume-off':!item.enabled}"></i>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>


  <div style="min-width:210px;padding:0px;float:left;width:calc(100vw - 680px);height: calc(100vh - 10px);">
    <div style="min-height:140px;">
      <div class="formInnerDiv">
        <div style="border: 1px solid #aaaaaa;position: relative;height: 180px;">
          <div class="partheader">
            <div style="float:left">{{langStr.documents}}</div>
            <label class="btn-file-upload" style="float:right;">
   					<i class="fa fa-file fa-fw buttonInDropZone"></i>
   					<input type="file" name="file" ng2FileSelect [uploader]="uploader" multiple hidden/>
   				</label>
          </div>
          <div ng2FileDrop
               [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
               (fileOver)="fileOverBase($event)"
               [uploader]="uploader"
               class="well my-drop-zone"
               style="overflow-y: scroll;height: 140px;position: absolute;">
            <ng-template ngFor let-item [ngForOf]="roomStatus.room.files" let-i="index">

              <div  class="filerow" style="position:relative;" (click)="downloadFile(item.id)">

                  <i style="position:absolute;right:5px;top:5px;" title="Törlés" class="fa fa-remove fa-fw buttonIcon" aria-hidden="true" (click)="deleteFile(item.id);$event.stopPropagation()"></i>
                  <span class="filename">{{item.name}}</span><br/><span class="fileuploader">{{item.userName}}</span>

              </div>
            </ng-template>
          </div>
          <div class="fileAlertBox" *ngIf="tooLargeFile">
            {{langStr.tooLargeFile}}
          </div>
        </div>
      </div>

    </div>

    <div style="min-height:100px;height: calc(100vh - 197px);margin-top: 10px;">
      <div style="height: 100%">
        <div id="chatmsgs" style="overflow:auto;height: calc(100% - 38px);width:auto;border:1px lightgray solid;background: white;">
          <ng-template ngFor let-item [ngForOf]="roomStatus.room.texts" let-i="index">
            <div @items class="chatrow" >
                <span class="sender">{{item.user.name}}</span>&nbsp;<span class="message">{{item.text}}</span>
            </div>
          </ng-template>
        </div>

        <div class="input-group mb-3">
          <input type="text" class="form-control" id="chatmsg" #chatmsg (keyup.enter)="onEnter(chatmsg.value)">
          <div class="input-group-append">
            <button class="btn btn-secondary" (click)="sendText()" type="button" placement="auto" ngbTooltip="{{langStr.sendmessage}}"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>

    </div>

  </div>


  <div id="streaminfo" style="position: absolute; left: 10px; top:40px;"> -->
  <!-- </div> -->


<!-- </div> --> -->
