<div *ngIf="(isNotLoggedIn)" class="forgotten-password-page animate d-flex align-items-center justify-content-center">
  <div class="container-fluid sidebar-mini">
    <div class="container-row">
      <div class="sidebar-wrapper" >
        <div class="logo d-flex align-items-center justify-content-center" >
          <a href="/otr-client/src">
            <img src="../../../assets/images/logo_feher_horizont.png">
          </a>
          <div class="menuListDiv flagMenuListDiv" >
            <button type="button" class="buttonImgFlag" (click)="selectLang()">
              <img class="imgInButton" src="../../assets/images/flags/{{ currentLang }}.svg" alt=""/>
            </button>
          </div>
        </div>

        <nav class="nav">


          <div id="navbarSupportedContent">
            <ul *ngIf="menus['elements']" class="navbar-nav">
              <li *ngFor="let menu of menus['elements']" class="nav-item" [routerLinkActive]="['active']">
                <a class="nav-link" [routerLink]="[menu.link]"><p>
                  {{ menu.name }}<span class="sr-only">(current)</span>
                </p>
                </a>
              </li>
            </ul>
          </div>
        </nav>

      </div>
      <div class="card">
        <div class="card-title">{{langEventDataStr.elfelejtett_jelszo}}</div>
        <div class="card-subtitle">&nbsp;</div>
        <div class="card-margin">
          <form *ngIf="(isNewPassRequest)" (ngSubmit)="newPasswordRequest()" #newPassReqForm="ngForm" novalidate>
            <div class="form-content">
              <div class="form-group">
                <label for="email">{{langEventDataStr.e_mail}}</label>
                <input type="email" class="form-control" [(ngModel)]="passwordData.email" id="email" name="email"
                       placement="top" ngbTooltip="{{ errMessageData.email }}">
              </div>
            </div>
            <div class="form-button-container">
              <button type="submit" class="button">{{langEventDataStr.kuldes}}</button>
            </div>
          </form>


          <form *ngIf="(!isNewPassRequest)" (ngSubmit)="newPassword()" #newPassForm="ngForm" novalidate>
            <div class="form-content">
              <div class="form-group">
                <label for="password">{{langEventDataStr.uj_jelszo}} *</label>
                <input type="password" class="form-control" [(ngModel)]="passwordData.password" id="password"
                       name="password" placement="top" ngbTooltip="{{ errMessageData.password }}">
              </div>
              <div class="form-group">
                <label for="passwordAgain">{{langEventDataStr.uj_jelszo_megegyszer}} *</label>
                <input type="password" class="form-control" [(ngModel)]="passwordData.passwordAgain" id="passwordAgain"
                       name="passwordAgain" placement="top" ngbTooltip="{{ errMessageData.passwordAgain }}">
              </div>
            </div>
            <div class="form-button-container">
              <button type="submit" class="button">{{langEventDataStr.mentes}}</button>
            </div>
          </form>
        </div>
        <p class="parallax_one_copyright_content" >
          <a class="linkme-mini" target="_blank" href="assets/document/aszf.pdf">{{navBarStr.altalanos_szerzodesi_feltetelek}}</a><br/>
          <a class="linkme-mini" target="_blank" href="assets/document/adatv_nyil.pdf">{{navBarStr.adatvedelem}}</a><br/>

          SIMY © 2022 - Combit Zrt.
        </p>
      </div>
    </div>
  </div>
</div>

