import {Injectable} from '@angular/core';
import {OtrHttpRequestService} from './otr-http-request.service';

@Injectable()
export class OtrEventParticipantService {

  constructor(private otrHttpRequestService: OtrHttpRequestService) {
  }

  getParticipants(eventId, callback) {
    this.otrHttpRequestService.doGetAndCheck('/invite-list/load/participants/' + eventId, data => {
      callback(data);
    });
  }

  getParticipant(eventId, callback) {
    this.otrHttpRequestService.doGetAndCheck('/invite-list/load/participant/' + eventId, data => {
      callback(data);
    });
  }

}
