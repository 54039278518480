<div *ngIf="isLoggedIn" class="animate">
  <div class="page-navbar nav ">
    <div class="row" style="width: 100%">
      <h3>{{langEventDataStr.kimeno_szamlak}}</h3>
    </div>
  </div>

  <div class="container-fluid " style="height: calc( 100vh - 51px)">
    <div class="row">
      <div class="col-md-12 col" style="padding: 15px">
        <div class="card">
          <div class="card-title"></div>
          <div class="card-subtitle"></div>
          <div class="card-body" style="overflow-x:auto;">
            <table class="table table-striped" role="none" id="invoicetable">
              <thead>
              <tr>
                <th style="text-align:center;">{{langEventDataStr.szamla_kelte}}</th>
                <th style="text-align:center;">{{langEventDataStr.teljesitve}}</th>
                <th style="text-align:center;">{{langEventDataStr.osszeg}}</th>
                <th style="text-align:center;">{{langEventDataStr.partner}}</th>
                <th style="text-align:center;">{{langEventDataStr.letoltes}}</th>
              </tr>
              </thead>
              <tbody *ngIf="invoiceData.length > 0">
              <tr *ngFor="let invoiceData_ of invoiceData">
                <td>{{ invoiceData_.szamla_kelt }}</td>
                <td>{{ invoiceData_.fizetve_date }}</td>
                <td>{{ invoiceData_.vegosszeg_brutto }}</td>
                <td>{{ invoiceData_.partner_nev }}</td>
                <td>
                  <div style="display: grid; grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); grid-gap: 4px;">
                    <a target="_blank" class="function"
                       [href]="'/invoice-client/load/' + invoiceData_.id">
                      {{langEventDataStr.elolegszamla}}
                    </a>
                    <a *ngIf="invoiceData_.finalInvoiceHeadId" target="_blank" class="function"
                       [href]="'/invoice-client/load/' + invoiceData_.finalInvoiceHeadId">
                      {{langEventDataStr.vegszamla}}
                    </a>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
