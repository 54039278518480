import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[transition-group-item]',
})
export class TransitionGroupItemDirective {
    public prevPos: any;

    public newPos: any;

    public el: HTMLElement;

    public moved: boolean;

    public moveCallback: any;
    public constructor(elRef: ElementRef) {
        this.el = elRef.nativeElement;
    }
}

