import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OtrUserService } from '../otr-user.service';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-otr-home',
  templateUrl: './otr-home.component.html',
  styleUrls: ['./otr-home.component.css']
})
export class OtrHomeComponent implements OnInit {
  isLoggedIn = false;

  constructor(private router: Router, private otrUserService: OtrUserService,
              private platform: Platform) {}

  ngOnInit() {
    this.platform.ready().then(() => {
      this.otrUserService.getUserData(data => {
        if (data.isLoggedin) {
          this.isLoggedIn = true;
          // az új dashboard
          if (
            data.userType === 'A' ||
            data.userType === 'T' ||
            data.userType === 'U'
          ) {
            this.router.navigate(['/event-query']);
          } else {
            this.router.navigate(['/school']);
          }
        } else {
          // Redirect only in ionic app
          if (this.platform.is('mobile')) {
            this.router.navigate(['/auth']);
          }
        }
      });
    });

  }
}
