import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OtrHttpRequestService} from '../../otr-http-request.service';
import {OtrUserService} from '../../otr-user.service';
import {Location} from '@angular/common';
import {OtrLangService} from '../../otr-lang.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-otr-login',
  templateUrl: './otr-login.component.html'
})
export class OtrLoginComponent implements OnInit, OnDestroy {

  @ViewChild('loginForm') loginForm: HTMLFormElement;
  menus = [];
  loginData: any = {};
  errMessageData: any = {};
  isNotLoggedIn = false;
  continueUrl: any;
  idParam: any;
  isAcceptParam: any;
  langEventDataStr: any = {};
  subscriptionLang: Subscription;
  currentLang = '';

  navBarStr: {
    altalanos_szerzodesi_feltetelek: string,
    adatvedelem: string
  };

  constructor(private router: Router,
              private location_: Location, private activatedRoute: ActivatedRoute,
              private otrHttpRequestService: OtrHttpRequestService, private otrUserService: OtrUserService,
              private otrLangService: OtrLangService) {

    this.loadLoginStr();
    this.subscriptionLang = this.otrLangService.newLangs$.subscribe(
      (lang) => {
        this.currentLang = lang;
        this.loadLoginStr();
      });
    if (activatedRoute.snapshot.params.continueUrl) {
      this.continueUrl = activatedRoute.snapshot.params.continueUrl;
    } else {
      this.continueUrl = null;
    }
    if (activatedRoute.snapshot.params.id) {
      this.idParam = activatedRoute.snapshot.params.id;
    } else {
      this.idParam = null;
    }
    if (activatedRoute.snapshot.params.isAccept) {
      this.isAcceptParam = activatedRoute.snapshot.params.isAccept;
    } else {
      this.isAcceptParam = null;
    }
  }

  loadLoginStr(): void {
    this.otrUserService.getMenus(data => {
      this.menus = data;
      this.currentLang = this.otrLangService.getCurrentLang();
    });
    this.otrLangService.getValuesForGroup('loginStr', res => {
      this.langEventDataStr = res;
    });
    this.otrLangService.getValuesForGroup('navBarStr', res => {
      this.navBarStr = res;
    });
  }

  ngOnInit() {
    this.loadLoginStr();
    this.otrUserService.getIsLoggedIn((data, lng) => {
      if (!data) {
        this.isNotLoggedIn = true;
        this.currentLang = lng;
      } else {
        if (this.continueUrl) {
          this.router.navigate(
            [
              'event',
               this.continueUrl,
              (this.idParam ? this.idParam : ''),
              (this.isAcceptParam ? this.isAcceptParam : '')
            ]
           // { relativeTo: this.activatedRoute.root}
          ).then(
            () =>  this.otrLangService.changeLang(this.otrLangService.currentLangId)
          );
        } else {
          this.router.navigate(['/home']).then();
        }
      }
    });
  }

  loginUser() {
    this.errMessageData = {};
    this.otrHttpRequestService.doPostAndCheck('/auth/login', this.loginData, res => {
        if (!res['isError']) {
          if (this.continueUrl) {
            /*
            window.location.href =  '/#/event/' + this.continueUrl
              + (this.idParam ? '/' + this.idParam : '')
              + (this.isAcceptParam ? '/' + this.isAcceptParam : '');

             */
            this.router.navigate(
              [
                'event',
                this.continueUrl,
                (this.idParam ? this.idParam : ''),
                (this.isAcceptParam ? this.isAcceptParam : '')
              ]
              // { relativeTo: this.activatedRoute.root}
            ).then(
              () =>  this.otrLangService.changeLang(this.otrLangService.currentLangId)
            );
          } else {
            window.location.href = '/#/home';
          }
        } else {
          this.errMessageData = res;
          for (const key in res) {
            if (res.hasOwnProperty(key) && this.loginForm.form.controls[key]) {
              if (res[key] != null) {
                this.loginForm.form.controls[key].setErrors({'incorrect': true});
              } else {
                this.loginForm.form.controls[key].setErrors(null);
              }
            }
          }
        }
      }
    );
  }

  changeLang(newLang) {
    this.otrLangService.changeLang(newLang);
  }

  selectLang() {
    this.otrLangService.selectLang('', '');
  }

  ngOnDestroy() {
    this.subscriptionLang.unsubscribe();
  }
}
