import {
  AfterViewInit,
  Component,
  EventEmitter, HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import {animate, animateChild, query, stagger, style, transition, trigger} from '@angular/animations';
import {takeUntil} from 'rxjs/operators';

import {OtrUserService} from '../otr-user.service';
import {OtrEventParticipantService} from '../otr-event-participant.service';
import {OtrMainJoinEventService} from '../otr-main-join-event.service';

import {OtrHttpRequestService} from '../otr-http-request.service';
import {Subject} from 'rxjs/Subject';
import {UserHandler} from '../event/UserHandler';
import {EventComponent} from '../event/event-component';
import {OtrLangService} from '../otr-lang.service';
import {fromEvent} from 'rxjs/internal/observable/fromEvent';
import {OtrLogService} from '../otr-log.service';
import {ImageModifyService} from '../image-modify.service';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-otr-event-monitor-partner',
  templateUrl: './otr-event-monitor-partner.component.html',
  styleUrls: ['./otr-event-monitor-partner.component.css'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(300, animateChild()), {optional: true})
      ])
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({opacity: 0}),
          animate('10ms', style({opacity: 1}))
        ]),
        transition(':leave', [
          style({opacity: 1}),
          animate('5000ms', style({opacity: 0}))
        ])
      ]
    )
  ]

})


export class OtrEventMonitorPartnerComponent extends EventComponent implements OnInit, OnDestroy, AfterViewInit {

  videoCameraToggleEnabled = false;
  @Output() closeHandler = new EventEmitter();
  private $d = new Subject();

  @ViewChildren('userPinned')
  private userPinned: QueryList<any>;


  displayedStreamId: any;
  previousDisplayedStreamId: any;
  displayedInterpreterStreamId: any;

  langStr = {
    gatheringInfo: '',
    interpreter: '',
    sendmessage: '',
    participants: '',
    chat: '',
    missing: '',
    present: '',
    documents: '',
    tooLargeFile: '',
    organizer: '',
    turnonmic: '',
    exit: '',
    settings: '',
  };

  userId: number;
  msg: string;
  videoCameraInput = true;

  buttonState = null;
  gestureCounter = 4;

  constructor(
    private otrUserService: OtrUserService,
    private otrEventParticipantService: OtrEventParticipantService,
    private otrMainJoinEventService: OtrMainJoinEventService,
    private otrLangService: OtrLangService,
    private otrLogService: OtrLogService,
    private otrHttpRequestService: OtrHttpRequestService,
    private imageModifyService: ImageModifyService,
    public platform: Platform
  ) {
    super(otrHttpRequestService, otrLogService, 'room', imageModifyService, platform);
    otrUserService.getUserData((data) => {
      this.userId = data.userId;
    });
    this.otrLangService.getValuesForGroup('partnerWindow', res => {
      this.langStr = res;
    });
  }

  ngAfterViewInit() {
    this.userPinned.changes.pipe(takeUntil(this.$d)).subscribe((d: QueryList<any>) => {
      if (d.length) {
        // here you get access only when element is rendered
        d.forEach(item => {
          const currentUserId = parseInt(item.nativeElement.userId, 10);
          const currentUserHandler = this.roomStatus.room.getUserById(currentUserId);
          currentUserHandler.displayStream('Partner' + currentUserId + 'VideoContainer');
        });
      }
    });
  }

  ngOnInit() {
    super.eventId = this.eventId;
    this.subscribeToNativeNavigation();
    this.init();
  }


  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (event.target['tagName'] === 'INPUT' && event.target['id'] === 'chatmsg') {
      return;
    }
  }

  isMobile(): boolean {
    return this.platform.is('ios') || this.platform.is('android');
  }

  private subscribeToNativeNavigation() {
    fromEvent(window, 'beforeunload')
      .subscribe((e) => {
        this.resetAudioAndCamera();
      });
  }


  speakerChanged(): void {
    if (this.roomStatus.room.state === 1) {
      if (this.roomStatus.room.speakingUser === this.roomStatus.self.id) { // akt user beszél..
        // Kamera automatikus bekapcsolása ha szót kap
        this.setAudioVideoEnabled(true, true);
        this.eventDisplayState.muteOn = false;
      } else {
        // TODO kell ide? this.eventDisplayState.cameraOn = this.roomStatus.room.lastSpeakingUser === this.roomStatus.self.id;
        this.eventDisplayState.muteOn = true;
        if (this.roomStatus.localStream) {
          this.setAudioVideoEnabled(false, this.videoEnabled());
          this.roomStatus.localStream.audio(!this.eventDisplayState.muteOn);
        }
      }
    }
  }

  eventStateHandler(): void {
    this.roomStatus.enableStreamByLang();
    if (this.roomStatus.room.speakingUser === this.roomStatus.self.id) { // akt user beszél..
      if (this.roomStatus.localStream) {
        this.roomStatus.localStream.audio(!this.eventDisplayState.muteOn);
      }
      if (this.roomStatus.room.waitingUsers.length === 0) {
        this.eventDisplayState.speakingStatus = '1';
      } else {
        this.eventDisplayState.speakingStatus = '2';
      }
    } else {
      if (this.roomStatus.localStream) {
        this.roomStatus.localStream.audio(false);
      }
      if (this.roomStatus.room.waitingUsers.includes(this.roomStatus.self.id)) {
        this.eventDisplayState.speakingStatus = '3';
      } else {
        this.eventDisplayState.speakingStatus = '0';
      }
    }
    this.pinnedUsers(true);
  }

  private pinnedUsers(videoOnly: boolean) {
    this.roomStatus.room.users.forEach((user: UserHandler) => {
      if (user.subscription) {
        // Mindenki hallható, kivéve a letiltottak
        if (!videoOnly) {
          user.subscription.audio(user.enabled);
        }
        // A .show, az interpreter és a kiemeltek láthatóak
        user.subscription.video(
          user.pinned
          || user.id === this.roomStatus.show
          || user.id === this.roomStatus.interpreter.id
          || user.id === this.roomStatus.room.lastSpeakingUser
        );

      }
    });
  }

  eventStateModified(): void {
    if (this.roomStatus.room.state === 0) {
      // gyülekező
      const val = localStorage.getItem('audioEnabled');
      console.log('R', 'localstorage val', val);
      if (val === '0') {
        this.setAudioVideoEnabled(false, this.videoEnabled());
      } else {
        this.setAudioVideoEnabled(true, this.videoEnabled());
      }
    } else {
      localStorage.setItem('audioEnabled', this.audioEnabled() ? '1' : '0');
      this.setAudioVideoEnabled(this.roomStatus.room.speakingUser === this.roomStatus.self.id, this.videoEnabled());
    }
    /*
    if (this.roomStatus.room.state === 0 && this.roomStatus.self.id === this.roomStatus.room.userTree.id) { // szerverző maradhat  :D
      this.eventDisplayState.muteOn = false;
      // TODO set before event start this.eventDisplayState.cameraOn = true;
    } else {
      this.eventDisplayState.muteOn = true;
      // TODO set before event start this.eventDisplayState.cameraOn = false;
    }
     */
    if (this.roomStatus.localStream) {
      // TODO felesleges, a roosstatus állítja this.roomStatus.localStream.video(this.eventDisplayState.cameraOn);
      this.roomStatus.localStream.audio(this.audioEnabled());
    }
    // this.setAudioVideoState();
  }

  gatheringStateHandler() {
    this.eventDisplayState.speakingStatus = '0';
    if (this.roomStatus.localStream) {
      // ha néz valaki akkor kellhet a video (máshol rendezve)
      // állítja máshol this.roomStatus.localStream.audio(this.audioEnabled());
    }
    // az utolsó beszélő vagy a szervező képe látszik (szerver oldali logika)
    if (this.roomStatus.show !== undefined) {
      this.roomStatus.displayUserStreamById(this.roomStatus.show, 'videoSpeakingUser');
    }

    this.pinnedUsers(false);
  }


  onRoomStatusUpdate(): void {
    if (this.roomStatus.room.state === 0) {
      // GATHERING STATE
      /* TODO Felesleges, a roomstatus állítja
      if (this.roomStatus.localStream) {
        this.roomStatus.localStream.video(this.eventDisplayState.cameraOn);
      }
       */
    }
    if (this.roomStatus.show !== -1 && this.roomStatus.show !== undefined) {
      this.roomStatus.displayUserStreamById(this.roomStatus.show, 'videoSpeakingUser');
    }
  }


  setLang(lang) {

    if (this.roomStatus.room.state === 0 ||
      this.roomStatus.room.speakingUser !== this.roomStatus.self.id
    ) {
      super.setLang(lang);
    }
  }


  ngOnDestroy() {
    this.resetAudioAndCamera();
    this.destroy();

    this.otrMainJoinEventService.loadEvents();
    // unsubscribe
    this.$d.next();
    this.$d.complete();
  }


  private resetAudioAndCamera() {
    this.eventDisplayState.muteOn = true;
    this.setAudioVideoState();
  }

  mute() {
    if (this.roomStatus.localStream) {
      this.eventDisplayState.muteOn = true;
      this.roomStatus.localStream.audio(false);
    }
  }

  unmute() {
    if (this.roomStatus.localStream) {
      this.eventDisplayState.muteOn = false;
      this.roomStatus.localStream.audio(true);
    }
  }

  gesture(found) {
    if (found) {
      this.gestureCounter--;
      if (this.gestureCounter === 0) {
        this.nextState();
        this.gestureCounter = 4;
      }
    } else {
      this.gestureCounter = 4;
    }
  }

  toggleCamera() {
    this.setAudioVideoEnabled(this.audioEnabled(), !this.videoEnabled());
  }

  toggleMic() {
    this.setAudioVideoEnabled(!this.audioEnabled(), this.videoEnabled());
  }

  toggleUserEnabled(userId) {
    this.roomStatus.toggleUserEnabled(userId);
  }

  backClicked() {
    this.closeHandler.emit();
  }

  togglePin(userId: number): void {
    this.roomStatus.togglePin(userId);
  }


// Beszéd gomb kezelő, az aktuális állapotnak megfelelő akciót hajtja végre.
  nextState() {
    if (this.roomStatus.room.state === 1) {
      if (this.roomStatus.room.speakingUser === this.roomStatus.self.id) {
        if (this.httpMode) {
          this.otrHttpRequestService.doGet('/room/' + this.eventId + '/stopspeaking')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.stopSpeaking();
        }
      } else if (this.roomStatus.room.waitingUsers.includes(this.roomStatus.self.id)) {
        if (this.httpMode) {
          this.otrHttpRequestService.doGet('/room/' + this.eventId + '/unapply')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.unapply();
        }
      } else {
        if (this.httpMode) {
          this.otrHttpRequestService.doGet('/room/' + this.eventId + '/apply')
            .subscribe(
              data => {
                this.roomStatus.update(data);
              },
              err => {
                console.log('err: ' + JSON.stringify(err));
              }
            );
        } else {
          this.roomStatus.apply();
        }
      }
    }
  }

  toggleVideoInput() {
    if (this.videoCameraInput) {
      this.serverConnection.publishScreen(this.serverConnection,
        () => {
          this.videoCameraInput = false;
          this.setAudioVideoState();
        }
        );
    } else {
      this.serverConnection.publishStream(this.serverConnection,
        this.imageDescriptor,
        () => {
          this.videoCameraInput = true;
          this.setAudioVideoState();
        });
    }
  }

  startRecording() {
    if (this.httpMode) {
      this.otrHttpRequestService.doPost('/room/' + this.eventId + '/startRecord', {})
        .subscribe(data => {
          this.roomStatus.update(data);
        });
    } else {
      this.roomStatus.startRecord();
    }
  }

  stopRecording() {
    if (this.httpMode) {
      this.otrHttpRequestService.doPost('/room/' + this.eventId + '/stopRecord', {})
        .subscribe(data => {
          this.roomStatus.update(data);
        });
    } else {
      this.roomStatus.stopSpeaking();
    }
  }


  activateOrganizer() {
    if (this.roomStatus.room.waitingUsers.includes(this.roomStatus.self.id)) {
      if (this.httpMode) {
        this.otrHttpRequestService.doGet('/room/' + this.eventId + '/unapply')
          .subscribe(
            data => {
              this.otrHttpRequestService.doPost('/room/' + this.eventId + '/grabTheMic', {})
                .subscribe(inner_data => {
                  this.roomStatus.update(inner_data);
                });
            },
            err => {
              console.log('err: ' + JSON.stringify(err));
            }
          );
      } else {
        this.roomStatus.unapplyAndGrabTheMic();
      }
    } else {
      if (this.httpMode) {
        this.otrHttpRequestService.doPost('/room/' + this.eventId + '/grabTheMic', {})
          .subscribe(data => {
            this.roomStatus.update(data);
          });
      } else {
        this.roomStatus.grabTheMic();
      }
    }
  }
}

