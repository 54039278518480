import {UserHandler} from './event/UserHandler';
import * as Owt from './Owt/Owt';

export class EventStreamHandler {
  stream: any;
  subscription: any;
  gain: any;
  streamId: string;
  audioMuted = -1;
  videoMuted = -1;
  userId = -1;
  private otherSideAudioMuted = -1;
  private otherSideVideoMuted = -1;
  streamEndedCallback: any;
  displayed = false;
  userHandler: UserHandler;
  statInterval: any;
  videoPacketsReceived = [];
  audioPacketsReceived = [];

  constructor(pstream: any, psubscription: any, pgain: any, pstreamId: string, puserHandler: UserHandler) {
    this.gain = pgain;
    this.streamId = pstreamId;
    this.stream = pstream;
    this.setSubscription(psubscription);
    this.userHandler = puserHandler;
  }

  close() {
    console.debug('event-stream-handler', 'close');
    if (this.subscription) {
      this.subscription.stop();
      if (this.streamEndedCallback != null) {
        this.streamEndedCallback.call(this, this.streamId);
      }
    }
    clearInterval(this.statInterval);
  }

  setSubscription(sub) {
    console.debug('event-stream-handler', 'setSubscription');
    this.subscription = sub;
    const me = this;
    if (this.subscription) {
      this.subscription.addEventListener('update', (e) => {
        // call remove
        if (me.streamEndedCallback != null) {
          me.streamEndedCallback.call(me, me.streamId);
        }
      });
      this.subscription.addEventListener('ended', () => {
        // call remove
        if (me.streamEndedCallback != null) {
          me.streamEndedCallback.call(me, me.streamId);
        }
      });
      this.subscription.addEventListener('mute', ({type, kind}) => {
        if (kind === Owt.Base.TrackKind.AUDIO) {
          me.otherSideAudioMuted = 1;
        }
        if (kind === Owt.Base.TrackKind.VIDEO) {
          me.otherSideVideoMuted = 1;
        }
        if (kind === Owt.Base.TrackKind.AUDIO_AND_VIDEO) {
          me.otherSideAudioMuted = 1;
          me.otherSideVideoMuted = 1;
        }
      });
      this.subscription.addEventListener('unmute', ({type, kind}) => {
        if (kind === Owt.Base.TrackKind.AUDIO) {
          me.otherSideAudioMuted = 0;
        }
        if (kind === Owt.Base.TrackKind.VIDEO) {
          me.otherSideVideoMuted = 0;
        }
        if (kind === Owt.Base.TrackKind.AUDIO_AND_VIDEO) {
          me.otherSideAudioMuted = 0;
          me.otherSideVideoMuted = 0;
        }
      });
      // stat gather
      this.statInterval = setInterval( () => {
        this.subscription.getStats()
          .then( (stat) => stat.forEach(now => {
            if (now.type === 'inbound-rtp') {
              if (now.kind === 'video') {
                this.videoPacketsReceived.push(now.packetsReceived);
                if (this.videoPacketsReceived.length > 10) {
                  this.videoPacketsReceived.shift();
                }
              }
              if (now.kind === 'audio') {
                this.audioPacketsReceived.push(now.packetsReceived);
                if (this.audioPacketsReceived.length > 10) {
                  this.audioPacketsReceived.shift();
                }
              }
            }
          }) )
          .catch( (error) => {
            console.log(error);
            clearInterval(this.statInterval);
          } );
        if (!this.isAudioWorking() || !this.isVideoWorking()) {
          // vagy az audio vagy a video nem működik, újra kell csatlakozni
          console.debug('event-stream-handler', 'setSubscription',  'reconnect', this.userHandler.nickname);
          this.close();
          this.userHandler.inReconnect = true;
          this.userHandler.resetStream();
        }
      }, 1000);
    }
  }

  isAudioWorking() {
    // itt sincs némítva és a másik oldalon sem
    if (this.userId !== -1 &&  this.otherSideAudioMuted === 0 && this.audioMuted === 0) {
      // jött audio adat?
      if (this.audioPacketsReceived.length > 2) {
        const m1 = this.audioPacketsReceived[this.audioPacketsReceived.length - 1];
        const m2 = this.audioPacketsReceived[this.audioPacketsReceived.length - 2];
        const m3 = this.audioPacketsReceived[this.audioPacketsReceived.length - 3];
        if (m1 === m2 && m2 === m3 && m1 === 0) {
          // 2 mp óta nem jött semmi audio csomag
          console.debug('event-stream-handler', 'isAudioWorking', 'nononono');
          return false;
        }
        // jött csomag
      }
      // még nincs 2 mp adat, vagy jött csomag
      return true;
    }
    // másik oldal némítva van, nem tudjuk megállapítani, vagy magunkról kérdezzük
    return true;
  }

  isVideoWorking() {
    // itt sincs némítva és a másik oldalon sem
    if (this.userId !== -1 && this.otherSideVideoMuted === 0 && this.videoMuted === 0) {
      // jött audio adat?
      if (this.videoPacketsReceived.length > 2) {
        const m1 = this.videoPacketsReceived[this.videoPacketsReceived.length - 1];
        const m2 = this.videoPacketsReceived[this.videoPacketsReceived.length - 2];
        const m3 = this.videoPacketsReceived[this.videoPacketsReceived.length - 3];
        if (m1 === m2 && m2 === m3 && m1 === 0) {
          // 2 mp óta nem jött semmi video csomag
          console.debug('event-stream-handler', 'isVideoWorking', 'nononono', m1, m2, m3);
          return false;
        }
        // jött csomag
      }
      // még nincs 2 mp adat, vagy jött csomag
      return true;
    }
    // másik oldal némítva van, nem tudjuk megállapítani, vagy magunkról kérdezzük
    return true;
  }

  audio(on) {
    if (this.subscription && this.stream.mediaStream.getAudioTracks().length > 0) {
      if (on) {
        if (this.audioMuted === 1 || this.audioMuted === -1) {
          const self = this;
          this.subscription.unmute(Owt.Base.TrackKind.AUDIO).catch(function (error) {
            if (error) {
              if (self.streamEndedCallback != null) {
                self.streamEndedCallback.call(self, self.streamId);
              }
            } else {
              // TODO ide kellene a muted
            }
          });
          this.audioMuted = 0;
        }
      } else {
        if (this.audioMuted === 1 || this.audioMuted === -1) {
          const self = this;
          this.subscription.mute(Owt.Base.TrackKind.AUDIO).catch(function (error) {
            self.audioMuted = 0;
          });
          this.audioMuted = 1;
        }
      }
      this.stream.mediaStream.getAudioTracks()[0].enabled = on;
    }
  }

  video(on) {
    if (this.subscription && (!this.userHandler || this.userHandler.userHasVideo)) {
      if (on) {
        if (this.videoMuted === 1 || this.videoMuted === -1) {
          this.subscription.unmute(Owt.Base.TrackKind.VIDEO).then(
            () => {
              this.videoMuted = 0;
            }
          ).catch(function (error) {
            console.log(error);
          });
        }
      } else {
        if (this.videoMuted === 0 || this.videoMuted === -1) {
          this.subscription.mute(Owt.Base.TrackKind.VIDEO).then(
            () => {
              this.videoMuted = 1;
            }
          ).catch(function (error) {
            console.log(error);
          });
        }
      }
    }
    if (this.stream.mediaStream.getVideoTracks().length > 0) {
      this.stream.mediaStream.getVideoTracks()[0].enabled = on;
    }
  }

  audioVideo(on) {
    this.video(on);
    this.audio(on);
  }
}
