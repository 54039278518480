import * as bodyPix from '@tensorflow-models/body-pix';
import '@tensorflow/tfjs-backend-webgl';
import {BodyPix, ModelConfig} from '@tensorflow-models/body-pix/dist/body_pix_model';
import {Injectable} from '@angular/core';

// import * as handpose from '@tensorflow-models/handpose';
// import * as fingerpose from 'fingerpose';

class ImageDescriptor {
  backgroundBlurValue: number;
  edgeBlurValue: number;
  segmentationMask: any;
  segmentationPixelCount: number;
  source: any;
  ctx: any;
  blurredEnabled: boolean;
  virtualBackgroundEnabled: boolean;
  segmentationMaskCtx: any;
  canvasOutput: any;
  segmentationMaskCanvas: any;
  selectedBackground: any;
  gestureCallback: any;
}

@Injectable({
  providedIn: 'root',
})
export class ImageModifyService {

  net: bodyPix.BodyPix;
  config: ModelConfig;
  hands: any;
  GE: any;
  framecounter: number;
  fpsInterval = 1000 / 60;
  startTime = Date.now();
  then = Date.now();
  stopMe = false;

  constructor() {
    console.log('ImageModifyInitialized');
    this.framecounter = 0;
    this.config = {
      architecture: 'MobileNetV1',
      outputStride: 16,
      quantBytes: 4,
      multiplier: 0.75
    } as ModelConfig;
    this.loadModel();

  }

  async loadModel() {
    bodyPix.load(this.config).then((model: BodyPix) => {
      this.net = model;
      console.log('MODEL LOADED');
      const segmentationMaskCanvas = document.createElement('canvas');
      const segmentationWidth = 320;
      const segmentationHeight = 240;
      segmentationMaskCanvas.width = segmentationWidth;
      segmentationMaskCanvas.height = segmentationHeight;
      this.net.segmentPerson(segmentationMaskCanvas);
      console.log('MODEL INITIALIZED');
    });
    /*
    this.hands = await handpose.load();
    const PaperGesture = new fingerpose.GestureDescription('paper');
    for (const finger of fingerpose.Finger.all) {
      PaperGesture.addCurl(finger, fingerpose.FingerCurl.NoCurl, 1.0);
    }
    this.GE = new fingerpose.GestureEstimator([
      PaperGesture
    ]);
     */
  }

  createDescriptor(source, result): ImageDescriptor {
    const desc = new ImageDescriptor();
    desc.source = source;
    desc.blurredEnabled = false;
    desc.virtualBackgroundEnabled = false;
    desc.canvasOutput = result;
    desc.ctx = desc.canvasOutput.getContext('2d');
    desc.backgroundBlurValue = 10;
    desc.edgeBlurValue = 3;
    const segmentationWidth = 320;
    const segmentationHeight = 240;
    desc.segmentationPixelCount = segmentationWidth * segmentationHeight;
    desc.segmentationMask = new ImageData(segmentationWidth, segmentationHeight);
    desc.segmentationMaskCanvas = document.createElement('canvas');
    desc.segmentationMaskCanvas.width = segmentationWidth;
    desc.segmentationMaskCanvas.height = segmentationHeight;
    desc.segmentationMaskCtx = desc.segmentationMaskCanvas.getContext('2d');
    desc.selectedBackground = null;
    desc.gestureCallback = null;
    return desc;
  }


  async perform(desc: ImageDescriptor) {
    // end me
    if (this.stopMe) {
      this.stopMe = false;
      return;
    }
    const now = Date.now();
    const elapsed = now - this.then;
    if (elapsed < this.fpsInterval) {
      requestAnimationFrame(this.perform.bind(this, desc));
      return;
    }
    this.then = now - (elapsed % this.fpsInterval);
    // while ( true ) {
    if (this.net !== undefined && this.net !== null && (desc.blurredEnabled || desc.virtualBackgroundEnabled)) {
      desc.segmentationMaskCtx.clearRect(
        0,
        0,
        desc.segmentationMaskCanvas.width,
        desc.segmentationMaskCanvas.height
      );
      desc.segmentationMaskCtx.drawImage(
        desc.source,
        0,
        0,
        desc.segmentationMaskCanvas.width,
        desc.segmentationMaskCanvas.height
      );

      const segmentation = await this.net.segmentPerson(desc.segmentationMaskCanvas);
      for (let i = 0; i < desc.segmentationPixelCount; i++) {
        // Sets only the alpha component of each pixel
        desc.segmentationMask.data[i * 4 + 3] = segmentation.data[i] ? 255 : 0;
      }
      desc.segmentationMaskCtx.putImageData(desc.segmentationMask, 0, 0);

      this.runPostProcessing(desc);
    } else {
      desc.ctx.save();
      desc.ctx.filter = 'none';
      // desc.ctx.globalCompositeOperation = 'copy';
      // desc.ctx.clearRect(0, 0, desc.canvasOutput.width, desc.canvasOutput.height);
      desc.ctx.drawImage(desc.source, 0, 0, desc.canvasOutput.width, desc.canvasOutput.height,
        0, 0, desc.canvasOutput.width, desc.canvasOutput.height);
      desc.ctx.restore();
    }
    requestAnimationFrame(this.perform.bind(this, desc));
    /* Gesture not enabled yet
  this.framecounter ++;
  if (this.framecounter > 20 && desc.gestureCallback != null) {
    this.framecounter = 0;
    setTimeout( async () => {
      if (this.hands !== undefined && this.GE !== undefined) {
        try {
          const predictions = await this.hands.estimateHands(desc.source);
          if (predictions.length > 0) {
            const estimatedGestures = this.GE.estimate(predictions[0].landmarks, 8.5);
            if (estimatedGestures.gestures.length > 0) {
              desc.gestureCallback(true);
            } else {
              desc.gestureCallback(false);
            }
          } else {
            desc.gestureCallback(false);
          }
        } catch (e) {
        }
      }
    }, 0);

  }
     */

  }

  getBackground(desc: ImageDescriptor) {
    if (desc.blurredEnabled) {
      return 'blur';
    } else if (desc.virtualBackgroundEnabled) {
      return desc.selectedBackground;
    }
  }

  runPostProcessing(desc: ImageDescriptor) {
    desc.ctx.clearRect(0, 0, desc.canvasOutput.width, desc.canvasOutput.height);
    desc.ctx.save();
    desc.ctx.globalCompositeOperation = 'copy';
    desc.ctx.filter = 'none';

    desc.ctx.filter = 'blur(' + desc.edgeBlurValue + 'px)';
    desc.ctx.drawImage(desc.segmentationMaskCanvas, 0, 0, desc.canvasOutput.width, desc.canvasOutput.height);
    desc.ctx.globalCompositeOperation = 'source-in';
    desc.ctx.filter = 'none';

    desc.ctx.drawImage(desc.source, 0, 0, desc.canvasOutput.width, desc.canvasOutput.height);

    if (desc.virtualBackgroundEnabled) {
      this.blurBackground(desc, desc.selectedBackground, 0);
    }

    if (desc.blurredEnabled) {
      this.blurBackground(desc, desc.source, desc.backgroundBlurValue);
    }

    desc.ctx.restore();
  }

  blurBackground(desc: ImageDescriptor, image, blurAmount) {
    desc.ctx.globalCompositeOperation = 'destination-over';

    desc.ctx.filter = `blur(${blurAmount}px)`;
    desc.ctx.drawImage(image, 0, 0, desc.canvasOutput.width, desc.canvasOutput.height);
  }

  getResultStream(desc: ImageDescriptor) {
    const res = desc.canvasOutput.captureStream();
    // ..... ez firefox hack, mert nem ad a captureStream trackről infót
    const track = res.getVideoTracks()[0];
    if (track.getSettings().width === undefined) {
      track.getSettings = function () {
        return {
          width: desc.canvasOutput.width,
          height: desc.canvasOutput.height,
          framerate: 25,
        };
      };
    }
    const oldStop = track.stop;
    track.stop = () => {
      const src = desc.source;
      src.srcObject.getTracks().forEach(function (mediaStreamTrack) {
        mediaStreamTrack.stop();
      });
      oldStop.call(track);
    };
    return res;
  }

}
