import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {OtrUserService} from './otr-user.service';
import {OtrHttpRequestService} from './otr-http-request.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LanguageDialogComponent} from './language-dialog/language-dialog/language-dialog.component';


@Injectable()
export class OtrLangService {

  currentLangId = 'en';
  langCache = {};

  constructor(private modalService: NgbModal,
              private otrUserService: OtrUserService,
              private otrHttpRequestService: OtrHttpRequestService) {
  }

  private changeLangService = new Subject<string>();
  newLangs$ = this.changeLangService.asObservable();

  changeLang(langId) {
    const me = this;
    this.langCache = {};
    this.otrUserService.doChangeLang(langId, data => {
      me.currentLangId = langId;
      this.changeLangService.next(langId);
    });
  }

  setCurrentInterfaceLang(langId) {
    const me = this;
    me.currentLangId = langId;
    this.changeLangService.next(langId);
  }

  getCurrentLang(): string {
    return this.currentLangId;
  }

  getCurrentDataTableLangUrl(): string {
    return '../../assets/i18n/' + this.currentLangId + '.json';
  }

  selectLang(inTitle, inMessage) {
    const modalRef = this.modalService.open(LanguageDialogComponent);

    modalRef.componentInstance.languages = this.getLanguages();
    modalRef.componentInstance.changeRef.markForCheck();
    modalRef.result.then((val) => this.changeLang(val));
  }

  getLanguages() {
    return ['hu', 'en', 'de', 'it', 'pl', 'ru', 'es', 'pt', 'fr'];
  }

  getLanguageCodes() {
    return ['hu_HU', 'en_US', 'de_DE', 'it_IT', 'pl_PL', 'ru_RU', 'es_ES', 'pt_PT', 'fr_FR'];
  }
  getLanguageCodes3() {
    return ['HUN', 'ENG', 'GER', 'ITA', 'POL', 'RUS', 'SPA', 'POR', 'FRA'];
  }

  getLanguageCode3For2(code) {
    const c3 = this.getLanguageCodes3();
    const c2 = this.getLanguageCodes();
    const ci = c3.indexOf(code);
    if (ci === -1) {
      return '';
    }
    return c2[ci];
  }
  getLanguageCode2For1(code) {
    const c1 = this.getLanguages();
    const c2 = this.getLanguageCodes();
    const ci = c1.indexOf(code);
    if (ci === -1) {
      return 'hu_HU';
    }
    return c2[ci];
  }


  getValuesForGroup(group, callback) {
    if (this.langCache.hasOwnProperty(group)) {
      callback(this.langCache[group]);
      return;
    }
    this.otrHttpRequestService.doGetAndCheck('/getLang/' + group, res => {
      this.langCache[group] = res;
      callback(res);
    });
  }

  loadCodesByCodeType(codetype: string, callback: (data) => void) {
    this.getValuesForGroup('db.' + codetype, callback);
  }
}
